import { createReducer } from "@reduxjs/toolkit";
import { INITIAL_STATE, REDUX_ACTION } from "./action";

const applicationReducer = createReducer(INITIAL_STATE, (builder) => {
 builder.addCase(REDUX_ACTION.WEB_INIT, (state, action) => {
  state.token = action.token;
 });
 builder.addCase(REDUX_ACTION.LOGIN, (state, action) => {
  state.token = action.token;
 });
 builder.addCase(REDUX_ACTION.LOGOUT, (state, action) => {
  state.token = INITIAL_STATE.token;
 });
 builder.addCase(REDUX_ACTION.TAB_ID, (state, action) => {
  state.tab_Id = action.tab_Id;
 });
 builder.addCase(REDUX_ACTION.CART_ITEM_COUNT, (state, action) => {
  state.cart_item_count = action.cart_item_count;
 });
 builder.addCase(REDUX_ACTION.USER_DETAILS, (state, action) => {
  state.user_details = action.user_details;
 });
 builder.addCase(REDUX_ACTION.SESSION_DETAILS, (state, action) => {
    state.session_details = action.session_details;
   });
});

export default applicationReducer;
