import ProgressBar from "@ramonak/react-progress-bar";
import Styles from "../css/My_Courses.module.css";
import Video_Svg from "../../assets/svg/Video_Svg";
import { useNavigate } from "react-router-dom";
import Empty_Page_Style from "../css/Cart_Screen.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tabId } from "../../redux/action";
import { API_URL } from "../../api/URL/Api_Route";
import { PostMethod } from "../../api/request/Api_Calls";
import toast from "react-hot-toast";
import RunTimeConstant from "../../config/RuntimeConfig";
import Loader from "../../component/javascript/loader";
import NetworkError from "../../component/javascript/Network_Error";
import { CONFIG } from "../../config/Constant";
import queryString from "query-string";

const My_Courses = () => {
 const default_class_image = require("../../assets/image/Default_class_image.jpg");

 const navigate = useNavigate();
 const dispatch = useDispatch();

 const { token } = useSelector((state) => state.applicationState);

 const [suggest_Course_Data, set_Suggest_Course_Data] = useState([]);
 const [is_Loading, set_Is_Loading] = useState(false);
 const [is_Network_Error, set_Is_Network_Error] = useState(false);
 const [my_Course_Data, set_My_Course_Data] = useState([]);

 const suggest_course = [
  {
   _id: "65f808441599a363e7a5d46b",
   CourseName: "Trignomentry",
   Subject: "Maths",
   Price: 5000,
   Description: "Lets study about Trignomentry",
   AssetPath: "COR1710753859523/",
   Image: "IMG1710753859523",
   NumberOfTimePurchased: 0,
   Assessment: "",
  },
  {
   _id: "65f808561599a363e7a5d46e",
   CourseName: "Matrix",
   Subject: "Maths",
   Price: 5000,
   Description: "Lets study about Matrix",
   AssetPath: "COR1710753877455/",
   Image: "IMG1710753877455",
   NumberOfTimePurchased: 0,
   Assessment: "",
  },
  {
   _id: "65fd6fbe9fe37adc866c1e24",
   CourseName: "Geomentry-1",
   Subject: "Maths",
   Price: 5000,
   Description: "let study geomentry",
   AssetPath: "COR1711108029430/",
   Image: "IMG1711108662918",
   NumberOfTimePurchased: 0,
   Assessment: "",
  },
  {
   _id: "65f808441599a363e7a5d46b",
   CourseName: "Trignomentry",
   Subject: "Maths",
   Price: 5000,
   Description: "Lets study about Trignomentry",
   AssetPath: "COR1710753859523/",
   Image: "IMG1710753859523",
   NumberOfTimePurchased: 0,
   Assessment: "",
  },
  {
   _id: "65f808561599a363e7a5d46e",
   CourseName: "Matrix",
   Subject: "Maths",
   Price: 5000,
   Description: "Lets study about Matrix",
   AssetPath: "COR1710753877455/",
   Image: "IMG1710753877455",
   NumberOfTimePurchased: 0,
   Assessment: "",
  },
 ];

 const suggest_Course = () => {
  let payload = {
   url: API_URL.userCart,
   data: {
    _id: token,
    limit: CONFIG.PAGINATION_COURSE_LIMIT,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("suggest_Course response", response);
    if (response.success) {
     let exist_data = [];
     const new_data = response.data;
     new_data.forEach((ele) => {
      ele.thumnail_image = `${RunTimeConstant.BASEURL}assert/image?id=${encodeURIComponent(
       ele.AssetPath + ele.Image
      )}`;
      exist_data.push(ele);
     });
     set_Suggest_Course_Data(exist_data);
    } else {
     toast.dismiss();
     toast(response.message);
    }
   })
   .catch((error) => {
    set_Is_Network_Error(true);
   });
 };

 const getCourseProgress = () => {
  set_Is_Loading(true);
  let payload = {
   url: API_URL.getCourseProgress,
   data: {
    UserID: token,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("getCourseProgress response", response);
    if (response.success) {
     let exist_data = [];
     const new_data = response.data;
     new_data.forEach((ele) => {
      ele.thumnail_image = `${RunTimeConstant.BASEURL}assert/image?id=${encodeURIComponent(
       ele.AssetPath + ele.Image
      )}`;
      exist_data.push(ele);
     });
     set_My_Course_Data(exist_data);
     set_Is_Loading(false);
    } else {
     toast.dismiss();
     toast(response.message);
     set_Is_Loading(false);
    }
   })
   .catch((error) => {
    set_Is_Network_Error(true);
    set_Is_Loading(false);
   });
 };

 useEffect(() => {
  getCourseProgress();
 }, []);

 useEffect(() => {
  dispatch(tabId({ tab_Id: 1 }));
 }, []);

 const handleClick = (course_ID) => {
  const value = {
   courseID: course_ID,
  };
  const dataString = JSON.stringify(value);
  const queryStringified = queryString.stringify({ data: dataString });
  navigate(`/courseContent?${queryStringified}`);
 };

 if (is_Loading) {
  return <Loader isLoading={is_Loading} callBack={() => set_Is_Loading(false)} />;
 }

 if (is_Network_Error) {
  return <NetworkError callBack={() => set_Is_Network_Error(false)} />;
 }

 return (
  <div className={Styles.myCourse_Container}>
   {my_Course_Data.length ? (
    <>
     <div className={Styles.myCourse_Body_Container}>
      <div className={Styles.myCourse_Title_View}>
       <div className={Styles.myCourse_Title}>My Courses</div>
      </div>

      <div>
       {my_Course_Data.map((my_crs_data, ind) => {
        return (
         <div key={ind} className={Styles.myCourse_Card_View}>
          <div className={Styles.image_View}>
           <img
            src={my_crs_data?.thumnail_image}
            crossOrigin="anonymous"
            className={Styles.img_Fit}
           />
          </div>

          <div className={Styles.my_Course_Card_Content_View}>
           <div>
            <div className={Styles.myCourse_SubjectName}>{my_crs_data?.Subject}</div>
            <div className={Styles.myCourse_CourseName_Text}>{my_crs_data?.CourseName}</div>
           </div>

           <div>
            <div className={Styles.myCourse_Completion_Text}>
             {"Completion" + " " + Math.trunc(my_crs_data?.TotalCompletionPercentage) + "%"}
            </div>
            <div className={Styles.myCourse_Progress}>
             <ProgressBar
              completed={Math.trunc(my_crs_data?.TotalCompletionPercentage)}
              bgColor={"#21B573"}
              height={"5%"}
              width={"100%"}
              customLabel=" "
             />
            </div>
           </div>

           <div className={Styles.myCourse_Assets_Container}>
            <Video_Svg color={"var(--primary-color)"} height={"13"} width={"16"} />
            <div className={Styles.myCourse_Assets_Text}>
             {my_crs_data?.TotalTopics} Videos
            </div>

            <div className={Styles.assest_Divider_Line} />

            <div className={Styles.myCourse_Assets_Text} style={{ color: "#21B573" }}>
             Completed {my_crs_data?.TotalVideoCompletion}
            </div>

            <div className={Styles.assest_Divider_Line} />

            <div className={Styles.myCourse_Assets_Text} style={{ color: "#FF455B" }}>
             Pending {my_crs_data?.TotalVideoPending}
            </div>
           </div>
          </div>

          <div className={Styles.myCourse_Line_Style} />

          <div className={Styles.myCourse_Button_Container}>
           <div className={Styles.myCourse_Button_View}>
            <div
             className={Styles.myCourse_Button_Text}
             onClick={() => handleClick(my_crs_data._id)}
            >
             Go to Course
            </div>
           </div>
          </div>
         </div>
        );
       })}
      </div>
     </div>

     {suggest_Course_Data?.length ? (
      <>
       <div className={Styles.horizontal_LineStyle} />

       <div className={Styles.suggest_Course_Title}>
        Our Suggestions Because You Complete{" "}
        <span style={{ fontWeight: "700", color: "var(--primary-color)" }}>"Course 1"</span>
       </div>
       <div className={Styles.course_Direction}>
        {suggest_Course_Data.map((ele, ind) => {
         return (
          <div key={ind} className={Styles.course_Container}>
           <div className={Styles.card_View}>
            <div className={Styles.course_Image_View}>
             <img
              src={ele.thumnail_image}
              crossOrigin="anonymous"
              className={Styles.course_Image_Size}
             />
            </div>

            <div className={Styles.course_Sub_Name}>{ele?.Subject}</div>
            <div className={Styles.course_Name}>{ele.CourseName}</div>

            <div
             className={Styles.rating_Like_Direction}
             onClick={() => handleClick(ele?._id)}
            >
             <div className={Styles.price_Text}>{"AED" + " " + ele?.Price}</div>
            </div>
           </div>
          </div>
         );
        })}
       </div>
      </>
     ) : (
      <></>
     )}
    </>
   ) : (
    <div className={Empty_Page_Style.empty_Cart_Container}>
     <div className={Styles.myCourse_Title}>My Courses</div>

     <div className={Empty_Page_Style.cart_Center_View}>
      <div className={Empty_Page_Style.no_Item_Text}>My Course is empty</div>

      <div
       className={Empty_Page_Style.explore_Now_Button_View}
       onClick={() => navigate("/allCourse")}
      >
       <div className={Empty_Page_Style.explore_Now_Text}>Explore now</div>
      </div>
     </div>
    </div>
   )}
  </div>
 );
};

export default My_Courses;
