
export const text = "Support@Cognitived.com";
export const phno = "+123456789";

export const values = [
    { "qn": "Payment", "ans": "Cognitived Education is an organization dedicated to transforming the educational landscape...", option: "option1" },
    { "qn": "Plan related", "ans": "Cognitived Education is an organization dedicated to transforming the educational landscape...", option: "option2" },
    { "qn": "Organization plan", "ans": "Cognitived Education is an organization dedicated to transforming the educational landscape...", option: "option3" },
    { "qn": "Organization plan", "ans": "Cognitived Education is an organization dedicated to transforming the educational landscape...", option: "option4" },
    { "qn": "Organization plan", "ans": "Cognitived Education is an organization dedicated to transforming the educational landscape...", option: "option5" },
];
