const setLocalStorage = async (key, value) => {
 // console.log("setLocalStorage",key, value)
 try {
  if (key) {
   let ITEM = localStorage.setItem(key, JSON.stringify(value));
   return true;
  } else {
   console.log("error while setting Item key or value is null", key, value);
   return null;
  }
 } catch (error) {
  console.log("error while setting Item", error);
  return null;
 }
};

const getLocalStorage = async (key) => {
 try {
  if (key) {
   let ITEM = localStorage.getItem(key);
   ITEM = ITEM ? JSON.parse(ITEM) : null;
   return ITEM;
  } else {
   console.log("error while getting Item key is null", key);
   return null;
  }
 } catch (error) {
  console.log("error while getting Item", error);
  return null;
 }
};

export { setLocalStorage, getLocalStorage };
