import { setLocalStorage } from "../storage/localStorage";

//please add the application state here
const INITIAL_STATE = {
 token: null,
 tab_Id: 0,
 cart_item_count: 0,
 user_details: {},
 session_details: {}
};

//please add the redux action here
const REDUX_ACTION = {
 WEB_INIT: "WEB_INIT",
 LOGIN: "LOGIN",
 LOGOUT: "LOGOT",
 TAB_ID: "TAB_ID",
 CART_ITEM_COUNT: "CART_ITEM_COUNT",
 USER_DETAILS: "USER_DETAILS",
 SESSION_DETAILS:"SESSION_DETAILS"
};
const WebsiteInit = (details) => {
 return {
  type: REDUX_ACTION.WEB_INIT,
  token: details.token,
 };
};
//please add the dispatch action here
const LogIn = (details) => {
 setLocalStorage("token", details?.token);
 return {
  type: REDUX_ACTION.LOGIN,
  token: details.token,
 };
};
const LogOut = () => {
 setLocalStorage("token", null);
 return {
  type: REDUX_ACTION.LOGOUT,
  token: null,
 };
};

const tabId = (details) => {
 setLocalStorage("Tab_ID", details.tab_Id);
 return {
  type: REDUX_ACTION.TAB_ID,
  tab_Id: details.tab_Id,
 };
};

const cartItemCount = (details) => {
 return {
  type: REDUX_ACTION.CART_ITEM_COUNT,
  cart_item_count: details.cart_item_count,
 };
};

const userDetails = (details) => {
 return {
  type: REDUX_ACTION.USER_DETAILS,
  user_details: details.user_details,
 };
};

const sessionDetails =(details)=>{
 return{
  type:REDUX_ACTION.SESSION_DETAILS,
  session_details: details.session_details,
}
}

export {
 INITIAL_STATE,
 REDUX_ACTION,
 LogIn,
 LogOut,
 WebsiteInit,
 tabId,
 cartItemCount,
 userDetails,
 sessionDetails,
};
