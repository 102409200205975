export const CONFIG = {
 USER_EMAIL_MAX_LENGTH: 50,
 USER_NAME_MAX_LENGTH: 20,
 PASSWORD_MAX_LENGTH: 20,
 PAGINATION_COURSE_LIMIT: 5,
};

export const TOP_EMAIL_DOMAINS = {
 "gmail.com": true,
 "yahoo.com": true,
 "hotmail.com": true,
 "outlook.com": true,
 "aol.com": true,
 "icloud.com": true,
 "mail.com": true,
 "live.com": true,
 "msn.com": true,
 "yahoo.co.jp": true,
 "comcast.net": true,
 "qq.com": true,
 "163.com": true,
 "163.net": true,
 "126.com": true,
 "sina.com": true,
 "yahoo.co.uk": true,
 "bellsouth.net": true,
 "yahoo.com.br": true,
 "163.com": true,
 "126.com": true,
 "sina.com": true,
 "yahoo.co.uk": true,
 "bellsouth.net": true,
 "yahoo.com.br": true,
 "rocketmail.com": true,
 "yahoo.co.in": true,
 "verizon.net": true,
 "yahoo.ca": true,
 "live.co.uk": true,
 "aol.co.uk": true,
 "cox.net": true,
 "yahoo.fr": true,
 "hotmail.co.uk": true,
 "ymail.com": true,
 "yahoo.com.au": true,
 "earthlink.net": true,
 "optonline.net": true,
 "me.com": true,
 "mail.ru": true,
 "sbcglobal.net": true,
 "shaw.ca": true,
 "yahoo.es": true,
 "att.net": true,
 "rogers.com": true,
 "yahoo.de": true,
 "btinternet.com": true,
 "centurylink.net": true,
 "yahoo.co.id": true,
 "web.de": true,
 "charter.net": true,
 "gmx.com": true,
 "windstream.net": true,
 "mac.com": true,
 "blueyonder.co.uk": true,
 "telus.net": true,
 "libero.it": true,
 "mail.yahoo.com": true,
 "terra.com.br": true,
 "rambler.ru": true,
 "netzero.net": true,
 "alice.it": true,
 "sky.com": true,
 "frontier.com": true,
 "videotron.ca": true,
 "gmx.de": true,
 "t-online.de": true,
 "earthlink.net": true,
 "optonline.net": true,
 "me.com": true,
 "mail.ru": true,
 "sbcglobal.net": true,
 "shaw.ca": true,
 "yahoo.es": true,
 "att.net": true,
 "rogers.com": true,
 "yahoo.de": true,
 "btinternet.com": true,
 "centurylink.net": true,
 "yahoo.co.id": true,
 "web.de": true,
 "charter.net": true,
 "gmx.com": true,
 "windstream.net": true,
 "mac.com": true,
 "blueyonder.co.uk": true,
 "telus.net": true,
 "libero.it": true,
 "mail.yahoo.com": true,
 "terra.com.br": true,
 "rambler.ru": true,
 "rediffmail.com": true,
 "netzero.net": true,
 "alice.it": true,
 "sky.com": true,
 "frontier.com": true,
 "videotron.ca": true,
 "gmx.de": true,
 "t-online.de": true,
};
