const Message_logo_Svg=()=>{
    return(
        <svg width="43" height="44" viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.5" width="43" height="43" rx="21.5" fill="#7445FF"/>
<path d="M34.4154 18.118V28.6348C34.4154 29.707 34.0052 30.7387 33.2688 31.5181C32.5324 32.2975 31.5257 32.7655 30.4551 32.8262L30.2174 32.8327H12.7799C11.7077 32.8328 10.676 32.4225 9.89665 31.6861C9.11726 30.9497 8.6492 29.943 8.58849 28.8724L8.58203 28.6348V18.118L21.0492 24.6487C21.1879 24.7213 21.3421 24.7593 21.4987 24.7593C21.6553 24.7593 21.8095 24.7213 21.9482 24.6487L34.4154 18.118ZM12.7799 12.166H30.2174C31.258 12.1659 32.2615 12.5522 33.0333 13.2501C33.8052 13.9479 34.2903 14.9076 34.3947 15.9428L21.4987 22.6983L8.6027 15.9428C8.70283 14.9487 9.1543 14.0228 9.87596 13.3317C10.5976 12.6406 11.5421 12.2295 12.5397 12.1725L12.7799 12.166Z" fill="white"/>
</svg>

    )
}

export default Message_logo_Svg;