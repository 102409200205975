import React, { useState } from 'react';
import { getFAQs } from '../Model/FAQ_Page_Model';
import FAQView from '../View/FAQ_Page_View';

const FAQController = () => {
  const [expandedOption, setExpandedOption] = useState(null);
  const faqs = getFAQs();

  const handleOption = (option) => {
    setExpandedOption(expandedOption === option ? null : option);
  };

  return (
    
    <FAQView
    
      faqs={faqs}
      expandedOption={expandedOption}
      handleOption={handleOption}
    />
    
  );
};

export default FAQController;
