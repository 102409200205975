import { useEffect, useRef, useState } from "react";
import { MathfieldElement } from "mathlive";

const MathFieldComponent = (props) => {
 const mathFieldRef = useRef(null);
 const [value, setValue] = useState(props?.value);

 useEffect(() => {
  const mathFieldElement = new MathfieldElement();
  mathFieldElement.defaultMode = "text";
  mathFieldElement.smartMode = true;
  mathFieldElement.style.setProperty("background-color", "var(--bg-color)");
  mathFieldElement.style.setProperty("--text-font-family", "WEB_FONT");
  mathFieldElement.style.setProperty("width", "100%");
  mathFieldElement.style.setProperty("resize", "none");
  mathFieldElement.style.setProperty("outline", "none");
  mathFieldElement.style.setProperty("border", "none");
  mathFieldElement.style.setProperty("marginBottom", "2px");
  mathFieldElement.style.setProperty("height", "auto");
  mathFieldElement.style.setProperty("color", "var(--text-color)");
  //   mathFieldElement.style.setProperty("white-space", "pre-wrap");
  //   mathFieldElement.style.setProperty("word-wrap", "break-word");
  //   mathFieldElement.style.setProperty("overflow-wrap", "break-word");
  mathFieldElement.executeCommand("command", (command) => {
   console.log("command", command);
   if (command === "copyToClipboard") {
    console.log("Copy to clipboard command prevented");
    return false; // Prevent copying
   }
   return true; // Allow other commands
  });
  mathFieldElement.executeCommand();
  mathFieldElement.readOnly = props?.readOnly || false;
  mathFieldElement.setValue(`\\text{${value}}`, { mode: "text" });
  mathFieldElement.style.setProperty("min-height", "50px");
  //   mathFieldElement.addEventListener("input", (event) => {
  //    setValue(event.target.value);
  //    props?.onchangeText(event);
  //   });
  //   mathFieldElement.addEventListener("copy", (evt) => {
  //    console.log("Copy event:", evt.target.value, evt.defaultPrevented, evt.clipboardData);
  //   });
  //   mathFieldElement.addEventListener("paste", (event) => {
  //    console.log("paste event:", event.target.value);
  //    setValue(event.target.value);
  //    props?.onchangeText(event);
  //   });
  if (mathFieldRef.current) {
   mathFieldRef.current.appendChild(mathFieldElement);

   // Clean up the MathLive instance on component unmount
   //   return () => {
   //     mathFieldRef.current.removeChild(mathFieldElement);
   //   };
  }
 }, []);

 return (
  <div
   ref={mathFieldRef}
   style={{
    width: props?.width ? props?.width : "93%",
   }}
  ></div>
 );
};

export default MathFieldComponent;
