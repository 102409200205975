import { useEffect, useState } from "react";
import { CONFIG } from "../../config/Constant";
import Landing_Svg from "../../assets/svg/Landing_Svg";
import Styles from "./../css/Otp.module.css";
import { Formik } from "formik";
import * as Yup from "yup";
import App_Logo from "../../assets/svg/app_logo";
import OtpInput from "react-otp-input";
import LoginReg_Svg from "../../assets/svg/LoginReg_Svg";
import { API_URL } from "../../api/URL/Api_Route";
import {newPostMethod} from "../../api/request/Api_Calls"; 
import { PostMethod } from "../../api/request/Api_Calls";
import { setLocalStorage } from "../../storage/localStorage";
import { LogIn } from "../../redux/action";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../component/javascript/loader";
import NetworkError from "../../component/javascript/Network_Error";
import Header_Component from "../../component/javascript/Header_Component";
import queryString from "query-string";
import toast from "react-hot-toast";
import { AiOutlineExclamationCircle } from "react-icons/ai";

var timerID;

const OTP = () => {
  // console.log("props props", props);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [is_small_screen, setIsSmallScreen] = useState(false);
  const [is_landscape, setIsLandscape] = useState(false);
  const [is_network_error, set_is_network_error] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [check_OTP, set_Check_OTP] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    setCountdown(180);
    const handleResize = () => {
      if (window.innerWidth <= 772) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
      //   console.log("window.innerWidth", window.innerWidth)
      // Check for landscape orientation with a max-width of 767 pixels
      const isLandscapeOrientation = window.matchMedia(
        "(max-height: 575.98px) and (orientation: landscape)"
      ).matches;
      setIsLandscape(isLandscapeOrientation);
    };
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      timerID = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
    return () => {
      clearInterval(timerID);
    };
  }, [countdown]);

  // console.log("otp queryParams", queryParams)

  // const makeDecision = (type, response) => {
  //   let data, queryStringified;
  //   switch (type) {
  //     // from regisration page
  //     case "100":
  //       navigate("/");
  //       dispatch(LogIn({ token: response?.UserID }));
  //       break;
  //     //from forgot password page
  //     case "101":
  //       data = {
  //         id: 101,
  //         email: queryParams.email,
  //       };
  //       queryStringified = queryString.stringify(data);
  //       navigate(`/passwordReset?${queryStringified}`);
  //       break;
  //     //from profile page
  //     case "102":
  //       data = {
  //         id: 500,
  //         email: queryParams.email,
  //       };
  //       queryStringified = queryString.stringify(data);
  //       navigate(`/passwordReset?${queryStringified}`);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const onSubmit = () => {
    // if(otp.length == 6){
    setIsLoading(true);
    // console.log("location.state.email", location.state.email);
    let payload = {
      url: API_URL.otpverify,
      data: {
        EmailId: queryParams.email,
        Otp:otp,
      },
    };
    newPostMethod(payload)
      .then(async (res) => {
        // setLoader(false)
        setIsLoading(false);
        console.log("otp res", res);
        // toast.dismiss()
        if (res.status==200) {

          console.log(res);
          toast.success("OTP Verified")
          navigate('/login');
          // makeDecision(queryParams?.id, res);
          // makeDecision(100,res);
          // toast.success("Login Verified")
          // navigate("/");
          

          // //from regisration page
          // if (queryParams?.id == 100) {
          //   navigate("/");
          //   dispatch(LogIn({ token: res?.UserID }));
          // }
          // //from forgot password page
          // else if (queryParams?.id == 101) {
          //   let data = {
          //     email: queryParams.email
          //   }
          //  const queryStringified = queryString.stringify(data)
          //   navigate(`/passwordReset?${queryStringified}`)
          // navigate("/passwordReset", {
          //   state: { email: location.state.email },
          // });
          // }
        } else {
          toast.dismiss();
          toast.error(res.message);
          // console.log('login not res')
        }
      })
      .catch((err) => {
        if(err.response.status === 400){
          setIsLoading(false);
          toast.error("Invalid OTP","Try Again")
        }
        else{
        setIsLoading(false);
        set_is_network_error(true);
        // setLoader(false)
        console.log(" login catch err", err);
  }});
    // }
    // else{
    //   set_Check_OTP(true)
    // }
  };

  const resendOTP = () => {
    setCountdown(180);
    // setIsLoading(true)
    let payload = {
      url: API_URL.forgetPassword,
      data: {
        EmailId: queryParams.email,
      },
    };
    newPostMethod(payload)
      .then(async (res) => {
        console.log("resendOTP res", res);
        if (res.success) {
          toast.dismiss();
          toast.success(res.message);
        } else {
          toast.dismiss();
          toast.error(res.message);
          // console.log('login not res')
        }
      })
      .catch((err) => {
        set_is_network_error(true);
        // setLoader(false)
        console.log(" login catch err", err);
      });
  };

  const handleOTPChange = (e) => {
    // console.log('otp', e)
    setOtp(e);
    set_Check_OTP(false);
  };
  if (isLoading) {
    console.log("isLoading isLoading", isLoading);
    return <Loader isLoading={isLoading} callBack={() => setIsLoading(false)} />;
  }
  if (is_network_error) {
    return <NetworkError callBack={() => set_is_network_error(false)} />;
  }

  const handleEnter = (event) => {
    const { key, target } = event;
    const { selectionStart, value } = target;
    if (key === "Enter") {
      otp.length == 6 ? onSubmit() : set_Check_OTP(true);
    }
    if (key === "Backspace" && selectionStart === 0 && value === "") {
      const prevInput = target.previousElementSibling;
      if (prevInput) {
        prevInput.focus();
        prevInput.value = "";
        const newOtp = otp.slice(0, otp.length - 1);
        setOtp(newOtp);
      }
    }
  };

  return (
    <div className={Styles.body}>
      {/* {queryParams?.id != 102 && (
    <div style={{ paddingBottom: "6%" }}>
     <Header_Component />
    </div>
   )} */}
      <div className={Styles.outLine}>
        <div className={Styles.svgPart}>
          {is_small_screen ? (
            ""
          ) : (
            <LoginReg_Svg
            // width={ width } height={ height }
            />
          )}
        </div>
        <div className={Styles.formPart}>
          <div className={Styles.form}>
            <div className={Styles.signInText}>OTP Verification</div>
            <div className={Styles.otpText}>Enter the OTP sent to the respected email address</div>
            <div className={Styles.otpEmail}>{queryParams?.email}</div>
            <div>
              <label className={Styles.field}>OTP</label>
              <span className={Styles.requiredFieldSymbol}>*</span>
              <br />
              {/* <input
                                type="text"
                                name="user_email"
                                placeholder='Enter your User Name'
                                className={ Styles.fieldInput }
                                onChange={(val)=>{
                                    // setFieldValue("user_email", val.target.value)
                                } }
                                maxLength={CONFIG.USER_NAME_MAX_LENGTH}
                            /> */}
              <OtpInput
                value={otp}
                onChange={handleOTPChange}
                numInputs={6}
                // renderSeparator={<span>&nbsp; &nbsp; &nbsp; &nbsp;</span>}
                renderInput={(props) => <input {...props} onKeyDown={handleEnter} />}
                containerStyle={Styles.otpContainer}
                inputStyle={Styles.otpInput}
                // className={ Styles.otpInput }
                // style={{color:'black',width:'45px',borderRadius:'3px',height:'45px',border:'1px solid #9B9B9B',textAlign: 'center',margin:'3px'}}
              />

              {/* <div className={Styles.formikErrorText}>
                                    {errors.user_email && touched.user_email && errors.user_email}
                                </div> */}
            </div>

            {check_OTP && (
              <div className={Styles.otp_Error_Text}>
                <div>
                  <AiOutlineExclamationCircle size={14} />
                </div>
                <div>Please enter the valid OTP</div>
              </div>
            )}
            <div
              className={Styles.signInButton}
              tabIndex={0}
              onClick={() => (otp.length == 6 ? onSubmit() : set_Check_OTP(true))}
            >
              Verified & Proceed
            </div>

         { isOnline &&  <div className={Styles.resend_OTP}>
              {countdown > 0 ? (
                `Resend Code in ${Math.floor(countdown / 60)}:${(countdown % 60)
                  .toString()
                  .padStart(2, "0")} minutes`
              ) : (
                <div
                  onClick={resendOTP}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "var(--primary-color)",
                  }}
                  // className={otpverifyStyles.resend_code2}
                >
                  Resend OTP
                </div>
              )}
            </div>}
            {/* <div
                            className={ Styles.signRegNav }
                            tabIndex={0}
                        >
                            Edit the Mail id
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTP;
