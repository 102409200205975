import { useEffect, useLayoutEffect, useState } from "react";
import Assessment_Svg from "../../assets/svg/Assessment_Svg";
import Document_Svg from "../../assets/svg/Document_Svg";
import Video_Svg from "../../assets/svg/Video_Svg";
import Styles from "../css/Cart_Screen.module.css";
import { API_URL } from "../../api/URL/Api_Route";
import { PostMethod } from "../../api/request/Api_Calls";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RunTimeConstant from "../../config/RuntimeConfig";
import NetworkError from "../../component/javascript/Network_Error";
import Loader from "../../component/javascript/loader";
import { cartItemCount, tabId } from "../../redux/action";
import PaypalButton from "../../component/javascript/PayPal_Payment";
import queryString from "query-string";

let totalPayment;

const Cart_Screen = () => {
 const navigate = useNavigate();
 const dispatch = useDispatch();
 //  const location = useLocation();
 //  //  const { course_id } = useParams();
 //  const queryParams = queryString.parse(location.search);
 //  const parsedData = JSON.parse(queryParams?.data);

 const { token, cart_item_count } = useSelector((state) => state.applicationState);

 const [is_Loading, set_Is_Loading] = useState(false);
 const [is_Network_Error, set_Is_Network_Error] = useState(false);
 const [cart_Data, set_Cart_Data] = useState([]);
 const [all_Course_Id, set_All_Course_Id] = useState([]);
 const [is_Small_Screen, set_Is_Small_Screen] = useState(false);
 const [showPayment, setShowPayment] = useState(false);

 const getCartData = () => {
  set_Is_Loading(true);
  let payload = {
   url: API_URL.userCart,
   data: {
    _id: token,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("getCartData response", response);
    if (response.success) {
     let exist_data = [];
     const new_data = response.data;
     new_data.forEach((ele) => {
      ele.thumnail_image = `${RunTimeConstant.BASEURL}assert/image?id=${encodeURIComponent(
       ele.AssetPath + ele.Image
      )}`;
      exist_data.push(ele);
     });
     set_Cart_Data(exist_data);
     set_Is_Loading(false);
     let all_course_id = [];
     new_data.map((ele) => {
      all_course_id.push(ele._id);
     });
     set_All_Course_Id(all_course_id);
    } else {
     set_Is_Loading(false);
     toast.dismiss();
     toast.error(response.message);
    }
   })
   .catch((error) => {
    console.log("getCartData error", error);
    set_Is_Loading(false);
    set_Is_Network_Error(true);
   });
 };

 const handleResize = () => {
  if (window.innerWidth <= 767) {
   set_Is_Small_Screen(true);
  } else {
   set_Is_Small_Screen(false);
  }
 };

 useEffect(() => {
  getCartData();
 }, [is_Network_Error]);

 //  useLayoutEffect(() => {
 //   if (parsedData?.statusCode == 200) {
 //    buyCourse();
 //   }
 //  }, []);

 useEffect(() => {
  dispatch(tabId({ tab_Id: 4 }));
  window.addEventListener("resize", handleResize);

  return () => {
   window.removeEventListener("resize", handleResize);
  };
 }, []);

 const removeCart = (course_id, count) => {
  let payload = {
   url: API_URL.updateCart,
   data: {
    _id: token,
    CourseID: course_id,
    Type: 0,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("removeCart response", response);
    if (response.success) {
     dispatch(cartItemCount({ cart_item_count: count ? 0 : cart_item_count - 1 }));
     getCartData();
     if (!count) {
      toast.dismiss();
      toast.success(response.message);
     }
    } else {
     toast.dismiss();
     toast.error(response.message);
    }
   })
   .catch((error) => {
    console.log("removeCart error", error);
    set_Is_Network_Error(true);
   });
 };

 const buyCourse = () => {
  set_Is_Loading(true);
  setShowPayment(false);
  let payload = {
   url: API_URL.multiplePurchase,
   data: {
    _id: token,
    CourseID: all_Course_Id,
    Type: 1,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("buyCourse response", response);
    if (response.success) {
     // dispatch(cartItemCount({cart_item_count: 0}))
     removeCart(all_Course_Id, true);
     toast.dismiss();
     toast.success(response.message);
     // set_Is_Loading(false)

     // getCartData()
    } else {
     toast.dismiss();
     toast.error(response.message);
     set_Is_Loading(false);
    }
   })
   .catch((error) => {
    set_Is_Loading(false);
    console.log("buyCourse error", error);
    set_Is_Network_Error(true);
   });
 };

 const goToPayment = () => {
  const dataObject = {
   data: cart_Data,
   statusCode: 101,
   allCourseID: all_Course_Id,
  };
  const dataString = JSON.stringify(dataObject);
  const queryStringified = queryString.stringify({ cart: dataString });
  navigate(`/payment?${queryStringified}`);
 };

 const handleClick = (course_ID) => {
  const value = {
   courseID: course_ID,
  };
  const dataString = JSON.stringify(value);
  const queryStringified = queryString.stringify({ data: dataString });
  navigate(`/courseContent?${queryStringified}`);
 };

 if (is_Loading) {
  return <Loader isLoading={is_Loading} callBack={() => set_Is_Loading(false)} />;
 }

 if (is_Network_Error) {
  return <NetworkError callBack={() => set_Is_Network_Error(false)} />;
 }

 //  if (showPayment) {
 //   return (
 //    <PaypalButton
 //     totalPayment={totalPayment}
 //     onSubmit={() => buyCourse()}
 //     closePayment={() => setShowPayment(false)}
 //    />
 //   );
 //  }

 return (
  <div className={Styles.cart_Container}>
   {cart_Data.length ? (
    <>
     <div className={Styles.cart_Title_View}>
      <div className={Styles.cart_Title}>
       {cart_Data.length +
        "  " +
        (cart_Data.length <= 1 ? "course" : "courses") +
        " " +
        "in cart"}
      </div>
     </div>
     <div className={Styles.cart_Container_Body}>
      <div className={Styles.cart_Section_1}>
       {cart_Data.map((ele, ind) => {
        return (
         <div key={ind} className={Styles.cart_Card_View} onClick={() => handleClick(ele._id)}>
          <div className={Styles.cart_Image_View}>
           <img
            src={ele.thumnail_image}
            crossOrigin="anonymous"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
           />
          </div>

          <div className={Styles.cart_Card_Content_View_1}>
           <div>
            <div className={Styles.courseName_Text}>{ele.CourseName}</div>
            {!is_Small_Screen ? (
             <div className={Styles.subject_View}>
              <div className={Styles.subject_Text}>{ele.Subject}</div>
             </div>
            ) : (
             <div className={Styles.subject_Price_View}>
              <div className={Styles.subject_Text}>{ele.Subject}</div>

              <div className={Styles.course_Price_Text}>{"AED" + " " + ele.Price}</div>
             </div>
            )}
           </div>

           <div className={Styles.assests_View}>
            <Video_Svg width={"16"} height={"13"} color={"var(--shadow-color_2)"} />{" "}
            <span className={Styles.assesst_Text}>
             {ele.NumberOfVideos} {"Videos"}
            </span>
            {/* <Document_Svg width={"16"} height={"13"} color={"var(--shadow-color_2)"}/> <span className={Styles.assesst_Text}>{ele.NumberOfDocuments? ele.NumberOfDocuments: 0} {"Documents"}</span> */}
            <Assessment_Svg width={"16"} height={"13"} color={"var(--shadow-color_2)"} />{" "}
            <span className={Styles.assesst_Text}>
             {ele.NumberOfAssessment} {"Assessments"}
            </span>
           </div>
          </div>

          {!is_Small_Screen ? (
           <div className={Styles.cart_Card_Content_View_2}>
            <div className={Styles.cart_Card_Content_View_2_Price_Text}>
             {"AED" + " " + ele.Price}
            </div>

            <div
             className={Styles.remove_Text}
             onClick={(e) => {
              e.stopPropagation();
              removeCart([ele?._id]);
             }}
            >
             Remove
            </div>
           </div>
          ) : (
           <div>
            <div className={Styles.remove_Text} onClick={() => removeCart([ele?._id])}>
             Remove
            </div>
           </div>
          )}
         </div>
        );
       })}
      </div>

      <div className={Styles.cart_Section_2}>
       <div className={Styles.cart_Section_2_Title_View}>
        <div className={Styles.cart_Section_2_Title}>Billing Details</div>
       </div>

       <div className={Styles.cart_Section_2_Content_View}>
        <div className={Styles.cart_Section_2_Content_Title}>
         {cart_Data.length + "  " + (cart_Data.length <= 1 ? "Course" : "Courses")}
        </div>

        {cart_Data.map((ele, ind) => {
         return (
          <div key={ind} className={Styles.cart_Section_2_Course_List}>
           <div className={Styles.cart_Section_2_Course_Name}>{ele.CourseName}</div>
           <div className={Styles.cart_Section_2_Course_Price}>{"AED" + " " + ele.Price}</div>
          </div>
         );
        })}

        <div className={Styles.cart_Section_2_Line_Style} />

        <div className={Styles.total_Count_View}>
         <div className={Styles.total_Count_Text}>Total</div>

         {(() => {
          totalPayment = cart_Data.reduce((a, b) => a + b.Price, 0);
          return <div className={Styles.total_Price_Text}>{"AED" + " " + totalPayment}</div>;
         })()}
        </div>

        {/* <div className={Styles.coupon_View}>
            <input
                placeholder="Enter Coupon"
                className={Styles.coupon_Text_input}
            />

            <div className={Styles.coupon_Button_View}>
                <div className={Styles.coupon_Button_Text}>
                    Apply
                </div>
            </div>
        </div> */}

        <div
         onClick={() => {
          // buyCourse()
          // setShowPayment(true);
          goToPayment();
         }}
         className={Styles.buyNow_Button_View}
        >
         <div className={Styles.buyNow_Button_Text}>Buy Now</div>
        </div>
       </div>
      </div>
     </div>
    </>
   ) : (
    <div className={Styles.empty_Cart_Container}>
     {/* <div className={Styles.no_Item_Text}>
                        No Item Selected
                    </div> */}

     <div className={Styles.cart_Center_View}>
      <div className={Styles.no_Item_Text}>Your cart is empty</div>

      <div onClick={() => navigate("/allCourse")} className={Styles.explore_Now_Button_View}>
       <div className={Styles.explore_Now_Text}>Explore now</div>
      </div>
     </div>
    </div>
   )}
  </div>
 );
};

export default Cart_Screen;
