// cropUtils.js
export const getCroppedImg = (imageSrc, pixelCrop) => {
 const createImage = (url) =>
  new Promise((resolve, reject) => {
   const image = new Image();
   image.onload = () => resolve(image);
   image.onerror = (error) => reject(error);
   image.setAttribute("crossOrigin", "anonymous"); // to avoid CORS issues
   image.src = url;
  });

 return new Promise(async (resolve, reject) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(
   image,
   pixelCrop.x,
   pixelCrop.y,
   pixelCrop.width,
   pixelCrop.height,
   0,
   0,
   pixelCrop.width,
   pixelCrop.height
  );

  canvas.toBlob((blob) => {
   if (!blob) {
    reject(new Error("Canvas is empty"));
    return;
   }
   blob.name = "cropped.jpg";
   resolve(window.URL.createObjectURL(blob));
  }, "image/jpeg");
 });
};
