import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { PostMethod } from "../../api/request/Api_Calls";
import { newPostMethod } from "../../api/request/Api_Calls";
import { API_URL } from "../../api/URL/Api_Route";
import queryString from "query-string";
import toast from "react-hot-toast";


const useRegistrationController = () => {
  const navigate = useNavigate();
  const [is_small_screen, setIsSmallScreen] = useState(false);
  const [is_landscape, setIsLandscape] = useState(false);
  const [is_network_error, set_is_network_error] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [passwordIconType, setPasswordIconType] = useState("password");
  const [passwordEyeIcon, setPasswordEyeIcon] = useState(false);
  // const [confirmPasswordIconType, setConfirmPasswordIconType] = useState("password");
  const [confirmPasswordEyeIcon, setConfirmPasswordEyeIcon] = useState(false);
  const [passwordIconType, setPasswordIconType] = useState("password");
  const [confirmPasswordIconType, setConfirmPasswordIconType] = useState("password");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
      const isLandscapeOrientation = window.matchMedia(
        "(max-height: 575.98px) and (orientation: landscape)"
      ).matches;
      setIsLandscape(isLandscapeOrientation);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onSubmit = (form_data) => {
    setIsLoading(true);
    

    const payload = {
      url: API_URL.user_registration,
      data: {
        UserName: form_data?.user_name,
        EmailId: form_data?.user_email,
        Password: form_data?.password,
        // RoleID: "66bde53993f0a9832e94fce9",
        RoleID: "66c75e85dff4c1e686cca7c6",
        UserType: 1,
      },
    };
    newPostMethod(payload)
      .then((res) => {
        setIsLoading(false);
        toast.dismiss();
        if (res.status==200) {
          const data = { id: 100, email: form_data.user_email };
          const queryStringified = queryString.stringify(data);
          navigate(`/otp?${queryStringified}`);
          toast.success("OTP Sent");
        }

      
         else {
          toast.error(res.message);
        }
      }
   
      )
      .catch((err) => {
        if(err?.response?.status === 409){
          setIsLoading(false);
      toast.error("Already Email is Registered");
       }
       else if(err?.response?.status === 422){
        setIsLoading(false);
        toast.error("Something Went Wrong");
       }
        else{
        setIsLoading(false);
        set_is_network_error(true);
        console.error("Error during registration:", err);
  }});
  };


  const passwordEyeIconChange = () => {
    setPasswordIconType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const confirmPasswordEyeIconChange = () => {
    setConfirmPasswordIconType((prevType) => (prevType === "password" ? "text" : "password"));
    // setConfirmPasswordIconType()
  };
  const Navigation=()=>{
    navigate(`/login`);
    }

    // const Loginnavigation=()=>{
    //   Navigate("/otp")
    // }
  
  return {
    is_small_screen,
    is_landscape,
    is_network_error,
    isLoading,
    passwordIconType,
    passwordEyeIcon,
    confirmPasswordIconType,
    confirmPasswordEyeIcon,
    set_is_network_error,
    setIsLoading,
    onSubmit,
    passwordEyeIconChange,
    confirmPasswordEyeIconChange,
    Navigation,
    
  };
};

export default useRegistrationController;
