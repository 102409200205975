import React from "react";

function No_Internet() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="129"
      height="101"
      fill="none"
      viewBox="0 0 129 101"
    >
      <path
        fill="#979797"
        d="M104.51 57.26a5.246 5.246 0 01-3.572-1.385 52.794 52.794 0 00-70.438 0 5.469 5.469 0 01-7.73-.438 5.542 5.542 0 01.511-7.729 63.438 63.438 0 0184.875 0 5.54 5.54 0 01.511 7.73 5.771 5.771 0 01-4.157 1.822z"
      ></path>
      <path
        fill="#979797"
        d="M123.563 34.56a5.541 5.541 0 01-3.719-1.458 79.259 79.259 0 00-109.375 0 5.47 5.47 0 11-7.291-8.021 90.125 90.125 0 01123.958 0 5.47 5.47 0 01-.546 8.469 5.47 5.47 0 01-3.027 1.01zM46.125 75.685a5.469 5.469 0 01-4.374-2.187 5.394 5.394 0 011.093-7.657 36.895 36.895 0 0144.844 0 5.467 5.467 0 011.094 7.657 5.542 5.542 0 01-7.656 1.093 26.178 26.178 0 00-31.72 0 5.687 5.687 0 01-3.28 1.094zm17.969 15.313c-.702 0 1.458 1.46.433.434-1.026-1.026.866 2.75.866 1.3 0-1.451-1.892-.275-.866-1.3 1.025-1.026-1.755-.08-.304-.08 1.45 0-.722-.946.304.08 1.025 1.025.725-.882.725.569-7.507-3.055 1.167-1.162.141-.136-1.025 1.025.151-.867-1.3-.867z"
      ></path>
      <rect
        width="3.465"
        height="24.257"
        x="72.257"
        y="80.956"
        fill="#979797"
        rx="1.733"
        transform="rotate(45 72.257 80.956)"
      ></rect>
      <rect
        width="3.465"
        height="24.257"
        x="55.104"
        y="83.406"
        fill="#979797"
        rx="1.733"
        transform="rotate(-45 55.104 83.406)"
      ></rect>
    </svg>
  );
}

export default No_Internet;
