import React, { useState } from 'react';
import { deleteExistingSession, newLoginGetMethod, getSessionMethod } from '../../api/request/Api_Calls';
import { API_URL } from '../../api/URL/Api_Route';
import { useNavigate } from "react-router-dom";
import { LogOut, sessionDetails, LogIn } from "../../redux/action";
import { useDispatch } from "react-redux";
import Cookies from 'js-cookie';
import queryString from "query-string";


const Dialogpagecontroller = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const onSubmit = () => {

        let payload = {
            url: API_URL.logout,
            data: {
                CUDUSID: Cookies.get('CUDUSID')
            }
        };
        newLoginGetMethod(payload)
            .then(async (res) => {

                if (res?.status == 200) {
                    //   setIsLoading(false);
                    dispatch(LogOut());

                    navigate('/')
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }


    const onConfirmSubmit = () => {

        let payload = {
            url: API_URL.deleteExistingSession,
        };
        deleteExistingSession(payload)
            .then(async (res) => {
                //   setIsLoading(false);
                // dispatch(LogOut());
                if (res?.status == 200) {

                    console.log("Delete Existing Session Response =====>", res)

                        let payload ={
                            url:API_URL.authorize
                        }

                    newLoginGetMethod(payload)
                        .then(async (res) => {
                            // setIsLoading(false);
                            console.log("entered authorize")
                            console.log("login res", res);
                            if (res?.status == 200) {
                                let data = {
                                    SessionID: Cookies.get('CUDUSID'),
                                };
                                const queryStringified = queryString.stringify(data);

                                const querydata = (data.SessionID)

                                console.log("query data 123456 ===>",querydata)
                                console.log("queryStringified =====> ", querydata)
                                let payload = {
                                    url: `${API_URL.getSession}?SessionID=${querydata}`,   //passing query parameter to a service 

                                };
                                getSessionMethod(payload)
                                    .then(async (res) => {
                                        console.log("entered getsession")
                                        if (res?.status == 200) {
                                            //   setIsLoading(false)
                                            dispatch(LogIn({ token: res.data.sub}));
                                            dispatch(sessionDetails({ token: res.data }))
                                            console.log("Dispatched Details ====>", (sessionDetails({ session_details: res.data }))) //print
                                            navigate("/");
                                            console.log('----SSSSSSSSSSSSS----'); //print
                                            console.log('Response from set session', res); //print
                                        }
                                    })
                                    .catch((err) => {
                                        console.log('----NNNNNNNNNNNNN----');
                                        console.log("Get Session error", err);
                                    })
                            }
                        }
                        )            
                        .catch((err) => {
                            console.log("123456789 ======> ", err)
            
                        })
                }
            })
            .catch((err) => {
                console.log(err);
                console.log("Authorization error 123456789 =======>",err)
            })
    }

    return {
        // no,
        // Setyes,
        // ChangeReplyNo,
        // ChangeReplyYes,
        onSubmit,
        onConfirmSubmit
    }




}

export default Dialogpagecontroller;