import React from "react";

function Down_Arrow_Svg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      fill="none"
      viewBox="0 0 14 15"
    >
      <ellipse
        cx="7"
        cy="7.309"
        fill="#fff"
        rx="6.989"
        ry="7"
        transform="rotate(90 7 7.309)"
      ></ellipse>
      <path
        fill="#7445FF"
        d="M3.506 4.57L6.92 7.979l3.413-3.407a.877.877 0 011.24 1.238L7.537 9.84a.877.877 0 01-1.24 0L2.256 5.81a.874.874 0 010-1.238.896.896 0 011.25 0z"
      ></path>
    </svg>
  );
}

export default Down_Arrow_Svg;
