import "./App.css";
import {getSessionMethod} from '../src/api/request/Api_Calls';
import { API_URL } from "../src/api/URL/Api_Route";
import { useDispatch, useSelector } from "react-redux";
import { WebsiteInit , sessionDetails ,LogIn } from "./redux/action";
import { getLocalStoragse } from "./storage/localStorage";
import toast, { Toaster } from "react-hot-toast";
import { Router_Provider } from "./router_container/routers";
import { useEffect, useLayoutEffect, useState } from "react";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

function App() {
 const [isRender, setRender] = useState(false);
 const { token } = useSelector((state) => state.applicationState);
 const dispatch = useDispatch();
//  const navigate = useNavigate();

 useEffect(() => {
   initialFetchToken();
},[]);

 const initialFetchToken = async () => {
   //  let tokenLocalStorage = await getLocalStorage("token");
   //  console.log("tokenLocalStorage", tokenLocalStorage)
   const  SessionID = Cookies.get('CUDUSID') ;
   console.log('app.js cookie ==>',SessionID)    //cookies
   let payload = {
      url:`${API_URL.getSession}?SessionID=${SessionID}`, //calling get session api along with cookie send in the url as parameter  
      }
   getSessionMethod(payload)
   .then(async (res) => {
         console.log("entered getsession")
         console.log("login res", res);
              if(res?.status == 200){
               dispatch(sessionDetails({session_details:res.data}))  //all the data responsed from the session details api willl come under this which includes country codeid and everything
               dispatch(LogIn({ token: res.data.sub }))  //sub uuid 
            }
            else if(res?.status == 409){
             
               console.log('hello',res)
             
            }
   })
   .catch((err)=>
   {
      if(err?.response?.status == 404){
         console.log('Session Failed')
      }
      else{
      console.log("Errrrrrror ------------->",err)
   }})

   setRender(true);
 };

 return (
  <div className="App">
   <Toaster />
   {isRender && <Router_Provider token={token} />}
  </div>
 );
}

export default App;
