import React from "react";

function Instagram_Svg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="24"
      fill="none"
      viewBox="0 0 23 24"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M6.684 1.212c1.173-.061 1.552-.061 4.534-.061 2.982 0 3.361 0 4.547.06a8.08 8.08 0 012.664.514 5.622 5.622 0 013.19 3.238c.32.853.494 1.754.514 2.664.06 1.174.06 1.552.06 4.534 0 1.818-.022 2.65-.04 3.3-.011.416-.02.757-.02 1.234a8.079 8.079 0 01-.514 2.664 5.622 5.622 0 01-3.214 3.214 8.08 8.08 0 01-2.665.514c-1.173.06-1.552.06-4.534.06-1.818 0-2.65-.022-3.3-.04-.416-.011-.758-.02-1.235-.02a8.08 8.08 0 01-2.664-.514 5.622 5.622 0 01-3.215-3.226 8.077 8.077 0 01-.513-2.664C.218 15.51.218 15.13.218 12.149c0-2.982 0-3.36.061-4.546.02-.91.193-1.811.513-2.664A5.622 5.622 0 014.02 1.725a8.08 8.08 0 012.665-.513zm8.984 1.981c-1.161-.061-1.516-.061-4.45-.061v.024c-1.809 0-2.636.023-3.274.041-.4.011-.726.02-1.174.02a6.111 6.111 0 00-2.041.38 3.666 3.666 0 00-2.066 2.065 6.11 6.11 0 00-.379 2.04c-.06 1.161-.06 1.504-.06 4.449 0 1.809.022 2.636.04 3.274.011.4.02.726.02 1.174a6.11 6.11 0 00.38 2.04 3.667 3.667 0 002.065 2.066c.654.242 1.344.37 2.04.379 1.162.061 1.504.061 4.45.061 1.81 0 2.636-.023 3.274-.04.4-.012.727-.021 1.175-.021a6.111 6.111 0 002.04-.379 3.666 3.666 0 002.066-2.065c.242-.654.37-1.344.38-2.041.06-1.161.06-1.503.06-4.448 0-1.81-.023-2.636-.04-3.274-.012-.4-.02-.727-.02-1.175a6.107 6.107 0 00-.38-2.04 3.666 3.666 0 00-2.065-2.066 6.112 6.112 0 00-2.041-.403z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M5.57 12.148a5.646 5.646 0 1111.293 0 5.646 5.646 0 01-11.293 0zm1.981 0a3.667 3.667 0 107.334 0 3.667 3.667 0 00-7.334 0zM16.82 6.64a1.32 1.32 0 100-2.64 1.32 1.32 0 000 2.64z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Instagram_Svg;
