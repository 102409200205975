
import { CONFIG} from "../../config/Constant";
import Styles from '../styles/Login_Page.module.css';
import { Formik } from "formik";
import App_Logo from "../../assets/svg/app_logo";
import Landing_Svg from "../../assets/svg/Landing_Svg";
import LoginReg_Svg from "../../assets/svg/LoginReg_Svg";
import { LogIn } from "../../redux/action";
import { setLocalStorage } from "../../storage/localStorage";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../api/URL/Api_Route";
import { PostMethod } from "../../api/request/Api_Calls";
import { useDispatch } from "react-redux";
import Loader from "../../component/javascript/loader";
import NetworkError from "../../component/javascript/Network_Error";
import Header_Component from "../../component/javascript/Header_Component";
import toast from "react-hot-toast";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import queryString from "query-string";
import { data, initialValues,passworddata } from "../Model/Login_Page_Model";
import { ValidationSchema } from "../Model/Login_Page_Model";
import useLoginController from "../Controller/Login_Page_Controller";
import Passwordfield from "../Component/Passwordfield";
import Textfield from "../Component/Textfield";
import {FaUser} from 'react-icons/fa'
import { lightBlue } from "@mui/material/colors";

const Loginpage=()=>{


    const {
        is_small_screen,
        is_landscape,
        is_network_error,
        isLoading,
        passwordIconType,
        passwordEyeIcon,
        confirmPasswordIconType,
        confirmPasswordEyeIcon,
        set_is_network_error,
        setIsLoading,
        onSubmit,
        passwordEyeIconChange,
        confirmPasswordEyeIconChange,
        forgetPageNavigation,
        registrationPageNavigation
       
      } = useLoginController();
    
      if (isLoading) {
        return <Loader isLoading={isLoading} callBack={() => setIsLoading(false)} />;
      }
    
      if (is_network_error) {
        return <NetworkError callBack={() => set_is_network_error(false)} />;
      }

    return(
        <div className={Styles.body}>
   {/* <Header_Component element={""} /> */}
   <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={ValidationSchema}
   >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
     <div className={Styles.outLine}>
      <div className={Styles.svgPart}>
       {is_small_screen ? (
        ""
       ) : (
        <div className={Styles.resizable_container}>
         <div className={Styles.svg_content} />
        </div>
       )}
      </div>
      <div className={Styles.formPart}>
       <div className={Styles.form}>
        <div className={Styles.signInText}>Sign in</div>
        <div>

        {data.map((values, index) => (
                <Textfield  
                  key={index}
                  error={errors[values.name]} 
                  touched={touched[values.name]} 
                  handleSubmit={handleSubmit} 
                  setFieldValue={setFieldValue} 
                  type={values.type} 
                  name={values.name} 
                  placeholder={values.placeholder} 
                  label={values.label}
                  config={values.MAX_LENGTH}
                  
                  // passwordEyeIconChange={passwordEyeIconChange}
                  // confirmPasswordEyeIconChange={confirmPasswordEyeIconChange}
                />
                ))}

{passworddata.map((values,index)=>(
                  <Passwordfield  
                  key={index}
                  error={errors[values.name]} 
                  touched={touched[values.name]} handleSubmit={handleSubmit} 
                  setFieldValue={setFieldValue} 
                  type={values.type} 
                  name={values.name} 
                  placeholder={values.placeholder} 
                  label={values.label}
                  config={values.MAX_LENGTH}
                  // passwordEyeIconChange={passwordEyeIconChange}
                  // confirmPasswordEyeIconChange={confirmPasswordEyeIconChange}
                  // passwordIconType={passwordIconType}
                  // passwordEyeIcon={passwordEyeIcon}
                  // confirmPasswordIconType={confirmPasswordIconType}
                  // confirmPasswordEyeIcon={confirmPasswordEyeIcon}
                  passwordIconType={values.name === "password" ? passwordIconType : confirmPasswordIconType}
                  passwordEyeIconChange={values.name === "password" ? passwordEyeIconChange : confirmPasswordEyeIconChange}
                  
                />

                

                ))}
               


        </div>
        <div
         tabIndex={0}
         className={Styles.forgetPassword}
         onClick={forgetPageNavigation}
        >
         Forget Password
        </div>
        <div tabIndex={0} className={Styles.signInButton} onClick={handleSubmit}>
         Submit
        </div>
        <div
         tabIndex={0}
         className={Styles.signRegNav}
         onClick={registrationPageNavigation}
        >
         Create Account
        </div>
       </div>
      </div>
     </div>
    )}
   </Formik>
  </div>
    )

}
export default Loginpage;