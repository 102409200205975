const Progress = () => {
 return (
  <div
   style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}
  >
   Progress Under Development
  </div>
 );
};

export default Progress;
