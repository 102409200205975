import React from "react";

function Twitter_Svg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="19"
      fill="none"
      viewBox="0 0 23 19"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M19.945 4.932c.008.205.01.41.01.615 0 6.21-4.584 13.38-12.967 13.38-2.576 0-4.97-.782-6.988-2.114a8.95 8.95 0 006.749-1.956c-1.995-.027-3.679-1.396-4.26-3.258.279.056.566.084.86.084.414 0 .816-.056 1.201-.168C2.463 11.087.891 9.187.891 6.906V6.85c.615.345 1.32.559 2.067.587a4.758 4.758 0 01-2.03-3.92 4.8 4.8 0 01.619-2.365C3.794 4 7.154 5.872 10.942 6.068a4.888 4.888 0 01-.117-1.07c0-2.598 2.04-4.703 4.558-4.703 1.311 0 2.495.568 3.326 1.48A8.794 8.794 0 0021.606.64a4.668 4.668 0 01-2.007 2.597 8.866 8.866 0 002.619-.735 9.446 9.446 0 01-2.273 2.43z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Twitter_Svg;
