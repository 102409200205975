import React, { useState } from "react";
import Styles from "../css/Paypal_Payment.module.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import toast from "react-hot-toast";
import { FcCancel } from "react-icons/fc";
import CartStyles from "../../pages/css/Cart_Screen.module.css";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../api/URL/Api_Route";
import { PostMethod } from "../../api/request/Api_Calls";
import { cartItemCount } from "../../redux/action";
import Loader from "./loader";
import NetworkError from "./Network_Error";
let totalPayment;

const Payment_Screen = () => {
 const location = useLocation();
 const navigate = useNavigate();
 const dispatch = useDispatch();
 const [isPaymentStarted, setIsPaymentStarted] = useState(false);
 const queryParams = queryString.parse(location.search);
 const payment_data = JSON.parse(queryParams.cart);
 const [is_Loading, set_Is_Loading] = useState(false);
 const [is_Network_Error, set_Is_Network_Error] = useState(false);
 const { token, cart_item_count } = useSelector((state) => state.applicationState);

 const closePayment = () => {
  navigate(-1);
 };

 const removeCart = (course_Id) => {
  let payload = {
   url: API_URL.updateCart,
   data: {
    _id: token,
    CourseID: course_Id,
    Type: 0,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("removeCart response", response);
    if (response.success) {
     dispatch(cartItemCount({ cart_item_count: cart_item_count - 1 }));
     //success
    } else {
     // toast.dismiss()
     // toast(response.message)
    }
   })
   .catch((error) => {
    console.log("removeCart error", error);
    // set_Is_Network_Error(true);
   });
 };

 const buyCourse = (course_id, Is_present_incart, isFrom) => {
  set_Is_Loading(true);
  let payload = {
   url: isFrom == "single" ? API_URL.updatePurchase : API_URL.multiplePurchase,
   data: {
    _id: token,
    CourseID: course_id,
    Type: 1,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("buyCourse response", response);
    if (response.success) {
     if (isFrom == "single") {
      const dataObject = {
       courseID: course_id,
      };
      const dataString = JSON.stringify(dataObject);
      const queryStringified = queryString.stringify({ data: dataString });
      navigate(`/courseContent?${queryStringified}`);
      if (Is_present_incart) {
       removeCart([course_id]);
      }
     } else if (isFrom == "bulk") {
      removeCart(course_id);
      navigate("/cart");
     }
     // set_Is_Loading(false);
     toast.dismiss();
     toast.success(response.message);
    } else {
     toast.dismiss();
     toast.error(response.message);
     set_Is_Loading(false);
    }
   })
   .catch((error) => {
    console.log("buyCourse error", error);
    set_Is_Network_Error(true);
    set_Is_Loading(false);
   });
 };

 const onSubmit = () => {
  if (payment_data?.statusCode == 100) {
   buyCourse(payment_data?.data[0]?._id, payment_data?.data[0]?.IsPresentIncart, "single");
  } else if (payment_data?.statusCode == 101) {
   buyCourse(payment_data?.allCourseID, false, "bulk");
   //  navigate("/cart");
  }
 };

 if (is_Loading) {
  return <Loader isLoading={is_Loading} callBack={() => set_Is_Loading(false)} />;
 }

 if (is_Network_Error) {
  return <NetworkError callBack={() => set_Is_Network_Error(false)} />;
 }

 return (
  <div className={Styles.paymentContainer}>
   <div className={CartStyles.cart_Title_View}>
    <div className={CartStyles.cart_Title}>Payment Option</div>
   </div>
   <div className={Styles.paymentBody}>
    <div className={Styles.paymentContainerMobile}>
     <PayPalScriptProvider
      options={{
       "client-id":
        "ATqnOb9g-m92VnsxnPsC0vu-FlzzmXl_eJuIWP49wqhy35qzKOF98DkfjH_79YiH4n7k-gnBsyw4sasS",
      }}
     >
      <div className={Styles.creditCardButtons}>
       <PayPalButtons
        createOrder={(data, actions) => {
         return actions.order.create({
          purchase_units: [
           {
            amount: {
             value: totalPayment + ".00",
            },
           },
          ],
         });
        }}
        onApprove={(data, actions) => {
         return actions.order.capture().then((details) => {
          // console.log("details 0 0 0 0 0 0 ", details);
          const payerName = "Customer";
          onSubmit();
          // toast.success("Transaction completed successfully");
          //    alert("Transaction completed by " + payerName);
         });
        }}
        onClick={() => setIsPaymentStarted(true)}
        onCancel={(data) => {
         //  console.log("Payment cancelled", data);
         toast.dismiss();
         toast("Payment cancelled", {
          icon: <FcCancel size={25} />,
         });
         closePayment();
         setIsPaymentStarted(false);
         // Handle cancellation response
        }}
        onError={(err) => {
         console.error("Error during the transaction", err);
         closePayment();
         setIsPaymentStarted(false);
         // alert("An error occurred during the transaction");
         // Handle error response
        }}
       />
      </div>
      {!isPaymentStarted && (
       <button className={Styles.cancelButton} onClick={closePayment} type="submit">
        Cancel
       </button>
      )}
     </PayPalScriptProvider>
    </div>

    <div className={CartStyles.cart_Section_2}>
     <div className={CartStyles.cart_Section_2_Title_View}>
      <div className={CartStyles.cart_Section_2_Title}>Billing Details</div>
     </div>

     <div className={CartStyles.cart_Section_2_Content_View}>
      <div className={CartStyles.cart_Section_2_Content_Title}>
       {payment_data?.data.length +
        "  " +
        (payment_data?.data.length <= 1 ? "Course" : "Courses")}
      </div>

      {payment_data?.data.map((ele, ind) => {
       return (
        <div key={ind} className={CartStyles.cart_Section_2_Course_List}>
         <div className={CartStyles.cart_Section_2_Course_Name}>{ele.CourseName}</div>
         <div className={CartStyles.cart_Section_2_Course_Price}>
          {"AED" + " " + ele.Price}
         </div>
        </div>
       );
      })}

      <div className={CartStyles.cart_Section_2_Line_Style} />
      <div className={CartStyles.total_Count_View}>
       <div className={CartStyles.total_Count_Text}>Total</div>

       {(() => {
        totalPayment = payment_data?.data.reduce((a, b) => a + b.Price, 0);
        return <div className={CartStyles.total_Price_Text}>{"AED" + " " + totalPayment}</div>;
       })()}
      </div>

      {/* <div className={Styles.coupon_View}>
          <input
              placeholder="Enter Coupon"
              className={Styles.coupon_Text_input}
          />

          <div className={Styles.coupon_Button_View}>
              <div className={Styles.coupon_Button_Text}>
                  Apply
              </div>
          </div>
      </div> */}

      {/* <div
      onClick={() => {
       // buyCourse()
       //  setShowPayment(true);
      }}
      className={CartStyles.buyNow_Button_View}
     >
      <div className={CartStyles.buyNow_Button_Text}>Buy Now</div>
     </div> */}
     </div>
    </div>
   </div>
  </div>
 );
};

export default Payment_Screen;
