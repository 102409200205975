import React from 'react';
import Styles from '../styles/FAQ_Page.module.css';
import Search_Svg from '../../assets/svg/Searchicon_Svg';
import Dropdownicon from '../../assets/svg/Dropdownicon_Svg';
import Upward_Svg from '../../assets/svg/Upward_Svg';



const Listbox = ({ qn, ans, expanded, onClick }) => (
  <div className={Styles.lisitemcontainer}>
    <div className={Styles.dropdownnamelogocontainer} onClick={onClick}>
      <div className={Styles.dropdownnamecontainer}>
        <div className={Styles.aligning}>
          <p className={Styles.listtextqn}>
            {qn}
          </p>
        </div>
      </div>

      <div className={Styles.dropdown}>
        <a onClick={onClick} >
          {!expanded ? <Dropdownicon /> : <Upward_Svg />}
        </a>
      </div>
    </div>
    {expanded && (
      <div className={Styles.dropdowntextcontainer} >
        <p className={Styles.listtextans} >{ans}</p>
      </div>
    )}
  </div>
);

const FAQView = ({ faqs, expandedOption, handleOption }) => (
  <div className={Styles.container}>
    <div className={Styles.topcontainer}>
      <div className={Styles.bodycontainer}>
        <div className={Styles.headingcontainer}>
          <h1 className={Styles.heading}>FAQs</h1>
        </div>
        <div className={Styles.textcontainer}>
          <p className={Styles.text}>Have any questions? We're here to assist you.</p>
        </div>
        <div className={Styles.searchboxcontainer}>
          <a className={Styles.logocontainer}><Search_Svg /></a>
          <input className={Styles.searchbox} type='text' placeholder='Search here' />
        </div>
      </div>
    </div>

    <div className={Styles.listbodycontainer}>
      <div className={Styles.listbodycontainerarrangement}>
        {faqs.map((value) => (
          <div className={Styles.insidelistbodycontainer} key={value.option}>
            <Listbox
              qn={value.qn}
              ans={value.ans}
              expanded={expandedOption === value.option}
              onClick={() => handleOption(value.option)}
            />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default FAQView;
