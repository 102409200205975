import React, { useState } from "react";
import styles from "../css/ScoreCardModal.module.css";

const ScoreCardModal = (props) => {
 const strokeWidth = 10;
 const radius = (87 - strokeWidth) / 2; // Adjust the radius to fit within the SVG viewbox
 const circumference = 2 * Math.PI * radius;
 const offset = circumference - (props?.score / 100) * circumference;

 return (
  <div>
   <div className={styles.modalOverlay} onClick={() => props?.closeModel()}>
    <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
     <div className={styles.modalContent1}>
      <h2 className={styles.modalTitle}>Your Score</h2>
      <h2
       className={styles.resultTitle}
       style={{ color: props?.score > 35 ? "rgba(33, 181, 115, 1)" : "#FF7068" }}
      >
       {props?.score > 35 ? "Pass" : "Fail"}
      </h2>
      <div className={styles.percentageCont}>
       <div className={styles.percentageCircle}>
        <svg height="87" width="87" viewBox="0 0 87 87">
         <circle
          stroke={props?.score > 35 ? "rgba(33, 181, 115, 1)" : "#FF7068"}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          r={radius}
          cx="43.5" // (87 / 2)
          cy="43.5" // (87 / 2)
          strokeLinecap="round"
         />
        </svg>
        <div
         className={styles.percentage}
         style={{ color: props?.score > 35 ? "rgba(33, 181, 115, 1)" : "#FF7068" }}
        >
         {props?.score}%
        </div>
       </div>
      </div>
      <div className={styles.paraCont}>
       <p className={styles.modalParagraph}>
        {props?.score > 35
         ? "Great job! You have a strong understanding of the material."
         : "Your current understanding level is insufficient; you need to enhance your skills."}
       </p>
      </div>
     </div>
     <div className={styles.modalContent2}>
      <div className={styles.modalButtons}>
       {/* <div className={styles.submit_button_view}>
         <div className={styles.modalButton_Text}>Re-try</div>
        </div> */}
       <div className={styles.button_view} onClick={() => props?.onSubmit()}>
        <div className={styles.modalButton_Text}>Ok</div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default ScoreCardModal;
