import React, { useState } from 'react';
import Styles from '../styles/Existing_Session_Alert_Page.module.css';
import  Dialogpagecontroller  from '../Controller/Existing_Session_Alert_Page_Controller';
import {data} from '../Model/Existing_Session_Alert_Page_Model';

// import{onSubmit} from '';

const DialogPageView=()=>{
    
    const {
        // ChangeReplyNo,
        // ChangeReplyYes,
        onSubmit,
        onConfirmSubmit
    } = Dialogpagecontroller();


    return(
    <div className={Styles.main}>
        <div className={Styles.body}>
            <div className={Styles.boxcontainer}>
                <div className={Styles.qnbox}>
                    <p className={Styles.qn}>{data.qn} </p>
                </div>
                <div className={Styles.ansbox}>
                    <div className={Styles.yesbox}>
                        <div className={Styles.buttonbox}>
                            <div className={Styles.button} onClick={onConfirmSubmit}> Yes</div>
                        </div>
                    </div>
                    <div className={Styles.nobox}>
                        <div className={Styles.buttonbox}>
                            <div  className={Styles.button} onClick={onSubmit}> No </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
        
    )
}
export default DialogPageView;