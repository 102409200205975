import React from "react";

function Edit_Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M15.398 2.506a.593.593 0 01-.076.746L8.044 10.53a.594.594 0 01-.27.155l-3.03.792a.593.593 0 01-.725-.725l.792-3.03a.616.616 0 01.127-.247l7.305-7.3a.594.594 0 01.84 0l2.239 2.238c.028.029.054.06.076.094zm-1.336.326l-1.4-1.399L5.92 8.177l-.495 1.894 1.895-.495 6.743-6.744z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M13.524 12.572c.216-1.849.286-3.713.207-5.573a.32.32 0 01.092-.237l.78-.78a.156.156 0 01.267.101 36.34 36.34 0 01-.166 6.628 3.475 3.475 0 01-3.067 3.034 37.816 37.816 0 01-8.325 0A3.475 3.475 0 01.246 12.71a36.355 36.355 0 010-8.447A3.475 3.475 0 013.312 1.23c2.1-.233 4.216-.289 6.325-.168a.158.158 0 01.101.269l-.786.785a.318.318 0 01-.235.093c-1.76-.06-3.522.007-5.273.202a2.288 2.288 0 00-2.018 1.991 35.167 35.167 0 000 8.17 2.288 2.288 0 002.018 1.992c2.657.297 5.405.297 8.062 0a2.288 2.288 0 002.018-1.992z"
      ></path>
    </svg>
  );
}

export default Edit_Icon;
