import React from "react";

function Assessment_Svg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 15 16"
    >
      <path
        stroke={props.color}
        d="M.5.5h1v1h-1v-1zM.5 3.5h1v9.588L1 14.501l-.5-1.413V3.5z"
      ></path>
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M5.129 0a2.07 2.07 0 00-1.505.66c-.4.424-.624.998-.624 1.596v11.488c0 .598.224 1.172.624 1.595.399.423.94.661 1.505.661h7.742a2.07 2.07 0 001.505-.66c.4-.424.624-.998.624-1.596V4.692a1.49 1.49 0 00-.274-.866L12.405.57a1.376 1.376 0 00-.477-.42 1.293 1.293 0 00-.604-.15H5.13zm-.968 2.256c0-.566.434-1.025.968-1.025h5.613v3.608c0 .34.26.615.58.615h2.517v8.29c0 .566-.434 1.025-.968 1.025H5.129c-.534 0-.968-.46-.968-1.025V2.256z"
        clipRule="evenodd"
      ></path>
      <path
        fill={props.color}
        d="M12.115 8.27c0-.154-.062-.3-.174-.409a.604.604 0 00-.42-.169H6.765a.604.604 0 00-.42.17.568.568 0 000 .815.604.604 0 00.42.17h4.756c.157 0 .309-.062.42-.17a.568.568 0 00.174-.408zm0 3.076c0-.153-.062-.3-.174-.408a.604.604 0 00-.42-.169H6.765a.604.604 0 00-.42.17.568.568 0 000 .815.604.604 0 00.42.17h4.756c.157 0 .309-.062.42-.17a.568.568 0 00.174-.408z"
      ></path>
    </svg>
  );
}

export default Assessment_Svg;
