import Styles from '../styles/Registration_Page.module.css';
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Passwordfield = ({
    error,
    touched,
    handleSubmit,
    setFieldValue,
    type,
    placeholder,
    name,
    label,
    passwordEyeIconChange,
    passwordEyeIcon,
    passwordIconType,
    config,
  }) => {
    return (
      <div>
        <div className={Styles.passwordBox} style={{ position: "relative" }}>
                  <label className={Styles.field}>{label}</label>
                  <span className={Styles.requiredFieldSymbol}>*</span>
                  <br />
                  <input
                    type={passwordIconType}
                    placeholder={placeholder}
                    name={name}
                    className={`${Styles.fieldInput} ${
                      error&& touched ? Styles.fieldInputError : ""
                    }`}
                    onChange={(val) => {
                      setFieldValue(name, val.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit();
                      }
                    }}
                    maxLength={config}
                  />
                  <span onClick={passwordEyeIconChange} className={Styles.eyeIcon}>
                    {passwordIconType === "password" ?   <FaEyeSlash size={20}/>:<FaEye size={20}/>}
                    </span>
                  {/* <div className={Styles.eyeIcon} onClick={passwordEyeIconChange}>
                    {passwordEyeIcon ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                  </div> */}
                  {/* <div className={Styles.eyeIcon} onClick={passwordEyeIconChange}>
                    {passwordEyeIcon ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                  </div> */}
                  <div className={Styles.formikErrorText}>
                    <div>
                      {error && touched && error && (
                        <AiOutlineExclamationCircle size={14} />
                      )}
                      
                    </div>
                    <div>{error && touched && error}</div>
                    
                  </div>
                </div>
              </div>
    )}

export default Passwordfield;
