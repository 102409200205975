import { configureStore } from "@reduxjs/toolkit";
import applicationReducer from "./reducer";

const store = configureStore({
 reducer: {
  applicationState: applicationReducer,
 },
});

export default store;
