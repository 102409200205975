// SupportPageView.js
import Styles from '../styles/Support_Page.module.css';
import Search_Svg from "../../assets/svg/Searchicon_Svg";
import Dropdownicon from '../../assets/svg/Dropdownicon_Svg';
import Upward_Svg from '../../assets/svg/Upward_Svg';
import Message_logo_Svg from '../../assets/svg/Message_logo_Svg';
import Call_logo_Svg from '../../assets/svg/Call_logo_Svg';
import Copy_icon_Svg from '../../assets/svg/Copy_icon_Svg';

const Listbox = ({ qn, ans, expanded, onClick }) => {
    return (
        <div className={Styles.lisitemcontainer}>
            <div className={Styles.dropdownnamelogocontainer} onClick={onClick}>
                <div className={Styles.dropdownnamecontainer}>
                    <div className={Styles.aligning}>
                        <p className={Styles.listtextqn}>{qn}</p>
                    </div>
                </div>
                <div className={Styles.dropdown}>
                    {!expanded ? <Dropdownicon /> : <Upward_Svg />}
                </div>
            </div>
            {expanded && (
                <div className={Styles.dropdowntextcontainer}>
                    <p className={Styles.listtextans}>{ans}</p>
                </div>
            )}
        </div>
    );
};

const SupportPageView = ({ values, expandedOption, handleOption, text, phno, handleCopy }) => {
    return (
        <div className={Styles.container}>
            <div className={Styles.topcontainer}>
                <div className={Styles.bodycontainer}>
                    <div className={Styles.headingcontainer}>
                        <h1 className={Styles.heading}>Support</h1>
                    </div>
                    <div className={Styles.textcontainer}>
                        <p className={Styles.text}>Have any questions? We're here to assist you.</p>
                    </div>
                    <div className={Styles.searchboxcontainer}>
                        <div className={Styles.logocontainer}>
                            <Search_Svg />
                        </div>
                        <input className={Styles.searchbox} type='text' placeholder='Search here' />
                    </div>
                </div>
            </div>

            <div className={Styles.listbodycontainer}>
                <div className={Styles.listbodycontainerarrangement}>
                    {values.map((value, index) => (
                        <div className={Styles.insidelistbodycontainer} key={index}>
                            <Listbox
                                qn={value.qn}
                                ans={value.ans}
                                expanded={expandedOption === value.option}
                                onClick={() => handleOption(value.option)}
                            />
                        </div>
                    ))}
                </div>
                <div className={Styles.downspace}>
                    <div className={Styles.body2container}>
                        <div className={Styles.logonamedivision}>
                            <div className={Styles.rowwisedivision}>
                                <div className={Styles.logocontainer1}>
                                    <Message_logo_Svg />
                                </div>
                                <div className={Styles.mailandnamelogocontainer}>
                                    <div className={Styles.heading1}>
                                        <p className={Styles.mailtext1}>Mail us</p>
                                    </div>
                                    <div className={Styles.downtext}>
                                        <p className={Styles.mailtext2}>Get answer from our experts</p>
                                    </div>
                                    <div className={Styles.downtext}>
                                        <p className={Styles.mailtext3}>
                                            {text} <span className='mailtext3' onClick={() => handleCopy(text)}><Copy_icon_Svg /></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={Styles.rowwisedivision}>
                                <div className={Styles.logocontainer1}>
                                    <Call_logo_Svg />
                                </div>
                                <div className={Styles.mailandnamelogocontainer}>
                                    <div className={Styles.heading1}>
                                        <p className={Styles.calltext1}>Call us</p>
                                    </div>
                                    <div className={Styles.downtext}>
                                        <p className={Styles.calltext2}>Tell us more we will help</p>
                                    </div>
                                    <div className={Styles.downtext}>
                                        <p className={Styles.calltext3}>
                                            {phno} <span className='mailtext3' onClick={() => handleCopy(phno)}><Copy_icon_Svg /></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupportPageView;
