import React, { useState } from "react";
import styles from "../css/ModalComponent.module.css";

const Modal_Component = (props) => {
 const [isChecked, setChecked] = useState(false);

 const handleCheckboxChange = () => setChecked(!isChecked);

 return (
  <div>
   <div className={styles.modalOverlay} onClick={() => props?.closeModel()}>
    <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
     {/* <button className={styles.closeButton} onClick={closeModal}>
              X
            </button> */}
     <div className={styles.modalContent1}>
      <h2 className={styles.modalTitle}>Ready to Submit?</h2>
      <p className={styles.modalParagraph}>
       Please review all your answers. Once you submit, you cannot make any changes.
      </p>
     </div>
     <div className={styles.modalContent2}>
      <div className={styles.modalContentsCont}>
       <div className={styles.checkbox}>
        <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
       </div>
       <div className={styles.checkboxText}>
        <h6 className={styles.modalParagraph2}>
         I have reviewed my answers and am ready to submit
        </h6>
       </div>
      </div>
      <div className={styles.modalButtons}>
       <div
        className={styles.submit_button_view}
        style={{
         backgroundColor: isChecked ? "var(--primary-color)" : "var(--secondary-color)",
        }}
        onClick={() => isChecked && props?.onSubmit()}
       >
        <div className={styles.submit_button_Text}>Submit</div>
       </div>
       <div className={styles.button_view} onClick={() => props?.closeModel()}>
        <div className={styles.modalButton_Text}>Back</div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default Modal_Component;
