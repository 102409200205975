import React from 'react';

const Searchicon_Svg=()=>{
    return(

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.6468 13.2357L10.9809 10.5697M12.4211 7.10703C12.4211 9.81485 10.226 12.01 7.51817 12.01C4.81035 12.01 2.61523 9.81485 2.61523 7.10703C2.61523 4.39922 4.81035 2.2041 7.51817 2.2041C10.226 2.2041 12.4211 4.39922 12.4211 7.10703Z" stroke="#53686A" stroke-width="1.22573" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

)}

export default  Searchicon_Svg;
