import RunTimeConstant from "../../config/RuntimeConfig";
import { apiRequest } from "../Interceptor";
import { API_HEADER, API_TIMEOUT } from "./Config";

/**
 *
 * @param {*} payload payload sent to the server
 */
export const PostMethod = async (payload) => {
 console.log("payload", payload);
 // here we can add manual timeout(promise.race()) to clear the api call
 // console.log("RunTimeConstant.BASEURL", RunTimeConstant.BASEURL, process.env.NODE_ENV);
 let promise = new Promise(async (resolve, reject) => {
  await apiRequest({
   method: "post",
   url: payload.url,
   baseURL: RunTimeConstant.BASEURL,
   timeout: API_TIMEOUT,
   headers: payload.header ? payload.header : API_HEADER,
   data: payload.data,
  })
   .then((res) => {
    // console.log('resttttttttttttttt', res );
    resolve(res.data); //status and data if(200){sucess} else{condition}
   })
   .catch((err) => {
    console.log("err", err);
    reject(err);
   });
 });
 return promise;
};

export const newPostMethod = async (payload) => {
  console.log("payload", payload);
  // here we can add manual timeout(promise.race()) to clear the api call
  // console.log("RunTimeConstant.BASEURL", RunTimeConstant.BASEURL, process.env.NODE_ENV);
  let promise = new Promise(async (resolve, reject) => {
   await apiRequest({
    method: "post",
    url: payload.url,
    baseURL: RunTimeConstant.SERVICEURL.AMS,
    timeout: API_TIMEOUT,
    headers: payload.header ? payload.header : API_HEADER,
    data: payload.data,
    withCredentials: true,
   })
    .then((res) => {
     console.log('resttttttttttttttt', res );
     console.log('resttttttttttttttt', res.data );
     resolve(res); //status and data if(200){sucess} else{condition}
    })
    .catch((err) => {

     console.log("err", err);
     reject(err);
    });
  });
  return promise;
 };

 export const newLoginPostMethod = async (payload) => {
  console.log("payload", payload);
  // here we can add manual timeout(promise.race()) to clear the api call
  // console.log("RunTimeConstant.BASEURL", RunTimeConstant.BASEURL, process.env.NODE_ENV);
  let promise = new Promise(async (resolve, reject) => {
   await apiRequest({
    method: "post",
    url: payload.url,
    baseURL: RunTimeConstant.SERVICEURL.IMS,
    timeout: API_TIMEOUT,
    headers: payload.header ? payload.header : API_HEADER,
    data: payload.data,
    withCredentials: true
   })
    .then((res) => {
     console.log('rest obj', res );
     console.log('rest data', res.data );
     resolve(res); //status and data if(200){sucess} else{condition}
    })
    .catch((err) => {
     console.log("err hello", err);
     reject(err);
    });
  });
  return promise;
 };

 export const newLoginGetMethod = async (payload) => {
  console.log("payload", payload);
  // here we can add manual timeout(promise.race()) to clear the api call
  // console.log("RunTimeConstant.BASEURL", RunTimeConstant.BASEURL, process.env.NODE_ENV);
  let promise = new Promise(async (resolve, reject) => {
   await apiRequest({
    method: "get",
    url: payload.url,
    baseURL: RunTimeConstant.SERVICEURL.IMS,
    timeout: API_TIMEOUT,
    headers: payload.header ? payload.header : API_HEADER,
    data: payload.data,
    withCredentials: true
   })
    .then((res) => {
     resolve(res); 
    })
    .catch((err) => {
     console.log("err", err);
     reject(err);
    });
  });
  return promise;
 };

 export const getSessionMethod = async (payload) => { //to get the session 
  console.log("payload", payload);

  let promise = new Promise(async (resolve, reject) => {
   await apiRequest({
    method: "get",
    url: payload.url,
    baseURL: RunTimeConstant.SERVICEURL.CMS,
    timeout: API_TIMEOUT,
    headers: payload.header ? payload.header : API_HEADER,
    data: payload.data,
    withCredentials: true
   })
    .then((res) => {
     resolve(res); 
    })
    .catch((err) => {
     console.log("err", err);
     reject(err);
    });
  });
  return promise;
 };

 export const deleteExistingSession = async (payload) => { //to get the session 
  console.log("payload", payload);

  let promise = new Promise(async (resolve, reject) => {
   await apiRequest({
    method: "delete",
    url: payload.url,
    baseURL: RunTimeConstant.SERVICEURL.CMS,
    timeout: API_TIMEOUT,
    headers: payload.header ? payload.header : API_HEADER,
    data: payload.data,
    withCredentials: true
   })
    .then((res) => {
     resolve(res); 
    })
    .catch((err) => {
     console.log("err", err);
     reject(err);
    });
  });
  return promise;
 };




//  export const newGetMethod = async (payload) => {
//   console.log("payload", payload);
//   // here we can add manual timeout(promise.race()) to clear the api call
//   // console.log("RunTimeConstant.BASEURL", RunTimeConstant.BASEURL, process.env.NODE_ENV);
//   let promise = new Promise(async (resolve, reject) => {
//    await apiRequest({
//     method: "get",
//     url: payload.url,
//     baseURL: RunTimeConstant.BASEURL,
//     timeout: API_TIMEOUT,
//     headers: payload.header ? payload.header : API_HEADER,
//     data: payload.data,
//    })
//     .then((res) => {
//      // console.log('resttttttttttttttt', res );
//      resolve(res.data); //status and data if(200){sucess} else{condition}
//     })
//     .catch((err) => {
//      console.log("err", err);
//      reject(err);
//     });
//   });
//   return promise;
//  };
