import { useEffect, useState } from "react";
import { CONFIG } from "../../config/Constant";
import Landing_Svg from "../../assets/svg/Landing_Svg";
import Styles from "./../css/Login.module.css";
import { Formik } from "formik";
import * as Yup from "yup";
import App_Logo from "../../assets/svg/app_logo";
import LoginReg_Svg from "../../assets/svg/LoginReg_Svg";
import { API_URL } from "../../api/URL/Api_Route";
import { useNavigate } from "react-router-dom";
import { PostMethod } from "../../api/request/Api_Calls";
import Loader from "../../component/javascript/loader";
import NetworkError from "../../component/javascript/Network_Error";
import Header_Component from "../../component/javascript/Header_Component";
import queryString from "query-string";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import toast from "react-hot-toast";

const ForgetPassword = () => {
 const navigate = useNavigate();
 const [is_small_screen, setIsSmallScreen] = useState(false);
 const [is_landscape, setIsLandscape] = useState(false);
 const [is_network_error, set_is_network_error] = useState(false);
 const [isLoading, setIsLoading] = useState(false);
 useEffect(() => {
  const handleResize = () => {
   if (window.innerWidth <= 772) {
    setIsSmallScreen(true);
   } else {
    setIsSmallScreen(false);
   }
   console.log("window.innerWidth", window.innerWidth);
   // Check for landscape orientation with a max-width of 767 pixels
   const isLandscapeOrientation = window.matchMedia(
    "(max-height: 575.98px) and (orientation: landscape)"
   ).matches;
   setIsLandscape(isLandscapeOrientation);
  };
  handleResize();
  window.addEventListener("resize", handleResize);
  return () => {
   window.removeEventListener("resize", handleResize);
  };
 }, []);
 const ValidationSchema = Yup.object().shape({
  user_email: Yup.string()
   .required("Email is a required field")
   .test("notEmpty", "Email cannot be empty", (value) => value.trim() !== "")
   .email("Invalid email"),
 });
 const onSubmit = (form_data) => {
  console.log("forget values", form_data);
  setIsLoading(true);
  let payload = {
   url: API_URL.forgetPassword,
   data: {
    EmailId: form_data.user_email,
   },
  };
  PostMethod(payload)
   .then(async (res) => {
    // setLoader(false)
    setIsLoading(false);
    console.log("forget res", res);
    toast.dismiss();
    if (res.success) {
     let data = {
      id: 101,
      email: form_data.user_email,
     };
     toast.success(res.message);
     const queryStringified = queryString.stringify(data);
     navigate(`/otp?${queryStringified}`);
    } else {
     console.log(res.message);
     toast.dismiss();
     toast.error(res.message);
     // console.log('login not res')
    }
   })
   .catch((err) => {
    setIsLoading(false);
    set_is_network_error(true);
    // setLoader(false)
    console.log(" login catch err", err);
   });
 };
 if (isLoading) {
  console.log("isLoading isLoading", isLoading);
  return <Loader isLoading={isLoading} callBack={() => setIsLoading(false)} />;
 }
 if (is_network_error) {
  return <NetworkError callBack={() => set_is_network_error(false)} />;
 }
 return (
  <div className={Styles.body}>
   {/* <div style={{ paddingBottom: "6%" }}>
    <Header_Component />
   </div> */}
   <Formik
    initialValues={{
     user_email: "",
    }}
    onSubmit={onSubmit}
    validationSchema={ValidationSchema}
   >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
     <div className={Styles.outLine}>
      <div className={Styles.svgPart}>
       {is_small_screen ? (
        ""
       ) : (
        <LoginReg_Svg
        // width={ width } height={ height }
        />
       )}
      </div>
      <div className={Styles.formPart}>
       <div className={Styles.form}>
        <div className={Styles.signInText}>Forget Password</div>
        <div>
         <label className={Styles.field}>User Email</label>
         <span className={Styles.requiredFieldSymbol}>*</span>
         <br />
         <input
          type="text"
          name="user_email"
          placeholder="Enter your user email"
          className={`${Styles.fieldInput} ${
           errors.user_email && touched.user_email ? Styles.fieldInputError : ""
          }`}
          onChange={(val) => {
           setFieldValue("user_email", val.target.value);
          }}
          onKeyDown={(e) => {
           if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit();
           }
          }}
          maxLength={CONFIG.USER_EMAIL_MAX_LENGTH}
         />
         <div className={Styles.formikErrorText}>
          <div>
           {errors.user_email && touched.user_email && errors.user_email && (
            <AiOutlineExclamationCircle size={14} />
           )}
          </div>
          <div>{errors.user_email && touched.user_email && errors.user_email}</div>
         </div>
        </div>
        <div className={Styles.signInButton} onClick={handleSubmit}>
         Generate OTP
        </div>
       </div>
      </div>
     </div>
    )}
   </Formik>
  </div>
 );
};

export default ForgetPassword;
