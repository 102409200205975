import { useEffect, useState } from "react";
import { CONFIG } from "../../config/Constant";
import Landing_Svg from "../../assets/svg/Landing_Svg";
import Styles from "./../css/Registation.module.css";
import { Formik } from "formik";
import * as Yup from "yup";
import App_Logo from "../../assets/svg/app_logo";
import LoginReg_Svg from "../../assets/svg/LoginReg_Svg";
import { API_URL } from "../../api/URL/Api_Route";
import { PostMethod } from "../../api/request/Api_Calls";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../component/javascript/loader";
import NetworkError from "../../component/javascript/Network_Error";
import Header_Component from "../../component/javascript/Header_Component";
import queryString from "query-string";
import toast from "react-hot-toast";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { AiOutlineExclamationCircle } from "react-icons/ai";

const PasswordReset = (props) => {
 const navigate = useNavigate();
 const [is_small_screen, setIsSmallScreen] = useState(false);
 const [is_landscape, setIsLandscape] = useState(false);
 const [is_network_error, set_is_network_error] = useState(false);
 const [isLoading, setIsLoading] = useState(false);
 const [passwordIconType, setPasswordIconType] = useState("password");
 const [passwordEyeIcon, setPasswordEyeIcon] = useState(false);
 const [confirmPasswordIconType, setConfirmPasswordIconType] = useState("password");
 const [confirmPasswordEyeIcon, setConfirmPasswordEyeIcon] = useState(false);

 const location = useLocation();
 const queryParams = queryString.parse(location.search);
 useEffect(() => {
  const handleResize = () => {
   if (window.innerWidth <= 772) {
    setIsSmallScreen(true);
   } else {
    setIsSmallScreen(false);
   }
   // Check for landscape orientation with a max-width of 767 pixels
   const isLandscapeOrientation = window.matchMedia(
    "(max-height: 575.98px) and (orientation: landscape)"
   ).matches;
   setIsLandscape(isLandscapeOrientation);
  };
  handleResize();
  window.addEventListener("resize", handleResize);
  return () => {
   window.removeEventListener("resize", handleResize);
  };
 }, []);

 console.log("queryParams", queryParams);

 const ValidationSchema = Yup.object().shape({
  password: Yup.string()
   .required("Password is required")
   .matches(/\w*[a-z]\w*/, "Password must have a small letter")
   .matches(/\w*[A-Z]\w*/, "Password must have a capital letter")
   .matches(/\d/, "Password must have a number")
   .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, "Password must have a special character")
   .min(8, ({ min }) => `Password must be at least 8 characters`),
  confirm_password: Yup.string()
   .oneOf([Yup.ref("password")], "Passwords do not match")
   .required("Confirm password is required"),
 });

 const onSubmit = (form_data) => {
  setIsLoading(true);
  let payload = {
   url: API_URL.resetPassword,
   data: {
    EmailId: queryParams.email,
    Password: form_data?.password,
   },
  };
  PostMethod(payload)
   .then(async (res) => {
    // setLoader(false)
    setIsLoading(false);
    console.log("register res", res);
    // toast.dismiss()
    if (res.success) {
     if (queryParams?.id == "101") {
      navigate("/login");
     } else if (queryParams?.id == "500") {
      navigate("/profile");
     } else {
      navigate("/");
     }
     toast.dismiss();
     toast.success(res.message);
    } else {
     toast.dismiss();
     toast.error(res.message);
     // console.log('login not res')
    }
   })
   .catch((err) => {
    setIsLoading(false);
    set_is_network_error(true);
    // setLoader(false)
    console.log(" login catch err", err);
   });
 };

 const passwordEyeIconChange = () => {
  // console.log('eye')
  if (passwordIconType == "password") {
   setPasswordEyeIcon(true);
   setPasswordIconType("text");
  } else if (passwordIconType == "text") {
   setPasswordEyeIcon(false);
   setPasswordIconType("password");
  }
 };

 const confirmPasswordEyeIconChange = () => {
  // console.log('eye')
  if (confirmPasswordIconType == "password") {
   setConfirmPasswordEyeIcon(true);
   setConfirmPasswordIconType("text");
  } else if (confirmPasswordIconType == "text") {
   setConfirmPasswordEyeIcon(false);
   setConfirmPasswordIconType("password");
  }
 };

 if (isLoading) {
  console.log("isLoading isLoading", isLoading);
  return <Loader isLoading={isLoading} callBack={() => setIsLoading(false)} />;
 }
 if (is_network_error) {
  return <NetworkError callBack={() => set_is_network_error(false)} />;
 }
 return (
  <div className={Styles.body}>
   {/* {queryParams?.id != 500 && (
            <div style={{paddingBottom:'6%'}}>
                <Header_Component />
            </div>
            )} */}
   <Formik
    initialValues={{
     password: "",
     confirm_password: "",
    }}
    onSubmit={onSubmit}
    validationSchema={ValidationSchema}
   >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
     <div className={Styles.outLine}>
      <div className={Styles.svgPart}>
       {is_small_screen ? (
        ""
       ) : (
        <LoginReg_Svg
        // width={ width } height={ height }
        />
       )}
      </div>
      <div className={Styles.formPart}>
       <div className={Styles.form}>
        <div className={Styles.signInText}>Change Password</div>
        <div style={{ position: "relative" }}>
         <label className={Styles.field}>Password</label>
         <span className={Styles.requiredFieldSymbol}>*</span>
         <br />

         <div className={Styles.password_Field_View}>
          <input
           type={passwordIconType}
           placeholder="Enter new Password"
           name="password"
           className={`${Styles.fieldInput} ${
            errors.password && touched.password ? Styles.fieldInputError : ""
           }`}
           // style={{width:'85%'}}
           onChange={(val) => {
            setFieldValue("password", val.target.value);
           }}
           onKeyDown={(e) => {
            if (e.key === "Enter") {
             e.preventDefault();
             handleSubmit();
            }
           }}
           maxLength={CONFIG.PASSWORD_MAX_LENGTH}
          />

          <div className={Styles.eyeIcon} onClick={passwordEyeIconChange}>
           {passwordEyeIcon ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
          </div>
         </div>
         <div className={Styles.formikErrorText}>
          <div>
           {errors.password && touched.password && errors.password && (
            <AiOutlineExclamationCircle size={14} />
           )}
          </div>
          <div>{errors.password && touched.password && errors.password}</div>
         </div>
        </div>
        <div style={{ position: "relative" }}>
         <label className={Styles.field}>Confirm Password</label>
         <span className={Styles.requiredFieldSymbol}>*</span>
         <br />

         <div className={Styles.password_Field_View}>
          <input
           type={confirmPasswordIconType}
           placeholder="Retype your Password"
           name="confirm_password"
           className={`${Styles.fieldInput} ${
            errors.confirm_password && touched.confirm_password ? Styles.fieldInputError : ""
           }`}
           onChange={(val) => {
            setFieldValue("confirm_password", val.target.value);
           }}
           onKeyDown={(e) => {
            if (e.key === "Enter") {
             e.preventDefault();
             handleSubmit();
            }
           }}
           maxLength={CONFIG.PASSWORD_MAX_LENGTH}
          />
          <div className={Styles.eyeIcon} onClick={confirmPasswordEyeIconChange}>
           {confirmPasswordEyeIcon ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
          </div>
         </div>
         <div className={Styles.formikErrorText}>
          <div>
           {errors.confirm_password && touched.confirm_password && errors.confirm_password && (
            <AiOutlineExclamationCircle size={14} />
           )}
          </div>
          <div>
           {errors.confirm_password && touched.confirm_password && errors.confirm_password}
          </div>
         </div>
        </div>
        <div className={Styles.signInButton} tabIndex={0} onClick={handleSubmit}>
         Reset My Password
        </div>
       </div>
      </div>
     </div>
    )}
   </Formik>
  </div>
 );
};

export default PasswordReset;
