import { useNavigate } from "react-router-dom";
import Profile_Svg from "../../assets/svg/Profile_Svg";
import Styles from "../css/Profile.module.css";
import { useDispatch, useSelector } from "react-redux";
import { LogOut, sessionDetails, tabId , WebsiteInit  } from "../../redux/action";
import { useEffect, useState } from "react";
import { PostMethod ,newLoginGetMethod } from "../../api/request/Api_Calls";
import toast from "react-hot-toast";
import RunTimeConstant from "../../config/RuntimeConfig";
import { API_URL } from "../../api/URL/Api_Route";
import Loader from "../../component/javascript/loader";
import NetworkError from "../../component/javascript/Network_Error";
import queryString from "query-string";
import Cookies from 'js-cookie';

const Profile = () => {
 const default_Image = require("../../assets/image/avatar.png");

 const { token } = useSelector((state) => state.applicationState);

 const navigate = useNavigate();
 const dispatch = useDispatch();
 const [is_Loading, set_Is_Loading] = useState(false);
 const [is_Network_Error, set_Is_Network_Error] = useState(false);
 const [profileDetails, setProfileDetails] = useState({});

 const buttons = [
  {
   id: 0,
   name: "Edit Password",
  },
  {
   id: 1,
   name: "Log Out",
  },
  // {
  //     id:2,
  //     name: 'Delete Account'
  // },
 ];

 const generateOTP = () => {
  let payload = {
   url: API_URL.forgetPassword,
   data: {
    EmailId: profileDetails?.EmailId,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("generateOTP response", response);
    if (response.success) {
     let data = {
      id: 102,
      email: profileDetails?.EmailId,
     };
     const queryStringified = queryString.stringify(data);
     navigate(`/otpAuth?${queryStringified}`);
     toast.dismiss();
     toast.success(response.message);
    } else {
     toast.dismiss();
     toast.error(response.message);
    }
   })
   .catch((error) => {
    set_Is_Network_Error(true);
   });
 };

 const buttonLink = (ID) => {
  if (ID == 0) {
   generateOTP();
  } 
  else if (ID == 1) {
    let payload = {
      url: API_URL.logout,
      data:{
        CUDUSID:Cookies.get('CUDUSID')
      }
      
     };
     newLoginGetMethod(payload)
      .then(async (res) => {
       // setLoader(false)
      //  setIsLoading(false);
       console.log("login res", res);
       
  
       if (res?.status==200) {

        navigate("/");
        dispatch(LogOut());
        console.log('<===== LOG OUT SUCCCESSFUL =====>')
        toast.dismiss();
        toast.success("User Logged out successfully");
         

         }})
         .catch((err)=>{
          console.log("Error is ===>",err);
        })

  } 
  



  else if (ID == 2) {
  }
 };

 const getProfileDetails = () => {
  set_Is_Loading(true);
  let payload = {
   url: API_URL.usersAccount,
   data: {
    _id: token,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("getProfileDetails response", response);
    if (response.success) {
     const new_data = response?.data;
     if (new_data?.Image != "") {
      new_data.profileImage = `${RunTimeConstant.BASEURL}assert/image?id=${encodeURIComponent(
       "PROFILE/" + new_data.Image
      )}`;
     } else {
      new_data.profileImage = default_Image;
     }
     setProfileDetails(new_data);
     set_Is_Loading(false);
    } else {
     set_Is_Loading(false);
     toast.dismiss();
     toast.error(response.message);
    }
   })
   .catch((error) => {
    console.log("getProfileDetails error", error);
    set_Is_Loading(false);
    set_Is_Network_Error(true);
   });
 };

 useEffect(() => {
  dispatch(tabId({ tab_Id: 6 }));
  getProfileDetails();
 }, [is_Network_Error]);

 const editProfile = () => {
  const queryStringified = queryString.stringify(profileDetails);
  navigate(`/editProfile?${queryStringified}`);
 };

 if (is_Loading) {
  return <Loader isLoading={is_Loading} callBack={() => set_Is_Loading(false)} />;
 }

 if (is_Network_Error) {
  return <NetworkError callBack={() => set_Is_Network_Error(false)} />;
 }

 return (
  <div className={Styles.profile_Container}>
   <div className={Styles.profile_Body}>
    {/* <div className={Styles.profile_Section_1}>
                    <Profile_Svg/>
                </div> */}

    <div className={Styles.profile_Section_2}>
     <div className={Styles.profile_Title}>Profile</div>

     <div className={Styles.profile_Card_View}>
      <div className={Styles.profile_Image_View}>
       <img
        src={profileDetails?.profileImage}
        crossOrigin="anonymous"
        className={Styles.profile_Image_fit}
       />
      </div>

      <div className={Styles.profile_Card_Content}>
       <div>
        <div className={Styles.profile_Name}>{profileDetails?.UserName}</div>

        <div className={Styles.profile_Email}>{profileDetails?.EmailId}</div>
       </div>

       <div className={Styles.edit_Profile_Button} onClick={() => editProfile()}>
        Edit Profile
       </div>
      </div>
     </div>

     <div className={Styles.profile_Data_Container}>
      {/* {profile_data.map((ele, ind)=>{
                            return( */}
      <div className={Styles.profile_Data_View}>
       <div className={Styles.profile_Data_Title}>{"DOB"}</div>
       <div className={Styles.profile_Data_Content}>
        {profileDetails?.DateOfBirth ? profileDetails?.DateOfBirth : "Your date of birth"}
       </div>
      </div>

      <div className={Styles.profile_Data_View}>
       <div className={Styles.profile_Data_Title}>{"Mobile Number"}</div>
       <div className={Styles.profile_Data_Content}>
        {profileDetails?.MobileNumber ? profileDetails?.MobileNumber : "Your mobile number"}
       </div>
      </div>

      <div className={Styles.profile_Data_View}>
       <div className={Styles.profile_Data_Title}>{"Region"}</div>
       <div className={Styles.profile_Data_Content}>
        {profileDetails?.Region ? profileDetails?.Region : "Your region"}
       </div>
      </div>
      {/* )
                        })} */}
     </div>

     <div>
      {buttons.map((ele, ind) => {
       return (
        <div  className={Styles.button_Name_View}>
         <div key={ele.id}  onClick={() => buttonLink(ele.id)} className={Styles.button_Name}>
          {ele.name}
         </div>
        </div>
       );
      })}
     </div>
    </div>
   </div>
  </div>
 );
};

export default Profile;
