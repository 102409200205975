import React, { useState } from "react";
import styles from "../css/Exam_Instruction.module.css";

const Exam_Instruction = (props) => {
 const [isChecked, setChecked] = useState(false);

 const handleCheckboxChange = () => setChecked(!isChecked);

 return (
  <div>
   <div className={styles.modalOverlay} onClick={() => props?.closeModel()}>
    <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
     {/* <button className={styles.closeButton} onClick={closeModal}>
              X
            </button> */}
     <div className={styles.modalContent1}>
      <h2 className={styles.modalTitle}>Exam Instruction</h2>
      <p className={styles.modalParagraph}>
       <li>The minimum score required to pass this exam is 35%.</li>
       <li style={{ paddingTop: "10px" }}>
        Submit each answer as you complete the questions.
       </li>
       <li style={{ paddingTop: "10px" }}>
        Once you have answered all the questions, submit the entire test for evaluation.
       </li>
      </p>
     </div>
     <div className={styles.modalContent2}>
      <div className={styles.modalButtons}>
       <div
        className={styles.submit_button_view}
        style={{
         backgroundColor: "var(--primary-color)",
        }}
        onClick={() => props?.onSubmit()}
       >
        <div className={styles.submit_button_Text}>Start Exam</div>
       </div>
       <div className={styles.button_view} onClick={() => props?.closeModel()}>
        <div className={styles.modalButton_Text}>Back</div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default Exam_Instruction;
