import React from 'react';
import { useState } from 'react';
import { text, phno, values } from '../Model/Support_Page_Model';
import SupportPageView from '../View/Support_Page_View';
import copy from 'copy-to-clipboard';
import toast from "react-hot-toast";

export const SupportPageController = () => {
    const [expandedOption, setExpandedOption] = useState(false);

    const handleOption = (option) => {
        setExpandedOption(expandedOption === option ? false : option);
    };

    const handleCopy = (data) => {
        copy(data);
        toast.success(`Copied "${data}"`);
    };

    return {
        expandedOption,
        handleOption,
        handleCopy,
        text,
        phno,
        values,
    };
};





const SupportPage = () => {
    const controller = SupportPageController();

    return (
        <SupportPageView
            values={controller.values}
            expandedOption={controller.expandedOption}
            handleOption={controller.handleOption}
            text={controller.text}
            phno={controller.phno}
            handleCopy={controller.handleCopy}
        />
    );
};

export default SupportPage;

