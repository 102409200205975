import { useEffect, useLayoutEffect, useRef, useState } from "react";
import Assessment_Svg from "../../assets/svg/Assessment_Svg";
import Document_Svg from "../../assets/svg/Document_Svg";
import Video_Svg from "../../assets/svg/Video_Svg";
import Styles from "../css/Course_Content.module.css";
import { BsChevronCompactDown, BsChevronCompactUp } from "react-icons/bs";
import { cartItemCount, sessionDetails, tabId } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { AiFillCheckCircle, AiFillWarning, AiOutlineShoppingCart } from "react-icons/ai";
import { API_URL } from "../../api/URL/Api_Route";
import { PostMethod } from "../../api/request/Api_Calls";
import toast from "react-hot-toast";
import Loader from "../../component/javascript/loader";
import NetworkError from "../../component/javascript/Network_Error";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RunTimeConstant from "../../config/RuntimeConfig";
import { getLocalStorage, setLocalStorage } from "../../storage/localStorage";
import VideoPlayer from "./video";
import queryString from "query-string";
import PaypalButton from "../../component/javascript/PayPal_Payment";
import Modal_Component from "../../component/javascript/ModalComponent";
import Exam_Instruction from "../../component/javascript/Exam_Instruction";





const Course_Content = () => {
 const initial_video = require("../../assets/video/subjectnameissue.mp4");
 const Change_Video = require("../../assets/video/sample_video.mp4");

 const hint_points = [
  "trigonometric functions,",
  "Exponential functions,",
  "logarithmic functions, implicit,",
  "Inverse functions",
 ];

 const dispatch = useDispatch();
 const navigate = useNavigate();
 const location = useLocation();
 //  const { course_id } = useParams();
 const queryParams = queryString.parse(location.search);
 const parsedData = JSON.parse(queryParams?.data);
 const videoRef = useRef(null);

 const { token, cart_item_count } = useSelector((state) => state.applicationState);

 const [selected_Chapter_Id, set_Selected_Chapter_Id] = useState(0);
 const [selected_Topic_Id, set_Selected_Topic_Id] = useState(0);
 const [open_Topic, set_Open_Topic] = useState(true);
 const [open_Topic_Assests, set_Open_Topic_Assests] = useState(true);
 const [is_Small_Screen, set_Is_Small_Screen] = useState(false);
 const [is_Loading, set_Is_Loading] = useState(false);
 const [is_Network_Error, set_Is_Network_Error] = useState(false);
 const [course_Data, set_Course_Data] = useState({});
 const [chapter_Data, set_Chapter_Data] = useState([]);
 const [topic_Data, set_Topic_Data] = useState([]);
 const [video, set_Video] = useState(Change_Video);
 const [chapter_Loader, set_Chapter_Loader] = useState(true);
 const [topic_Loader, set_Topic_Loader] = useState(true);
 const [selected_Topi_Name, set_Selected_Topic_Name] = useState(null);
 const [video_url, setVideo_url] = useState("");
 const [showPayment, setShowPayment] = useState(false);
 const [showModal, setShowModal] = useState(false);
 const [currentAssessment, setCurrentAssessment] = useState("");

 // console.log("topic_Data", topic_Data)
 
// const { session_details} = useSelector(
//   (state) => state.applicationState
//  );

//  console.log("Hello all fro  sessio 1234567 ====>",session_details)

 const handleResize = () => {
  if (window.innerWidth <= 912) {
   set_Is_Small_Screen(true);
  } else {
   set_Is_Small_Screen(false);
  }
 };

 const getChapterByCourse = (chp_id) => {
  set_Chapter_Loader(true);
  let payload = {
   url: API_URL.getChapterByCourse,
   data: {
    CourseID: parsedData?.courseID,
    UserID: token,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("getChapterByCourse response", response.data);
    if (response.success) {
     set_Chapter_Data(response?.data);
     set_Chapter_Loader(false);
     let res_chp_id = chp_id ? chp_id : response?.data[0]?.ChapterID;

     if (res_chp_id) {
      getTopicByChapter(res_chp_id);
     }
    } else {
     set_Chapter_Loader(false);
     toast.dismiss();
     toast.error(response.message);
    }
   })
   .catch((error) => {
    console.log("getSubjects error", error);
    set_Chapter_Loader(false);
    set_Is_Network_Error(true);
   });
 };

 const getCourseDetails = () => {
  set_Is_Loading(true);
  let payload = {
   url: API_URL.getCourseById,
   data: {
    CourseID: parsedData?.courseID,
    UserID: token,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("getCourseDetails response", response);
  
    if (response.success) {
     // let new_data = response.data
     setVideo_url(
      `${RunTimeConstant.BASEURL}assert/videoNew?id=${encodeURIComponent(
       response?.data?.AssetPath + response?.data?.Video
      )}`
     );
     set_Course_Data(response.data);
     set_Is_Loading(false);
     getChapterByCourse();
    } else {
     set_Is_Loading(false);
     toast.dismiss();
     toast.error(response.message);
    }
   })
   .catch((error) => {
    console.log("getSubjects error", error);
    set_Is_Loading(false);
    set_Is_Network_Error(true);
   });
 };

 //  console.log("videourl", video_url)

 useLayoutEffect(() => {
  // console.log("course_id", course_id)
  if (parsedData?.courseID) {
   getCourseDetails();
  } else {
   navigate("*");
  }
 }, [is_Network_Error]);

 const getClosedData = async () => {
  // let page_closed_data = await getLocalStorage("page_closed")
  // console.log("page_closed_data", page_closed_data)
 };

 //  useEffect(() => {
 //   let courseUpdated_data;
 //   set_Course_Data((prev) => {
 //    courseUpdated_data = prev;
 //    return prev;
 //   });
 //   console.error("courseUpdated_data", courseUpdated_data);
 //   if (!courseUpdated_data?.IsPurchase && parsedData?.statusCode == 200) {
 //    buyCourse();
 //   }
 //  }, [course_Data]);

 useEffect(() => {
  // handleResize();");
  dispatch(tabId({ tab_Id: -1 }));
  getClosedData();

  const handleUnload = (event) => {
   // setLocalStorage("page_closed", 95)
   // event.preventDefault();
   return (event.returnValue = "");
  };

  window.addEventListener("beforeunload", handleUnload);
  window.addEventListener("resize", handleResize);

  return () => {
   console.log("page_closed");
   // setLocalStorage("page_closed", 15)
   window.removeEventListener("beforeunload", handleUnload);
   window.removeEventListener("resize", handleResize);
  };
 }, []);

 const getTopicByChapter = (chp_id) => {
  let payload = {
   url: API_URL.getTopicByChapter,
   data: {
    ChapterID: chp_id,
    CourseID: parsedData?.courseID,
    UserID: token,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("getTopicByChapter response", response.data);
    if (response.success) {
     let exist_data = [];
     const new_data = response.data;
     new_data.forEach((ele) => {
      ele.video_file = `${RunTimeConstant.BASEURL}assert/videoNew?id=${encodeURIComponent(
       ele.AssetPath + ele.Video
      )}`;
      exist_data.push(ele);
     });
     // console.log("exist_data topic", exist_data)
     set_Topic_Data(exist_data);
     set_Topic_Loader(false);
    } else {
     set_Topic_Loader(false);
     toast.dismiss();
     toast.error(response.message);
    }
   })
   .catch((error) => {
    console.log("getSubjects error", error);
    set_Topic_Loader(false);
    set_Is_Network_Error(true);
   });
 };

 const select_Chapter = (chp_ind, chp_id) => {
  console.log("select_Chapter chp_id", chp_id);
  if (selected_Chapter_Id != chp_ind) {
   // console.log("if chapter")
   set_Selected_Chapter_Id(chp_ind);
   set_Selected_Topic_Id(null);
   set_Open_Topic(true);
   set_Topic_Loader(true);
   set_Topic_Data([]);
   getTopicByChapter(chp_id);
  } else {
   set_Open_Topic(!open_Topic);
  }
 };

 const select_Topic = (topc_id) => {
  // console.log("select_Topic", topc_id, selected_Topic_Id)
  if (selected_Topic_Id != topc_id) {
   set_Selected_Topic_Id(topc_id);
   set_Open_Topic_Assests(true);
  } else {
   set_Open_Topic_Assests(!open_Topic_Assests);
   // set_Selected_Topic_Id(selected_Topic_Id)
  }
 };

 const removeCart = (course_Id) => {
  let payload = {
   url: API_URL.updateCart,
   data: {
    _id: token,
    CourseID: course_Id,
    Type: 0,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("removeCart response", response);
    if (response.success) {
     dispatch(cartItemCount({ cart_item_count: cart_item_count - 1 }));
     //success
    } else {
     // toast.dismiss()
     // toast(response.message)
    }
   })
   .catch((error) => {
    console.log("removeCart error", error);
    set_Is_Network_Error(true);
   });
 };

 const buyCourse = () => {
  set_Is_Loading(true);
  setShowPayment(false);
  let payload = {
   url: API_URL.updatePurchase,
   data: {
    _id: token,
    CourseID: parsedData?.courseID,
    Type: 1,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("buyCourse response", response);
    if (response.success) {
     set_Course_Data((prevState) => {
      return { ...prevState, IsPurchase: true };
     });
     if (course_Data.IsPresentIncart) {
      removeCart([course_Data?._id]);
     }
     set_Is_Loading(false);
     toast.dismiss();
     toast.success(response.message);
    } else {
     toast.dismiss();
     toast.error(response.message);
     set_Is_Loading(false);
    }
   })
   .catch((error) => {
    console.log("buyCourse error", error);
    set_Is_Network_Error(true);
    set_Is_Loading(false);
   });
 };

 const add_Cart = () => {
  let payload = {
   url: API_URL.updateCart,
   data: {
    _id: token,
    CourseID: parsedData?.courseID,
    Type: 1,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("add_Cart response", response);
    if (response.success) {
     dispatch(cartItemCount({ cart_item_count: cart_item_count + 1 }));
     set_Course_Data((previousState) => {
      return { ...previousState, IsPresentIncart: true };
     });
     toast.dismiss();
     toast.success(response.message);
    } else {
     toast.dismiss();
     toast.error(response.message);
    }
   })
   .catch((error) => {
    console.log("add_Cart error", error);
    set_Is_Loading(false);
    set_Is_Network_Error(true);
   });
 };

 const goToAssessment = (assessment_id, params) => {
  if (!course_Data?.IsPurchase) {
   toast.dismiss();
   toast("Purchase the course to explore more information", {
    icon: <AiFillWarning color="orange" size={30} />,
    style: {
     borderRadius: "10px",
    },
   });
   // toast("Purchase the course to explore more information");
  } else {
   let data = {
    UserID: token,
    Assessment_ID: assessment_id,
    name: JSON.stringify(params),
    course_ID: parsedData?.courseID,
   };
   if (assessment_id) {
    if (params?.topicName) {
     const queryStringified = queryString.stringify(data);
     navigate(`/assessment?${queryStringified}`);
    } else {
     setCurrentAssessment(data);
     setShowModal(true);
    }
   }
  }
 };

 const takeAssessment = () => {
  const queryStringified = queryString.stringify(currentAssessment);
  navigate(`/assessment?${queryStringified}`);
 };

 const goToPayment = () => {
  const dataObject = {
   data: [course_Data],
   statusCode: 100,
  };
  const dataString = JSON.stringify(dataObject);
  const queryStringified = queryString.stringify({ cart: dataString });
  navigate(`/payment?${queryStringified}`);
 };

 if (is_Loading) {
  return <Loader isLoading={is_Loading} callBack={() => set_Is_Loading(false)} />;
 }

 if (is_Network_Error) {
  return <NetworkError callBack={() => set_Is_Network_Error(false)} />;
 }

 //  if (showPayment) {
 //   return (
 //    <PaypalButton
 //     totalPayment={course_Data?.Price}
 //     onSubmit={() => buyCourse()}
 //     closePayment={() => setShowPayment(false)}
 //    />
 //   );
 //  }

 //  if (showModal) {
 //   return <Modal_Component closeModel={() => setShowModal(false)} />;
 //  }

 const Course_Assets = () => {
  return (
   <div className={Styles.doc_Assests_View}>
    <div className={Styles.doc_Assests_Icon}>
     <Video_Svg color={"var(--inside-primary-color)"} width={"16"} height={"14"} />
    </div>
    <div className={Styles.doc_Assests_num}>
     {course_Data.NumberOfVideo ? course_Data?.NumberOfVideo : 0}
    </div>

    <div className={Styles.doc_Assests_Value}>Videos</div>

    {/* <div className={Styles.doc_Assests_Icon}>
     <Document_Svg color={"var(--inside-primary-color)"} width={"16"} height={"14"} />
    </div>
    <div className={Styles.doc_Assests_num}>
     {course_Data.documents_no ? course_Data?.documents_no : 0}
    </div>

    <div className={Styles.doc_Assests_Value}>Documents</div> */}

    <div className={Styles.doc_Assests_Icon}>
     <Assessment_Svg color={"var(--inside-primary-color)"} width={"16"} height={"14"} />
    </div>
    <div className={Styles.doc_Assests_num}>
     {course_Data.NumberOfAssessment ? course_Data?.NumberOfAssessment : 0}
    </div>

    <div className={Styles.doc_Assests_Value}>Assessments</div>
   </div>
  );
 };

 const updateLastVideoViewed = async (params) => {
  //   console.error("selected video data setting", params);
  let get_selected_video_data = await getLocalStorage("selected_video_data");
  if (get_selected_video_data?.selected_Video_TopicID) {
   let payload = {
    url: API_URL.update_last_video_view_point,
    data: {
     TopicID: get_selected_video_data?.selected_Video_TopicID,
     UserID: token,
     LastVideoViewed: params,
    },
   };
   PostMethod(payload)
    .then(async (response) => {
     console.log("updateLastVideoViewed response", response);
     if (response.success) {
     }
    })
    .catch((error) => {
     console.log("updateLastVideoViewed error", error);
    });
  }
 };

 const updateVideoCompletedStatus = async () => {
  let get_selected_video_data = await getLocalStorage("selected_video_data");

  if (
   get_selected_video_data?.selected_Video_TopicID &&
   !get_selected_video_data?.videoCompletion
  ) {
   let payload = {
    url: API_URL.update_video_completion,
    data: {
     TopicID: get_selected_video_data?.selected_Video_TopicID,
     UserID: token,
    },
   };
   PostMethod(payload)
    .then(async (response) => {
     console.log("updateVideoCompletedStatus response", response);
     if (response.success) {
      getChapterByCourse(get_selected_video_data.selected_Video_ChapteID);
      // getTopicByChapter(get_selected_video_data?.selected_Video_ChapteID);
     }
    })
    .catch((error) => {
     console.log("updateVideoCompletedStatus error", error);
    });
  }
 };

 const playTopicVideo = (video_file, chp_name, top_name) => {
  if (!course_Data?.IsPurchase) {
   toast.dismiss();
   toast("Purchase the course to explore more information", {
    icon: <AiFillWarning color="orange" size={30} />,
    style: {
     borderRadius: "10px",
    },
   });
   // toast("Purchase the course to explore more information");
  } else {
   set_Selected_Topic_Name({
    chapterName: chp_name?.ChapterName,
    topicName: top_name?.TopicName,
   });
   // console.log("video_file ---- ", video_file)
   setVideo_url(video_file);
   // videoRef.current.src = video_file;
   console.log("video_file");
   // videoRef.current.currentTime = 30;
   // videoRef.current.load();
   // videoRef.current.play();
  }
 };

 const Topic_Assets_2 = (data) => {
  let topc_data = data?.topic_data?.topc_data;
  let chp_data = data?.topic_data?.chp_data;
  let is_purchased = data?.topic_data?.purchased;
  return (
   <div className={Styles.topic_assets_before_buy}>
    <div
     onClick={async () => {
      let selected_video_data = {
       selected_Video_TopicID: topc_data.TopicID,
       selected_Video_ChapteID: chp_data.ChapterID,
       videoCompletion: topc_data.VideoCompletion,
       lastVideoViewedAt: topc_data.LastVideoViewed,
      };
      // console.log("topc_data.TopicID", topc_data);
      await setLocalStorage("selected_video_data", selected_video_data);
      playTopicVideo(topc_data.video_file, chp_data, topc_data);
     }}
     className={
      is_purchased
       ? Styles.topic_Assests_Body_Container_After_Buy
       : Styles.topic_Assests_Body_Container
     }
    >
     <div className={Styles.completed_Icon_View}>
      {topc_data?.VideoCompletion && <AiFillCheckCircle size={"20"} color="#21B573" />}
     </div>
     <div className={Styles.topic_Assests_Icon_View_2}>
      <Video_Svg
       width={"25"}
       height={"20"}
       color={is_purchased ? "var(--primary-color)" : "#A6A6A6"}
      />
     </div>
     <div
      className={
       is_purchased
        ? Styles.topic_Assests_Content_View_After_Buy
        : Styles.topic_Assests_Content_View
      }
     >
      {topc_data?.TopicName}
     </div>
    </div>
    {/* <div
          className={
            is_purchased
              ? Styles.topic_Assests_Body_Container_After_Buy
              : Styles.topic_Assests_Body_Container
          }
        >
          <div className={Styles.completed_Icon_View}>
            {chp_data.Completed && <AiFillCheckCircle size={"20"} color="#21B573" />}
          </div>
          <div className={Styles.topic_Assests_Icon_View_1}>
            <Document_Svg
              width={"25"}
              height={"20"}
              color={is_purchased ? "var(--primary-color)" : "#A6A6A6"}
            />
          </div>
          <div
            className={
              is_purchased
                ? Styles.topic_Assests_Content_View_After_Buy
                : Styles.topic_Assests_Content_View
            }
          >
            Document
          </div>
        </div> */}
    {topc_data?.Assessment && (
     <div
      className={
       is_purchased
        ? Styles.topic_Assests_Body_Container_After_Buy
        : Styles.topic_Assests_Body_Container
      }
      onClick={() =>
       goToAssessment(topc_data?.Assessment, {
        courseName: course_Data?.CourseName,
        chapterName: chp_data?.ChapterName,
        topicName: topc_data?.TopicName,
       })
      }
     >
      <div className={Styles.completed_Icon_View}>
       {chp_data.Completed && <AiFillCheckCircle size={"20"} color="#21B573" />}
      </div>
      <div className={Styles.topic_Assests_Icon_View_2}>
       <Assessment_Svg
        width={"25"}
        height={"20"}
        color={is_purchased ? "var(--primary-color)" : "#A6A6A6"}
       />
      </div>
      <div
       className={
        data?.topic_data?.purchased
         ? Styles.topic_Assests_Content_View_After_Buy
         : Styles.topic_Assests_Content_View
       }
      >
       Assessment
      </div>
     </div>
    )}
   </div>
  );
 };
 const Topic_Assets = (data) => {
  let topc_data = data?.topic_data?.topc_data;
  let chp_data = data?.topic_data?.chp_data;
  let is_purchased = data?.topic_data?.purchased;
  return (
   <div className={Styles.topic_assets_before_buy}>
    <div
     onClick={async () => {
      let selected_video_data = {
       selected_Video_TopicID: topc_data.TopicID,
       selected_Video_ChapteID: chp_data.ChapterID,
       videoCompletion: topc_data.VideoCompletion,
       lastVideoViewedAt: topc_data.LastVideoViewed,
      };
      // console.log("topc_data.TopicID", data?.topic_data);
      await setLocalStorage("selected_video_data", selected_video_data);
      playTopicVideo(topc_data.video_file, chp_data, topc_data);
     }}
     className={
      is_purchased
       ? Styles.topic_Assests_Body_Container_After_Buy
       : Styles.topic_Assests_Body_Container
     }
    >
     <div className={Styles.completed_Icon_View}>
      {topc_data?.VideoCompletion && <AiFillCheckCircle size={"20"} color="#21B573" />}
     </div>
     <div className={Styles.topic_Assests_Icon_View_1}>
      <Video_Svg
       width={"25"}
       height={"20"}
       color={is_purchased ? "var(--primary-color)" : "#A6A6A6"}
      />
     </div>
     <div
      className={
       is_purchased
        ? Styles.topic_Assests_Content_View_After_Buy
        : Styles.topic_Assests_Content_View
      }
     >
      {topc_data?.TopicName}
     </div>
    </div>
    {/* <div
          className={
            is_purchased
              ? Styles.topic_Assests_Body_Container_After_Buy
              : Styles.topic_Assests_Body_Container
          }
        >
          <div className={Styles.completed_Icon_View}>
            {chp_data.Completed && <AiFillCheckCircle size={"20"} color="#21B573" />}
          </div>
          <div className={Styles.topic_Assests_Icon_View_1}>
            <Document_Svg
              width={"25"}
              height={"20"}
              color={is_purchased ? "var(--primary-color)" : "#A6A6A6"}
            />
          </div>
          <div
            className={
              is_purchased
                ? Styles.topic_Assests_Content_View_After_Buy
                : Styles.topic_Assests_Content_View
            }
          >
            Document
          </div>
        </div> */}
    {topc_data?.Assessment && (
     <div
      className={
       is_purchased
        ? Styles.topic_Assests_Body_Container_After_Buy
        : Styles.topic_Assests_Body_Container
      }
      onClick={() =>
       goToAssessment(topc_data?.Assessment, {
        courseName: course_Data?.CourseName,
        chapterName: chp_data?.ChapterName,
        topicName: topc_data?.TopicName,
       })
      }
     >
      <div className={Styles.completed_Icon_View}>
       {chp_data.Completed && <AiFillCheckCircle size={"20"} color="#21B573" />}
      </div>
      <div className={Styles.topic_Assests_Icon_View_1}>
       <Assessment_Svg
        width={"25"}
        height={"20"}
        color={is_purchased ? "var(--primary-color)" : "#A6A6A6"}
       />
      </div>
      <div
       className={
        data?.topic_data?.purchased
         ? Styles.topic_Assests_Content_View_After_Buy
         : Styles.topic_Assests_Content_View
       }
      >
       Assessment
      </div>
     </div>
    )}
   </div>
  );
 };

 const Key_Points = () => {
  return (
   <div className={Styles.hint_View}>
    <div className={Styles.hint_Title}>What you’ll learn</div>

    <div className={Styles.hint_Line_1}>{course_Data?.WhatYouWillLearnDescription}</div>

    {course_Data?.WhatYouWillLearn?.map((ele, ind) => {
     return (
      <div key={ind}>
       <ul key={ind}>
        <li className={Styles.hint_Points}>{ele}</li>
       </ul>
      </div>
     );
    })}

    {/* <div className={Styles.hint_Line_1}>
                    Students will learn how to find the derivatives of functions including 
                </div> */}
   </div>
  );
 };

 return (
  <>
   {showModal && (
    <Exam_Instruction
     closeModel={() => {
      setShowModal(false);
      setCurrentAssessment("");
     }}
     onSubmit={() => takeAssessment()}
    />
   )}
   <>
    <div className={Styles.course_Content_Container}>
     <div className={Styles.course_Title_View}>
      <div className={Styles.course_Title}>{course_Data?.CourseName}</div>

      {selected_Topi_Name != null && (
       <>
        <div className={Styles.breadCrumb_Text}>
         <span className={Styles.breadCrumb_Divider}>{""}</span>{" "}
         {selected_Topi_Name.chapterName}
        </div>

        <div className={Styles.breadCrumb_Text}>
         <span className={Styles.breadCrumb_Divider}>{""}</span>
         {selected_Topi_Name.topicName}
        </div>
       </>
      )}
     </div>

     <div className={Styles.course_Content_body}>
      <div className={Styles.course_Content_Section_1}>
       <div className={Styles.video_View}>
        <VideoPlayer
         url={video_url}
         updateVideoCompletedStatus={updateVideoCompletedStatus}
         updateLastVideoViewed={updateLastVideoViewed}
        />
       </div>

       <div className={Styles.description_View}>
        <div className={Styles.description_Title_Text}>{"Description"}</div>

        <div className={Styles.description_Text}>{course_Data?.Description}</div>
       </div>

       {!course_Data?.IsPurchase && <Key_Points />}

       {!is_Small_Screen && course_Data?.IsPurchase ? (
        <></>
       ) : (
        is_Small_Screen | (!is_Small_Screen && !course_Data?.IsPurchase) && (
         // This dropdown will appear for small screen(Before and After Purchase) and large screen(Before purchase)

         <div style={{ width: "100%", marginBottom: "30px" }}>
          <div
           style={{
            backgroundColor: "#7445FF",
            color: "#fff",
            padding: "10px",
           }}
          >
           <div className={Styles.CourseHeader}>
            <div className={Styles.course_Text}>{course_Data?.CourseName}</div>
            <div
             style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
             }}
            >
             <Course_Assets />
            </div>
           </div>
          </div>
          {chapter_Data.map((chp_data, chp_ind) => (
           <>
            <div
             onClick={() => select_Chapter(chp_ind, chp_data.ChapterID)}
             className={Styles.chapter_List_Body_After_Buy}
             style={{
              backgroundColor: "#f5f2ff",
              // padding: "15px",
              border: "1px solid #D9D9D9",
              flexDirection: "row",
              width: "100%",
             }}
            >
             <div className={Styles.completed_Icon_View}>
              {chp_data?.ChapterAssessmentCompletion && (
               <AiFillCheckCircle size={"20"} color="#21B573" />
              )}
             </div>
             <div
              className={Styles.chapter_Name_Text_before_buy}
              style={{
               color:
                selected_Chapter_Id == chp_ind ? "var(--primary-color)" : "var(--text-color)",
              }}
             >
              {(chp_ind + 1).toString().padStart(2, "0") + "."} {chp_data?.ChapterName}{" "}
             </div>
            </div>
            <div>
             {selected_Chapter_Id == chp_ind && (
              <>
               <div
                className={
                 open_Topic ? Styles.topic_ListContainer : Styles.topic_ListContainer_Close
                }
                style={{
                 display: "flex",
                 //  justifyContent: "flex-end",
                 flexDirection: "column",
                 //  backgroundColor: "red",
                }}
               >
                {topic_Data.length ? (
                 <>
                  {topic_Data.map((topc_data, topc_ind) => {
                   return (
                    <>
                     <div key={topc_ind} className={Styles.topic_List_Body_Container}>
                      <div
                       key={topc_ind}
                       className={Styles.topic_List_Body}
                       onClick={() => select_Topic(topc_ind)}
                      >
                       <div className={Styles.topic_List_Content_View_before_buy}>
                        <div className={Styles.completed_Icon_View}>
                         {topc_data?.VideoCompletion && (
                          <AiFillCheckCircle size={"20"} color="#21B573" />
                         )}
                        </div>
                        <div className={Styles.topic_s_no_Text_View}>
                         <div
                          className={Styles.top_Sno_Text}
                          style={{
                           color:
                            selected_Topic_Id == topc_ind
                             ? "var(--primary-color)"
                             : "var(--text-color)",
                          }}
                         ></div>
                        </div>
                        <div className={Styles.topic_Name_Text_View}>
                         <div
                          className={Styles.topic_Name_Text}
                          style={{
                           color:
                            selected_Topic_Id == topc_ind
                             ? "var(--primary-color)"
                             : "var(--text-color)",
                          }}
                         >
                          <div>
                           {chp_ind + 1 + "." + (topc_ind + 1)} {topc_data.TopicName}
                          </div>
                         </div>
                        </div>
                       </div>

                       <div className={Styles.topic_List_Icon_View}>
                        {selected_Topic_Id == topc_ind ? (
                         <>
                          {open_Topic_Assests ? (
                           <BsChevronCompactUp size={20} />
                          ) : (
                           <BsChevronCompactDown size={20} />
                          )}
                         </>
                        ) : (
                         <BsChevronCompactDown size={20} />
                        )}
                       </div>
                      </div>
                     </div>
                     {selected_Topic_Id == topc_ind && (
                      <div
                       className={
                        open_Topic_Assests
                         ? Styles.topic_Assests_Container
                         : Styles.topic_Assests_Container_Close
                       }
                      >
                       {open_Topic_Assests ? (
                        <Topic_Assets_2
                         topic_data={{
                          topc_data,
                          purchased: course_Data?.IsPurchase,
                          chp_data,
                         }}
                        />
                       ) : (
                        <div
                         style={{
                          backgroundColor: "#FBFBFB",
                          height: "150px",
                         }}
                        ></div>
                       )}
                      </div>
                     )}
                    </>
                   );
                  })}
                 </>
                ) : (
                 <div className={Styles.loader_Container}>
                  {topic_Loader ? <Loader /> : <div>No Topics found</div>}
                 </div>
                )}
                {chp_data?.Assessment && (
                 <div
                  onClick={() => {
                   if (!course_Data?.IsPurchase) {
                    toast.dismiss();
                    toast("Purchase the course to explore more information", {
                     icon: <AiFillWarning color="orange" size={30} />,
                     style: {
                      borderRadius: "10px",
                     },
                    });
                   } else if (chp_data?.ChapterCompletion) {
                    goToAssessment(chp_data?.Assessment, {
                     courseName: course_Data?.CourseName,
                     chapterName: chp_data?.ChapterName,
                    });
                   }
                  }}
                  className={Styles.topic_Assests_Body_Container_assestment}
                 >
                  <div className={Styles.completed_Icon_View}>
                   {chp_data?.ChapterAssessmentCompletion && (
                    <AiFillCheckCircle size={"20"} color="#21B573" />
                   )}
                  </div>
                  <div
                   style={{
                    width: "90%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                   }}
                  >
                   <div className={Styles.topic_Assests_Icon_View}>
                    <Assessment_Svg
                     width={"25"}
                     height={"20"}
                     color={chp_data?.ChapterCompletion ? "var(--primary-color)" : "#A6A6A6"}
                    />
                   </div>
                   <div
                    className={Styles.topic_Assests_Content_View}
                    style={{
                     color: chp_data?.ChapterCompletion ? "var(--primary-color)" : "#A6A6A6",
                    }}
                   >
                    {chp_data?.ChapterName} Assessment
                   </div>
                  </div>
                 </div>
                )}
               </div>
              </>
             )}
            </div>
           </>
          ))}
          {course_Data?.Assessment && (
           <div
            onClick={() => {
             if (!course_Data?.IsPurchase) {
              toast.dismiss();
              toast("Purchase the course to explore more information", {
               icon: <AiFillWarning color="orange" size={30} />,
               style: {
                borderRadius: "10px",
               },
              });
             } else if (course_Data?.CourseCompletionStatus) {
              goToAssessment(course_Data?.Assessment, {
               courseName: course_Data?.CourseName,
              });
             }
            }}
            className={Styles.topic_Assests_Body_Container}
           >
            <div className={Styles.completed_Icon_View}>
             {course_Data?.AssessmentCompletionStatus && (
              <AiFillCheckCircle size={"20"} color="#21B573" />
             )}
            </div>
            <div className={Styles.topic_Assests_Icon_View}>
             <Assessment_Svg
              width={"25"}
              height={"20"}
              color={course_Data?.CourseCompletionStatus ? "var(--primary-color)" : "#A6A6A6"}
             />
            </div>
            <div
             className={Styles.topic_Assests_Content_View}
             style={{
              color: course_Data?.CourseCompletionStatus ? "var(--primary-color)" : "#A6A6A6",
             }}
            >
             {course_Data?.CourseName} Assessment
            </div>
           </div>
          )}
         </div>
        )
       )}
      </div>

      {!is_Small_Screen && (
       <div className={Styles.course_Content_Section_2}>
        {!course_Data?.IsPurchase ? (
         <div className={Styles.course_Content_Section_2_Body}>
          <div className={Styles.course_Price_Text}>{"AED" + " " + course_Data?.Price}</div>

          <div
           onClick={() => (course_Data.IsPresentIncart ? navigate("/cart") : add_Cart())}
           className={Styles.add_Cart_Button_View}
          >
           {course_Data.IsPresentIncart ? "Go to Cart" : "Add Cart"}
          </div>

          <div className={Styles.buy_Now_Button_View} onClick={() => goToPayment()}>
           Buy Now
          </div>

          <div className={Styles.docs_Container}>
           <div className={Styles.docs_Title_View}>This course includes:</div>

           <div>
            <div className={Styles.docs_View}>
             <div className={Styles.docs_Icon_View}>
              <Video_Svg color={"var(--primary-color)"} width={"30"} height={"27"} />
             </div>
             <div className={Styles.docs_Count_View}>
              {course_Data?.NumberOfVideo + " " + "Videos"}
             </div>
             {/* <div className={Styles.docs_Assests_Name}>
                                        Video
                                    </div> */}
            </div>

            {/* <div className={Styles.docs_View}>
            <div className={Styles.docs_Icon_View}>
             <Document_Svg color={"var(--primary-color)"} width={"30"} height={"27"} />
            </div>
            <div className={Styles.docs_Count_View}>{0 + " " + "Documents"}</div>
           </div> */}

            <div className={Styles.docs_View}>
             <div className={Styles.docs_Icon_View}>
              <Assessment_Svg color={"var(--primary-color)"} width={"30"} height={"27"} />
             </div>
             <div className={Styles.docs_Count_View}>
              {course_Data?.NumberOfAssessment + " " + "Assessments"}
             </div>
             {/* <div className={Styles.docs_Assests_Name}>
                                            Assessments
                                        </div> */}
            </div>
           </div>
          </div>
         </div>
        ) : (
         //This dropdown will appear only for large screen after purchase

         <div style={{ width: "100%", border: "1px solid #d9d9d9", marginBottom: "30px" }}>
          <div
           style={{
            backgroundColor: "#7445FF",
            color: "#fff",
            padding: "10px",
            gap: "10px",
           }}
          >
           <div
            style={{
             display: "flex",
             justifyContent: "center",
             alignItems: "flex-start",
             //  backgroundColor: "red",
             flexDirection: "column",
            }}
           >
            <div className={Styles.course_Text}>{course_Data?.CourseName}</div>
            <div
             style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "95%",
             }}
            >
             <Course_Assets />
            </div>
           </div>
          </div>
          {chapter_Data.map((chp_data, chp_ind) => (
           <>
            <div
             onClick={() => select_Chapter(chp_ind, chp_data.ChapterID)}
             className={Styles.chapter_List_Body_After_Buy}
             style={{
              backgroundColor: "#f5f2ff",
              // padding: "15px",
              border: "1px solid #D9D9D9",
              display: "flex",
              flexDirection: "row",
              // justifyContent: "center",
              alignItems: "center",
             }}
            >
             <div className={Styles.completed_Icon_View}>
              {chp_data?.ChapterAssessmentCompletion && (
               <AiFillCheckCircle size={"20"} color="#21B573" />
              )}
             </div>
             <div
              className={Styles.chapter_Name_Text}
              style={{
               color:
                selected_Chapter_Id == chp_ind ? "var(--primary-color)" : "var(--text-color)",
              }}
             >
              {(chp_ind + 1).toString().padStart(2, "0") + "."} {chp_data?.ChapterName}
             </div>
            </div>
            <div>
             {selected_Chapter_Id == chp_ind && (
              <>
               <div
                className={
                 open_Topic ? Styles.topic_ListContainer : Styles.topic_ListContainer_Close
                }
               >
                {topic_Data.length ? (
                 <>
                  {topic_Data.map((topc_data, topc_ind) => {
                   return (
                    <>
                     <div key={topc_ind} className={Styles.topic_List_Body_Container}>
                      <div
                       key={topc_ind}
                       className={Styles.topic_List_Body}
                       onClick={() => select_Topic(topc_ind)}
                      >
                       {/* <div style={{ backgroundColor: "red" }}>
                       {topc_data?.VideoCompletion && (
                        <AiFillCheckCircle size={"20"} color="#21B573" />
                       )}
                      </div> */}
                       <div className={Styles.topic_List_Content_View}>
                        <div className={Styles.completed_Icon_View}>
                         {topc_data?.VideoCompletion && (
                          <AiFillCheckCircle size={"20"} color="#21B573" />
                         )}
                        </div>

                        {/* <div className={Styles.topic_s_no_Text_View}>
                         <div
                          className={Styles.top_Sno_Text}
                          style={{
                           color:
                            selected_Topic_Id == topc_ind
                             ? "var(--primary-color)"
                             : "var(--text-color)",
                          }}
                         ></div>
                        </div> */}

                        <div className={Styles.topic_Name_Text_View_after_buy}>
                         <div
                          className={Styles.topic_Name_Text}
                          style={{
                           color:
                            selected_Topic_Id == topc_ind
                             ? "var(--primary-color)"
                             : "var(--text-color)",
                          }}
                         >
                          {chp_ind + 1 + "." + (topc_ind + 1)} {topc_data.TopicName}
                         </div>
                        </div>
                       </div>

                       <div className={Styles.topic_List_Icon_View}>
                        {selected_Topic_Id == topc_ind ? (
                         <>
                          {open_Topic_Assests ? (
                           <BsChevronCompactUp size={20} />
                          ) : (
                           <BsChevronCompactDown size={20} />
                          )}
                         </>
                        ) : (
                         <BsChevronCompactDown size={20} />
                        )}
                       </div>
                      </div>
                     </div>
                     {selected_Topic_Id == topc_ind && (
                      <div
                       className={
                        open_Topic_Assests
                         ? Styles.topic_Assests_Container
                         : Styles.topic_Assests_Container_Close
                       }
                      >
                       {open_Topic_Assests ? (
                        <Topic_Assets
                         topic_data={{
                          topc_data,
                          purchased: course_Data?.IsPurchase,
                          chp_data,
                         }}
                        />
                       ) : (
                        <div
                         style={{
                          backgroundColor: "#FBFBFB",
                          height: "150px",
                         }}
                        ></div>
                       )}
                      </div>
                     )}
                    </>
                   );
                  })}
                  {chp_data?.Assessment && (
                   <div
                    className={Styles.chapter_Assessment_View}
                    onClick={() => {
                     if (chp_data?.ChapterCompletion) {
                      goToAssessment(chp_data?.Assessment, {
                       courseName: course_Data?.CourseName,
                       chapterName: chp_data?.ChapterName,
                      });
                     }
                    }}
                   >
                    {/* <div className={Styles.chpaterAssessment_Alignment}> */}
                    <div className={Styles.completed_Icon_View}>
                     {chp_data?.ChapterAssessmentCompletion && (
                      <AiFillCheckCircle size={"20"} color="#21B573" />
                     )}
                    </div>
                    <div className={Styles.topic_Assests_Icon_View}></div>
                    <div className={Styles.topic_Assests_Icon_View}>
                     <Assessment_Svg
                      width={"25"}
                      height={"20"}
                      color={chp_data?.ChapterCompletion ? "var(--primary-color)" : "#A6A6A6"}
                     />
                    </div>
                    <div
                     className={Styles.topic_Assests_Content_View}
                     style={{
                      color: chp_data?.ChapterCompletion ? "var(--primary-color)" : "#A6A6A6",
                     }}
                    >
                     {chp_data?.ChapterName} Assessment
                    </div>
                    {/* </div> */}
                   </div>
                  )}
                 </>
                ) : (
                 <div className={Styles.loader_Container}>
                  {topic_Loader ? <Loader /> : <div>No Topics found</div>}
                 </div>
                )}
               </div>
              </>
             )}
            </div>
           </>
          ))}
          {course_Data?.Assessment && (
           <div
            // style={{ paddingLeft: "10px" }}
            onClick={() => {
             if (course_Data?.CourseCompletionStatus) {
              goToAssessment(course_Data?.Assessment, {
               courseName: course_Data?.CourseName,
              });
             }
            }}
            className={Styles.topic_Assests_Body_Container}
           >
            <div className={Styles.completed_Icon_View}>
             {course_Data?.AssessmentCompletionStatus && (
              <AiFillCheckCircle size={"20"} color="#21B573" />
             )}
            </div>
            <div className={Styles.topic_Assests_Icon_View}>
             <Assessment_Svg
              width={"25"}
              height={"20"}
              color={course_Data?.CourseCompletionStatus ? "var(--primary-color)" : "#A6A6A6"}
             />
            </div>
            <div
             className={Styles.topic_Assests_Content_View}
             style={{
              color: course_Data?.CourseCompletionStatus ? "var(--primary-color)" : "#A6A6A6",
             }}
            >
             {course_Data?.CourseName} Assessment
            </div>
           </div>
          )}
         </div>
        )}
       </div>
      )}
     </div>
    </div>

    {is_Small_Screen && !course_Data?.IsPurchase && (
     <div className={Styles.bottom_Nav_Container}>
      <div
       className={Styles.bottom_Nav_AddCart_Button_View}
       onClick={() => (course_Data.IsPresentIncart ? navigate("/cart") : add_Cart())}
      >
       <AiOutlineShoppingCart size={25} color="var(--primary-color)" />
       <span style={{ fontSize: "14px" }}>
        {course_Data.IsPresentIncart ? "Go to Cart" : "Add Cart"}
       </span>
      </div>

      <div onClick={() => goToPayment()} className={Styles.bottom_Nav_BuyNow_Button_View}>
       <span>{"AED 348"}</span>
       <span style={{ fontSize: "14px" }}>Buy Now</span>
      </div>
     </div>
    )}
   </>
  </>
 );
};

export default Course_Content;
