import React from "react";

function Youtube_Svg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="15"
      fill="none"
      viewBox="0 0 22 15"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M20.9 1.379c.468.6.771 1.312.88 2.065.137 1.12.21 2.245.22 3.373v1.576a29.928 29.928 0 01-.22 3.385 4.374 4.374 0 01-.88 2.066 3.238 3.238 0 01-2.2.88c-3.08.22-7.7.22-7.7.22s-5.72 0-7.456-.208a3.85 3.85 0 01-2.444-.893 4.375 4.375 0 01-.88-2.077A29.913 29.913 0 010 8.393V6.817c.01-1.132.083-2.262.22-3.385a4.375 4.375 0 01.88-2.066 3.24 3.24 0 012.2-.88C6.38.28 11 .28 11 .28s4.62 0 7.7.22c.818.005 1.604.32 2.2.88zm-5.011 6.233L8.556 3.945v7.334l7.333-3.667z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Youtube_Svg;
