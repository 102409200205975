import No_Internet from "../../assets/svg/Wifi_Svg";
import Styles from "../css/Network_Error.module.css";

const NetworkError = (props) => {
 return (
  <div className={Styles.networkError_Container}>
   <No_Internet />
   <div className={Styles.title_View}>Oops!</div>

   <div className={Styles.content_View}>
    No Internet connection found check your connection and try again
   </div>

   <div onClick={() => props.callBack()} className={Styles.button_View}>
    Try Again
   </div>
  </div>
 );
};
export default NetworkError;
