import { Formik } from "formik";
import Profile_Svg from "../../assets/svg/Profile_Svg";
import Styles from "../css/Edit.Profile.module.css";
import { CONFIG } from "../../config/Constant";
import * as Yup from "yup";
import Edit_Icon from "../../assets/svg/Edit_Icon";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { tabId } from "../../redux/action";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { API_URL } from "../../api/URL/Api_Route";
import { PostMethod } from "../../api/request/Api_Calls";
import Loader from "../../component/javascript/loader";
import NetworkError from "../../component/javascript/Network_Error";
import CropImage from "../../component/javascript/cropImageView";

const Edit_Profile = () => {
 const NameRexExp = /^[A-Za-z' -]+$/;
 const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

 const ValidationSchema = Yup.object().shape({
  user_name: Yup.string()
   .trim()
   .required("Name cannot be empty")
   .matches(NameRexExp, "Name should not contain space, numbers and special characters"),
  date_of_birth: Yup.string().trim().required("Date of birth cannot be empty"),
  mobile_number: Yup.string()
   .trim()
   .required("Mobile number cannot be empty")
   .matches(phoneRegExp, "Mobile number is not valid"),

  region: Yup.string()
   .trim()
   .required("Region cannot be empty")
   .matches(NameRexExp, "Region should not contain space, numbers and special characters"),
 });

 const dispatch = useDispatch();
 const location = useLocation();
 const navigate = useNavigate();
 const queryParams = queryString.parse(location.search);
 const [profileData, setProfileData] = useState({
  userName: queryParams?.UserName != "" ? queryParams?.UserName : "",
  profile_Image: queryParams?.profileImage != "" ? queryParams?.profileImage : "",
  // emailId: queryParams?.EmailId != '' ? queryParams?.EmailId : '' ,
  mobileNumber: queryParams?.MobileNumber != "" ? queryParams?.MobileNumber : "",
  dateOfBirth: queryParams?.DateOfBirth != "" ? queryParams?.DateOfBirth : "",
  region: queryParams?.Region != "" ? queryParams?.Region : "",
 });
 const [is_Loading, set_Is_Loading] = useState(false);
 const [is_Network_Error, set_Is_Network_Error] = useState(false);
 const [showCropImage, setShowCropImage] = useState(false);
 // const [dob_type, setDob_type] = useState('text')

 // console.log("profileData", profileData)

 useEffect(() => {
  dispatch(tabId({ tab_Id: -1 }));
 }, []);

 // const [profile_Image, set_Profile_Image] = useState(default_Image)
 const [profile_Image_Path, set_Profile_Image_Path] = useState(
  queryParams?.profileImage != "" ? queryParams?.profileImage : ""
 );

 const onSubmit = () => {
  set_Is_Loading(true);
  const formData = new FormData();
  formData.append("_id", queryParams?._id);
  formData.append("UserName", profileData?.userName);
  formData.append("MobileNumber", profileData?.mobileNumber);
  formData.append("DateOfBirth", profileData?.dateOfBirth);
  formData.append("Region", profileData?.region);
  profile_Image_Path && formData.append("Images", profile_Image_Path);
  // thumbnail_file_path && formData.append("Image", thumbnail_file_path)
  // console.log("Edit profile values", profile_Image_Path)
  console.log("formData", queryParams?._id);
  let payload = {
   url: API_URL.editProfile,
   data: formData,
   header: {
    "Content-Type": "multipart/form-data",
   },
  };
  PostMethod(payload)
   .then((response) => {
    set_Is_Loading(false);
    console.log("Edit profile response", response);
    if (response.success) {
     navigate("/profile");
     toast.dismiss();
     toast.success(response.message);
    } else {
     toast.dismiss();
     toast.error(response.message);
    }
   })
   .catch((error) => {
    set_Is_Loading(false);
    set_Is_Network_Error(true);
    console.log("Edit profile error", error);
   });
 };

 const handleFileInputChange = (event) => {
  // setShowCropImage(true);
  // console.log("handleFileChange", event.target.files[0])
  let files = URL.createObjectURL(event.target.files[0]);
  // set_Profile_Image(files)
  const fileSizeInBytes = event.target.files[0].size;
  let fileSizeInKB = fileSizeInBytes / 1024;
  if (files && Math.round(fileSizeInKB) <= 10240) {
   // set_Profile_Image(files)
   setProfileData((prev) => {
    return { ...prev, profile_Image: files };
   });
   set_Profile_Image_Path(event.target.files[0]);
  } else {
   toast.dismiss();
   toast.error("Image size should not be more than 10MB");
   event.target.value = null;
  }
 };

 const handleCropComplete = (croppedImg) => {
  setProfileData((prev) => {
   return { ...prev, profile_Image: croppedImg };
  });
  set_Profile_Image_Path(croppedImg);
  setShowCropImage(false);
 };

 if (is_Loading) {
  return <Loader isLoading={is_Loading} callBack={() => set_Is_Loading(false)} />;
 }

 if (is_Network_Error) {
  return <NetworkError callBack={() => set_Is_Network_Error(false)} />;
 }

 return (
  <div className={Styles.edit_Profile_Cotainer}>
   <div className={Styles.edit_Profile_Body}>
    {/* <div className={Styles.edit_Profile_Section_1}>
                    <Profile_Svg />
                </div> */}

    <div className={Styles.edit_Profile_Section_2}>
     <div className={Styles.edit_Profile_Title}>Edit Profile</div>

     {showCropImage ? (
      <CropImage imageSrc={profileData?.profile_Image} onCropComplete={handleCropComplete} />
     ) : (
      <div>
       <div className={Styles.edit_Icon_View}>
        <div className={Styles.edit_Icon_Style}>
         <input
          type="file"
          accept="image/*"
          title=""
          onChange={handleFileInputChange}
          className={Styles.upload_image_input}
         />
         <Edit_Icon />
        </div>
       </div>
       <div className={Styles.edit_Profile_Image_View}>
        <img
         src={profileData?.profile_Image}
         crossOrigin="anonymous"
         className={Styles.edit_Profile_Image_fit}
        />
       </div>

       <div>
        <Formik
         initialValues={{
          user_name: profileData?.userName,
          // school_name: "",
          date_of_birth: profileData?.dateOfBirth,
          // grade: "",
          mobile_number: profileData?.mobileNumber,
          region: profileData?.region,
         }}
         onSubmit={onSubmit}
         validationSchema={ValidationSchema}
        >
         {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
         }) => (
          <>
           <div className={Styles.editForm_View}>
            <div className={Styles.field_View}>
             <label className={Styles.label_Text}>Name*</label>

             <input
              type="text"
              name="user_name"
              placeholder="Type your name*"
              className={Styles.textInput_Style}
              onChange={(val) => {
               // console.log("user_name", val.target.value)
               setFieldValue("user_name", val.target.value);
               setProfileData((prevState) => {
                return { ...prevState, userName: val.target.value };
               });
              }}
              maxLength={CONFIG.USER_NAME_MAX_LENGTH}
              value={profileData?.userName}
             />

             <div className={Styles.formikErrorText}>
              {errors.user_name && touched.user_name && errors.user_name}
             </div>
            </div>

            {/* <div className={Styles.field_View}>
                                            <label className={Styles.label_Text}>
                                                School Name
                                            </label>

                                            <input
                                                type="text"
                                                name="school_name"
                                                placeholder="Type your school name*"
                                                className={Styles.textInput_Style}  
                                                onChange={(val)=>{
                                                    setFieldValue("school_name", val.target.value)
                                                }}
                                                maxLength={CONFIG.USER_NAME_MAX_LENGTH}  
                                            />

                                            <div className={Styles.formikErrorText}>
                                                {errors.school_name && touched.school_name && errors.school_name}
                                            </div>
                                        </div> */}

            <div className={Styles.field_View}>
             <label className={Styles.label_Text}>DOB*</label>

             <input
              type="date"
              name="date_of_birth"
              // onfocus={()=>setDob_type('date')}
              // onblur={()=> setDob_type('text')}
              placeholder="DD/MM/YYYY*"
              className={Styles.textInput_Style}
              onChange={(val) => {
               setFieldValue("date_of_birth", val.target.value);
               setProfileData((prevState) => {
                return { ...prevState, dateOfBirth: val.target.value };
               });
              }}
              maxLength={CONFIG.USER_NAME_MAX_LENGTH}
              value={profileData?.dateOfBirth}
             />

             <div className={Styles.formikErrorText}>
              {errors.date_of_birth && touched.date_of_birth && errors.date_of_birth}
             </div>
            </div>

            {/* <div className={Styles.field_View}>
                                            <label className={Styles.label_Text}>
                                                Grade*
                                            </label>

                                            <input
                                                type="text"
                                                name="grade"
                                                placeholder="Type your grade*"
                                                className={Styles.textInput_Style}  
                                                onChange={(val)=>{
                                                    setFieldValue("grade", val.target.value)
                                                }}
                                                maxLength={CONFIG.USER_NAME_MAX_LENGTH}  
                                            />

                                            <div className={Styles.formikErrorText}>
                                                {errors.grade && touched.grade && errors.grade}
                                            </div>
                                        </div> */}

            <div className={Styles.field_View}>
             <label className={Styles.label_Text}>Mobile Number*</label>

             <input
              type="text"
              name="mobile_number"
              placeholder="Type your mobile number*"
              className={Styles.textInput_Style}
              onChange={(val) => {
               let value = val.target.value.slice(0, 15);
               setFieldValue("mobile_number", value);
               setProfileData((prevState) => {
                return { ...prevState, mobileNumber: value };
               });
              }}
              value={profileData?.mobileNumber}
             />

             <div className={Styles.formikErrorText}>
              {errors.mobile_number && touched.mobile_number && errors.mobile_number}
             </div>
            </div>

            <div className={Styles.field_View}>
             <label className={Styles.label_Text}>Region*</label>

             <input
              type="text"
              name="region"
              placeholder="Type your region*"
              className={Styles.textInput_Style}
              onChange={(val) => {
               setFieldValue("region", val.target.value);
               setProfileData((prevState) => {
                return { ...prevState, region: val.target.value };
               });
              }}
              maxLength={CONFIG.USER_NAME_MAX_LENGTH}
              value={profileData?.region}
             />

             <div className={Styles.formikErrorText}>
              {errors.region && touched.region && errors.region}
             </div>
            </div>
           </div>

           <div className={Styles.button_View} onClick={() => handleSubmit()}>
            <div className={Styles.button_Style}>Save</div>
           </div>
          </>
         )}
        </Formik>
       </div>
      </div>
     )}
    </div>
   </div>
  </div>
 );
};

export default Edit_Profile;
