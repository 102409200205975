import { useEffect, useState } from "react";
import { LogIn ,sessionDetails} from "../../redux/action";
import { useNavigate , useLocation } from "react-router-dom";
import { API_URL } from "../../api/URL/Api_Route";
import {newLoginGetMethod, newLoginPostMethod,getSessionMethod} from "../../api/request/Api_Calls";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import Cookies from 'js-cookie';
import queryString from "query-string";

const useLoginController=()=>{
    const [is_small_screen, setIsSmallScreen] = useState(false);
    const [is_landscape, setIsLandscape] = useState(false);
    const [is_network_error, set_is_network_error] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [height, setHeight] = useState(720);
    const [width, setWidth] = useState(1200);
    const [passwordIconType, setPasswordIconType] = useState("password");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    
    useEffect(() => {
        const handleResize = () => {
         setHeight(window.innerHeight);
         setWidth(window.innerWidth);
         if (window.innerWidth < 992) {
          setIsSmallScreen(true);
         } else {
          setIsSmallScreen(false);
         }
         //   console.log("window.innerWidth", window.innerWidth)
      
         // Check for landscape orientation with a max-width of 767 pixels
         const isLandscapeOrientation = window.matchMedia(
          "(max-height: 575.98px) and (orientation: landscape)"
         ).matches;
         setIsLandscape(isLandscapeOrientation);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        
        return () => {
         window.removeEventListener("resize", handleResize);
        };
       }, []);

   

       const passwordEyeIconChange = () => {
        setPasswordIconType((prevType) => (prevType === "password" ? "text" : "password"));
      };

       const onSubmit = (form_data) => {
        console.log("Login values", form_data);
        setIsLoading(true);
        let payload = {
         url: API_URL.login,
         data: {
          EmailId: form_data?.user_email,
          Password: form_data?.password,
          FingerPrint:"zwedaszsdzw"
         },
        };
        newLoginPostMethod(payload)
         .then(async (res) => {
          // setIsLoading(false);
          if (res?.status==200) {
            console.log("authentication reply 200 entered")
            let payload = {
              url: API_URL.authorize,
            };
            newLoginGetMethod(payload)
            .then(async (res) => {
              // setIsLoading(false);
              console.log("entered authorize")
              console.log("login res", res);
              if(res?.status == 200){
                let data = {
                  SessionID: Cookies.get('CUDUSID') ,
                };
                const queryStringified = queryString.stringify(data);

                const querydata = (data.SessionID)


                console.log("queryStringified =====> ",querydata)
                
             

                let payload ={
                  url:`${API_URL.getSession}?SessionID=${querydata}`,   //passing query parameter to a service 

                };
                getSessionMethod(payload)
                .then(async (res) => {
                  console.log("entered getsession")

                  
                  if(res?.status == 200){
                    setIsLoading(false)
                    dispatch(LogIn({ token: res.data.sub }));
                    dispatch(sessionDetails({session_details:res.data}))
                    
                    console.log('Total  data from the get session details',res)
                    console.log('Response sub data from the get session details',res.data.sub)
                               
                    console.log("Dispatched Details 123456789 ====>",(sessionDetails)) //print
                    navigate("/");
                    console.log('----SSSSSSSSSSSSS----'); //print
                    console.log('Response from set session',res); //print
            
              }
            })

            .catch((err)=>{
              if(err?.response?.status == 404){
                // toast.message("Session not Exist")
                navigate('/')
              }
              else{

             
             
              console.log('----NNNNNNNNNNNNN----');
              console.log("Get Session error",err);
              }
          })
            }
            }
          )
          .catch((err) => {
            setIsLoading(false);
            set_is_network_error(true);
            console.log("Authorize catch error", err);
          });

          } else {
           toast.dismiss();
           console.log('error it seeems to be -----')
           toast.error(res.message);
        
          }
         })
         .catch((err) => {

          if(err?.response?.status == 401 || err?.response?.status == 404){
            setIsLoading(false);
            // navigate('/login')
            toast.error(err.response.data.error);
            console.log("What is the error ====>", err.response.data.error);
          console.log("Password Email Incorrect Error");
        }

        else if(err?.response?.status == 409){
          setIsLoading(false)
          navigate('/dialog')
          console.log("Naviating to Dialog box for getting reply from the user whether they are going to proceed with current session or previous session") ////print
        }
          // else if(err?.response?.status == 404){
          //   setIsLoading(false);
          //   navigate('/login')
          //   toast.error("Wrong EmailId Try Again");
          //   console.log("Email Incorrect Error")
          // }
          else{
          setIsLoading(false);
          set_is_network_error(true);
          // setLoader(false)
          console.log(" authentication catch error", err);}
         });
       };

      //  if (isLoading) {
      //   console.log("isLoading isLoading", isLoading);
      //   return <Loader isLoading={isLoading} callBack={() => setIsLoading(false)} />;
      //  }
      //  if (is_network_error) {
      //   return <NetworkError callBack={() => set_is_network_error(false)} />;
      //  }
       

      
    
      // const confirmPasswordEyeIconChange = () => {
      //   setConfirmPasswordIconType((prevType) => (prevType === "password" ? "text" : "password"));
      //   // setConfirmPasswordIconType()
      // };


       const forgetPageNavigation=()=>{
        navigate(`/forgetPassword`)
      }
  
      const registrationPageNavigation=()=>{
        navigate(`/registration`)
      }

    return {
        is_small_screen,
        is_landscape,
        is_network_error,
        isLoading,
        height,
        width,
        passwordIconType,
        // passwordEyeIcon,
        setHeight,
        setWidth,
        setIsSmallScreen,
        setIsLandscape,
        setIsLoading,
        set_is_network_error,
        // setPasswordEyeIcon,
        setPasswordIconType,
        onSubmit,
        forgetPageNavigation,
        registrationPageNavigation,
        passwordEyeIconChange,
        // confirmPasswordEyeIconChange,
    
      };
}
export default useLoginController;