import { useDispatch } from "react-redux";
import { LogOut, tabId } from "../../redux/action";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import Styles from "../css/All_Courses.module.css";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../api/URL/Api_Route";
import { PostMethod } from "../../api/request/Api_Calls";
import toast from "react-hot-toast";
import Loader from "../../component/javascript/loader";
import NetworkError from "../../component/javascript/Network_Error";
import { CONFIG } from "../../config/Constant";
import RunTimeConstant from "../../config/RuntimeConfig";
import { RotatingLines } from "react-loader-spinner";
import queryString from "query-string";

const All_Courses = () => {
 const navigate = useNavigate();
 const dispatch = useDispatch();

 const [seleceted_Subject, set_Seleceted_Subject] = useState({
  label: "All Subjects",
  value: "all subject",
 });
 const [all_Subjects, set_All_Subjects] = useState([]);
 const [allCourse_Data, set_All_Course_Data] = useState([]);
 const [is_Small_Screen, set_Is_Small_Screen] = useState(false);
 const [is_Loading, set_Is_Loading] = useState(false);
 const [is_Network_Error, set_Is_Network_Error] = useState(false);
 const [search_Value, set_Search_Value] = useState("");
 const [load_Page, set_Load_Page] = useState(false);
 const sectionRef = useRef(null);

 //  useEffect(() => {
 //   window.scrollTo(0, 0);
 //  });

 const handleResize = () => {
  if (window.innerWidth <= 912) {
   set_Is_Small_Screen(true);
  } else {
   set_Is_Small_Screen(false);
  }
 };

 const getCourseBySubject = async (subject_data, page_number, from_see_more) => {
  from_see_more == "see_more" ? set_Load_Page(true) : set_Is_Loading(true);
  // set_Is_Loading(true)
  var final_data = [];
  for (let i = 0; i < subject_data.length; i++) {
   let payload = {
    url: API_URL.getCourseBySubject,
    data: {
     Subject: subject_data[i],
     page: page_number ? page_number : 1,
     limit: CONFIG.PAGINATION_COURSE_LIMIT,
    },
   };
   await PostMethod(payload)
    .then((response) => {
     console.log("getCourseBySubject response", response);
     if (response.success) {
      if (page_number) {
       final_data = allCourse_Data.length ? [...allCourse_Data] : [];
       final_data.map((result) => {
        if (result.sub_name == subject_data[i]) {
         const course_data = response?.data;
         course_data.map((ele) => {
          ele.thumbnail_Image = `${
           RunTimeConstant.BASEURL
          }assert/image?id=${encodeURIComponent(ele.AssetPath + ele.Image)}`;
          return (
           result.course.data.push(ele), (result.course.currentPage = response.currentPage)
          );
         });
        }
       });
      } else {
       const course_response = response;
       course_response.data.map((ele) => {
        ele.thumbnail_Image = `${RunTimeConstant.BASEURL}assert/image?id=${encodeURIComponent(
         ele.AssetPath + ele.Image
        )}`;
        return ele;
       });
       final_data.push({ sub_name: subject_data[i], course: course_response });
      }
     } else {
      set_Is_Loading(false);
      set_Load_Page(false);
      toast.dismiss();
      toast(response.message);
     }
    })
    .catch((error) => {
     console.log("getCourseBySubject error", error);
     set_Is_Loading(false);
     set_Load_Page(false);
     set_Is_Network_Error(true);
    });
  }
  set_All_Course_Data(final_data);
  set_Is_Loading(false);
  set_Load_Page(false);
 };

 const getSubjects = async () => {
  set_Is_Loading(true);
  let payload = {
   url: API_URL.getSubjects,
   data: {},
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("getSubjects response", response);
    if (response.success) {
     const subject_data = response?.subject;
     let subjectList = [{ label: "All Subjects", value: "all subject" }];
     subject_data.forEach((ele) => {
      subjectList.push({ label: ele, value: ele.toLowerCase() });
     });
     set_All_Subjects(subjectList);
     // set_Is_Loading(false)
     await getCourseBySubject(subject_data);
    } else {
     set_Is_Loading(false);
     toast.dismiss();
     toast(response.message);
    }
   })
   .catch((error) => {
    console.log("getSubjects error", error);
    set_Is_Loading(false);
    set_Is_Network_Error(true);
   });
 };

 useEffect(() => {
  if (seleceted_Subject.label == "All Subjects") {
   getSubjects();
  } else {
   getCourseBySubject([seleceted_Subject.label]);
  }
 }, [seleceted_Subject, is_Network_Error]);

 useEffect(() => {
  dispatch(tabId({ tab_Id: 3 }));
  // handleResize();
  window.addEventListener("resize", handleResize);

  return () => {
   window.removeEventListener("resize", handleResize);
  };
 }, []);

 const seeMore = (subName, currentPage) => {
  // console.log("subName", subName, currentPage)
  let increment_currentPage = currentPage + 1;
  getCourseBySubject([subName], increment_currentPage, "see_more");
 };

 const handleChange_search = (e) => {
  set_Search_Value(e.target.value);
 };

 const onSearchEvent = () => {
  if (search_Value.trim()) {
   // console.log("Search value", search_Value)
   navigate(`/search/${search_Value}`);
  } else {
   console.log("Empty search");
  }
 };

 const handleClick = (course_ID) => {
  const dataObject = {
   courseID: course_ID,
  };
  const dataString = JSON.stringify(dataObject);
  const queryStringified = queryString.stringify({ data: dataString });
  navigate(`/courseContent?${queryStringified}`);
 };

 if (is_Loading) {
  return <Loader isLoading={is_Loading} callBack={() => set_Is_Loading(false)} />;
 }

 if (is_Network_Error) {
  return <NetworkError callBack={() => set_Is_Network_Error(false)} />;
 }

 return (
  <div className={Styles.allCourse_Container}>
   <div className={Styles.grade_Select_Container}>
    <div className={Styles.sort_View}>
     <div className={Styles.select_Grade_Text}>Select Subject</div>

     <div className={Styles.sort_Dropdown_View}>
      <Select
       // closeMenuOnSelect={false}
       placeholder={"Sort"}
       value={seleceted_Subject}
       onChange={set_Seleceted_Subject}
       options={all_Subjects}
       isSearchable={false}
       theme={(theme) => ({
        ...theme,
        borderRadius: "5px",
        colors: {
         ...theme.colors,
         primary25: "#fff",
         primary: "var(--primary-color)",
         //   danger:'red',
         primary50: "var(--secondary-color)",
         dangerLight: "var(--secondary-color)",
         neutral80: "#000",
        },
       })}
       styles={{
        control: (baseStyles, state) => ({
         ...baseStyles,
         backgroundColor: "var(--secondary-color)",
         height: "25px",
         fontFamily: "WEB_FONT",
         fontSize: "14px",
        }),
       }}
       // components={{DropdownIndicator}}
      />
     </div>
    </div>

    {is_Small_Screen ? (
     <div
      onClick={() => navigate("/search/=")}
      onKeyDown={(event) => {
       if (event.key == "Enter") {
        navigate("/search/=");
       }
      }}
      className={Styles.searchView}
     >
      <AiOutlineSearch size={25} color="var(--primary-color)" />
     </div>
    ) : (
     <div className={Styles.searchView}>
      <input
       type="text"
       placeholder="Search"
       value={search_Value}
       onChange={handleChange_search}
       className={Styles.search_input}
       onKeyDown={(event) => {
        if (event.key == "Enter") {
         onSearchEvent();
        }
       }}
      />
      <AiOutlineSearch
       size={20}
       color="var(--primary-color)"
       style={{ marginRight: "3%" }}
       onClick={() => onSearchEvent()}
      />
     </div>
    )}
   </div>

   {allCourse_Data.length ? (
    <div className={Styles.course_details}>
     {allCourse_Data.map((sub, index) => {
      let courser_data = sub?.course?.data;
      return (
       <div key={index}>
        <div className={Styles.subject_Text_View}>
         <div className={Styles.subject_Text}>{sub?.sub_name}</div>
        </div>

        <div className={Styles.course_Direction}>
         <div className={Styles.course_Container}>
          {courser_data.map((ele, ind) => {
           return (
            // <div  className={Styles.course_Container}>
            <div key={ind} className={Styles.card_View} onClick={() => handleClick(ele?._id)}>
             <div className={Styles.course_Image_View}>
              <img
               src={ele.thumbnail_Image}
               crossOrigin="anonymous"
               className={Styles.course_Image_Size}
              />
             </div>

             <div style={{ padding: "10px 5px 5px 5px" }}>
              <div className={Styles.sub_Price_View}>
               <div className={Styles.course_Sub_Name_View}>
                <div className={Styles.course_Sub_Name_Text}>{sub?.sub_name}</div>
               </div>
               <div className={Styles.course_Price_Text}>{"AED " + ele.Price}</div>
              </div>

              <div className={Styles.course_Name}>{ele.CourseName}</div>

              <div className={Styles.course_Description}>{ele.Description}</div>

              <div className={Styles.exploreButton_View}>
               <div className={Styles.exploreButton_Text}>{"Explore"}</div>
               {/* </div> */}
              </div>
             </div>
            </div>
            // </div>
           );
          })}
         </div>

         {sub?.course?.currentPage < sub?.course?.totalPages && (
          <div className={Styles.see_All_Course_Text_View}>
           {!load_Page ? (
            <div
             onClick={() => seeMore(sub?.sub_name, sub?.course?.currentPage)}
             style={{ display: "flex", flexDirection: "row" }}
            >
             <div className={Styles.see_All_Course_Text}>{"See more"}</div>
             <div style={{ cursor: "pointer" }}>
              <BiChevronDown size={25} color={"var(--primary-color)"} />
             </div>
            </div>
           ) : (
            <div>
             <RotatingLines
              visible={load_Page}
              height="30"
              width="30"
              strokeColor="var(--primary-color)"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
             />
            </div>
           )}
          </div>
         )}
        </div>
       </div>
      );
     })}
    </div>
   ) : (
    <div style={{ height: "100vh" }}></div>
   )}
  </div>
 );
};

export default All_Courses;
