export const getFAQs = () => {
    let i = 1;
    return [
      { qn: `Q${i++}: What is Cognitived Education?`, ans: "Cognitived Education is an organization dedicated to transforming the educational landscape...", option: "option1" },
      { qn: `Q${i++}: How does Cognitived Education ensure equitable access to education?`, ans: "Cognitived Education is an organization dedicated to transforming the educational landscape...", option: "option2" },
      { qn: `Q${i++}: What is personalized learning, and how does Cognitived Education implement it?`, ans: "Cognitived Education is an organization dedicated to transforming the educational landscape...", option: "option3" },
      { qn: `Q${i++}: What is personalized learning, and how does Cognitived Education implement it?`, ans: "Cognitived Education is an organization dedicated to transforming the educational landscape...", option: "option4" },
      { qn: `Q${i++}: What is personalized learning, and how does Cognitived Education implement it?`, ans: "Cognitived Education is an organization dedicated to transforming the educational landscape...", option: "option5" },
     
    ];
  };