import React from "react";
import { Formik } from "formik";
import { AiOutlineExclamationCircle } from "react-icons/ai";
// import { CONFIG } from '../../config/Constant';
// import { FaEye, FaEyeSlash } from "react-icons/fa";
import Loader from "../../component/javascript/loader";
import NetworkError from "../../component/javascript/Network_Error";
// import Header_Component from "../../component/javascript/Header_Component";
import Styles from "../styles/Registration_Page.module.css";
import useRegistrationController from "../Controller/Registration_Page_Controller";
import { ValidationSchema, initialValues } from "../Model/Registration_Page_Model";
import {data} from '../Model/Registration_Page_Model';
import {passworddata} from '../Model/Registration_Page_Model';
import Textfield from '../Component/Textfield';
import Passwordfield from "../Component/Passwordfield";


const RegistrationView = () => {
  const {
    is_small_screen,
    // is_landscape,
    is_network_error,
    isLoading,
    passwordIconType,
    passwordEyeIcon,
    confirmPasswordIconType,
    confirmPasswordEyeIcon,
    set_is_network_error,
    setIsLoading,
    onSubmit,
    passwordEyeIconChange,
    confirmPasswordEyeIconChange,
    Navigation,
   
  } = useRegistrationController();

  if (isLoading) {
    return <Loader isLoading={isLoading} callBack={() => setIsLoading(false)} />;
  }

  if (is_network_error) {
    return <NetworkError callBack={() => set_is_network_error(false)} />;
  }

  return (
    <div className={Styles.body}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
      >
        {({
          // values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <div className={Styles.outLine}>
          <div className={Styles.svgPart}>
            {is_small_screen ? (
              // <App_Logo />
              ""
            ) : (
              <div className={Styles.resizable_container}>
                <div className={Styles.svg_content} />
              </div>
            )}
          </div>
          <div className={Styles.formPart} >
            <div className={Styles.form}>
              <div className={Styles.signInText}>Create Account</div>
              
              


              {data.map((values, index) => (
                <Textfield  
                  key={index}
                  error={errors[values.name]} 
                  touched={touched[values.name]} 
                  handleSubmit={handleSubmit} 
                  setFieldValue={setFieldValue} 
                  type={values.type} 
                  name={values.name} 
                  placeholder={values.placeholder} 
                  label={values.label}
                  config={values.MAX_LENGTH}
                  // passwordEyeIconChange={passwordEyeIconChange}
                  // confirmPasswordEyeIconChange={confirmPasswordEyeIconChange}
                />
                ))}

                {passworddata.map((values,index)=>(
                  <Passwordfield  
                  key={index}
                  error={errors[values.name]} 
                  touched={touched[values.name]} handleSubmit={handleSubmit} 
                  setFieldValue={setFieldValue} 
                  type={values.type} 
                  name={values.name} 
                  placeholder={values.placeholder} 
                  label={values.label}
                  config={values.MAX_LENGTH}
                  // passwordEyeIconChange={passwordEyeIconChange}
                  // confirmPasswordEyeIconChange={confirmPasswordEyeIconChange}
                  // passwordIconType={passwordIconType}
                  // passwordEyeIcon={passwordEyeIcon}
                  passwordIconType={values.name === "password" ? passwordIconType : confirmPasswordIconType}
                  passwordEyeIconChange={values.name === "password" ? passwordEyeIconChange : confirmPasswordEyeIconChange}
                />

                ))}
            <div className={Styles.checkboxfieldOutline}>
                <input
                  type="checkbox"
                  name="term_and_condition"
                  className={Styles.checkbox}
                  onChange={(val) => {
                    // console.log("term_and_condition", val.target.checked)
                    setFieldValue("term_and_condition", val.target.checked);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSubmit();
                    }
                  }}
                />
               
            <span className={Styles.checkboxText}>
                  I have read and agree to the{" "}
                  <span tabIndex={0} className={Styles.termOfService}>
                    Terms and Conditions
                  </span>
                </span>
                <br />
              </div>
              <div className={Styles.formikErrorText}>
                <div>
                  {errors.term_and_condition &&
                    touched.term_and_condition &&
                    errors.term_and_condition && <AiOutlineExclamationCircle size={14} />}
                </div>
                <div>
                  {errors.term_and_condition &&
                    touched.term_and_condition &&
                    errors.term_and_condition}
                </div>
            </div>
            <div className={Styles.signInButton} tabIndex={0} onClick={handleSubmit}>
                  Submit
                </div>
                <div
                  tabIndex={0}
                  className={Styles.signRegNav}
                  onClick={Navigation}
                >
                  Sign in
                </div>
              
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default RegistrationView;
