import * as Yup from 'yup';
import {  TOP_EMAIL_DOMAINS } from "../../config/Constant";


export const initialValues = {
    user_email: "",
    password: "",
};

export const data=[
    {type:"text",placeholder:"Enter your Email",name:"user_email",label:"Email",errors:"user_email",MAX_LENGTH:50},
]

export const passworddata=[
    {type:"text",placeholder:"Enter your Password",name:"password",label:"Password",errors:"password",MAX_LENGTH:20,passwordIconType:"password"},
]


export const ValidationSchema = Yup.object().shape({
    user_email: Yup.string()
     .required("Email is a required field")
     .test("notEmpty", "Email cannot be empty", (value) => value.trim() !== "")
     .email("Invalid email")
     .test("isValidDomain", "Invalid email", (value) => {
      if (value) {
       const domain = value.split("@")[1];
       return !!TOP_EMAIL_DOMAINS[domain];
      }
      return true;
     }),
    password: Yup.string()
     .required("Password is a required field")
     .test("notEmpty", "Password cannot be empty", (value) => value.trim() !== ""),
   });