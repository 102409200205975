import React from "react";

function App_Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 73 73"
    >
      <path
        fill="#0F52FF"
        d="M36.5 0A36.503 36.503 0 000 36.5 36.503 36.503 0 0036.5 73V54.75a18.247 18.247 0 01-16.86-11.266 18.247 18.247 0 019.876-23.845A18.247 18.247 0 0136.5 18.25V0z"
      ></path>
      <path
        fill="#0F52FF"
        d="M36.5 48.667c6.72 0 12.167-5.447 12.167-12.167S43.22 24.334 36.5 24.334 24.334 29.78 24.334 36.5c0 6.72 5.447 12.167 12.166 12.167z"
      ></path>
      <path
        fill="#FDDD35"
        d="M0 36.5A36.503 36.503 0 0036.5 73 36.502 36.502 0 0073 36.5H54.75a18.247 18.247 0 01-11.266 16.86 18.247 18.247 0 01-23.845-9.876A18.247 18.247 0 0118.25 36.5H0z"
      ></path>
    </svg>
  );
}

export default App_Logo;