import queryString from "query-string";
import Styles from "../css/Assessment.module.css";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { API_URL } from "../../api/URL/Api_Route";
import { PostMethod } from "../../api/request/Api_Calls";
import toast from "react-hot-toast";
import Loader from "../../component/javascript/loader";
import NetworkError from "../../component/javascript/Network_Error";
import { useLocation, useNavigate } from "react-router-dom";
import MathFieldComponent from "../../component/javascript/Mathfield_Component";
import RunTimeConstant from "../../config/RuntimeConfig";
import { useSelector } from "react-redux";
import Modal_Component from "../../component/javascript/ModalComponent";
import ScoreCardModal from "../../component/javascript/ScoreCardModal";
let answerCount;
const Assessments = () => {
 const location = useLocation();
 const navigate = useNavigate();
 const queryParams = queryString.parse(location.search);
 const { token } = useSelector((state) => state.applicationState);
 const [is_Loading, set_Is_Loading] = useState(false);
 const [is_Network_Error, set_Is_Network_Error] = useState(false);
 const [question_Data, setQuestion_Data] = useState([]);
 const [questionIDs, setQuestionIDs] = useState([]);
 const [selectd_Question, setSelected_Question] = useState("");
 const [selectedOptions, setSelected_Option] = useState("");
 const [fillAnswer, setFillAnswer] = useState([]);
 const [questionLoader, setquestionLoader] = useState(false);
 const [showModal, setShowModal] = useState(false);
 const [showScore, setShowScore] = useState(false);
 const [score, setScore] = useState(0);

 const names = JSON.parse(queryParams.name);
 const indication = [
  {
   id: 0,
   text: "Answered",
   color: "#217C58",
  },
  {
   id: 1,
   text: "Not Answered",
   color: "#FBCA7D",
  },
  {
   id: 2,
   text: "Current Page",
   color: "var(--primary-color)",
  },
  {
   id: 3,
   text: "Not Visited",
   color: "#F5F2FF",
  },
 ];

 const addUrlKey = async (params) => {
  const data = params;
  data.forEach((item) => {
   if (item.Question) {
    item.Question.forEach((question) => {
     if (question.Image) {
      question.Url = `${RunTimeConstant.BASEURL}assert/image?id=${encodeURIComponent(
       item.AssertPath + question.Image
      )}`;
     }
    });
   }
   if (item.Options) {
    Object.keys(item.Options).forEach((option) => {
     item.Options[option].forEach((opt) => {
      if (opt.Image) {
       opt.Url = `${RunTimeConstant.BASEURL}assert/image?id=${encodeURIComponent(
        item.AssertPath + opt.Image
       )}`;
      }
     });
    });
   }
   // if (item.Reasons) {
   //     item.Reasons.forEach(reason => {
   //         if (reason.Reason) {
   //             reason.Reason.forEach(subReason => {
   //                 if (subReason.Image) {
   //                     subReason.Url = `${RunTimeConstant.BASEURL}assert/image?id=${encodeURIComponent(item.AssertPath+subReason.Image)}`;
   //                 }
   //             });
   //         }
   //     });
   // }
  });
  return data;
 };

 const getAssessment = (selec_ques) => {
  // console.error("getAssessment", selec_ques);
  setquestionLoader(true);
  let payload = {
   url: API_URL.read_individual_question,
   data: {
    _id: selec_ques?.QuestionID,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("getAssessment response", response);
    if (response.success) {
     const final_data = await addUrlKey(response.data);
     console.log("question data", final_data);
     setQuestion_Data(final_data);
     readQuestion(selec_ques?.QuestionID);
     setquestionLoader(false);
     if (final_data[0]?.Type == 0) {
      setSelected_Option(
       selec_ques?.AnswerGiven ? selec_ques?.AnswerGiven[final_data[0]?._id] : ""
      );
     } else {
      setFillAnswer(
       selec_ques?.AnswerGiven ? selec_ques?.AnswerGiven[final_data[0]?._id] : []
      );
     }
     set_Is_Loading(false);
    } else {
     setquestionLoader(false);
     toast.dismiss();
     toast(response.message);
    }
   })
   .catch((error) => {
    console.log("getAssessment error", error);
    setquestionLoader(false);
    set_Is_Network_Error(true);
   });
 };

 const handleChange = (e) => {
  console.log("Input value:", e.target.value);
  if (e.target.value.trim()) {
   setFillAnswer([e.target.value]);
  } else {
   setFillAnswer([]);
  }
 };

 const getQuestions = (question_data) => {
  let payload = {
   url: API_URL.take_assessment,
   data: {
    UserID: token,
    AssessmentID: queryParams?.Assessment_ID,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("getQuestions response", response);
    if (response.success) {
     if (response.message == "Assessment already completed") {
      toast.dismiss();
      toast(response.message);
      navigate(-1);
     } else {
      setQuestionIDs(response?.data?.Questions);
      if (question_data) {
       response?.data?.Questions.map((ele, ind) => {
        if (ele?.QuestionID == question_data?.QuestionID) {
         setSelected_Question(ele);
         getAssessment(ele);
        }
       });
      } else {
       setSelected_Question(response?.data?.Questions[0]);
       getAssessment(response?.data?.Questions[0]);
      }
      //  set_Is_Loading(false);
     }
    } else {
     set_Is_Loading(false);
     toast.dismiss();
     toast(response.message);
    }
   })
   .catch((error) => {
    console.log("getQuestions error", error);
    set_Is_Loading(false);
    set_Is_Network_Error(true);
   });
 };
 useLayoutEffect(() => {
  // getAssessment();
  set_Is_Loading(true);
  getQuestions();
 }, [is_Network_Error]);

 const submit_Assessment = () => {
  set_Is_Loading(true);
  let payload = {
   url: API_URL.submit_assessment,
   data: {
    UserID: token,
    AssessmentID: queryParams?.Assessment_ID,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("submit_Assessment response", response);
    if (response.success) {
     set_Is_Loading(false);
     setShowModal(false);
     setShowScore(true);
     setScore(response?.result);
    } else {
     set_Is_Loading(false);
     toast.dismiss();
     toast(response.message);
    }
   })
   .catch((error) => {
    console.log("submit_Assessment error", error);
    set_Is_Loading(false);
    set_Is_Network_Error(true);
   });
 };

 const assessmentCompleted = () => {
  const dataObject = {
   courseID: queryParams?.course_ID,
  };
  const dataString = JSON.stringify(dataObject);
  const queryStringified = queryString.stringify({ data: dataString });
  navigate(`/courseContent?${queryStringified}`);
 };

 const nextQuestion = () => {
  let next_ques_ind = -1;
  for (let i = 0; i < questionIDs.length; i++) {
   if (questionIDs[i].QuestionID === selectd_Question.QuestionID) {
    next_ques_ind = i + 1;
    break;
   }
  }
  // console.log("nextQuestion", questionIDs[next_ques_ind]);
  // if (questionIDs[next_ques_ind]?.QuestionID) {
  getQuestions(questionIDs[next_ques_ind]);
  //  setSelected_Question(questionIDs[next_qu es_ind]);
  //  getAssessment(questionIDs[next_ques_ind]);
  // }
 };
 const submitQuestion = () => {
  let answer = question_Data[0].Type == 0 ? selectedOptions : fillAnswer;
  // set_Is_Loading(true);
  setquestionLoader(true);
  let payload = {
   url: API_URL.submit_question,
   data: {
    UserID: token,
    AssessmentID: queryParams?.Assessment_ID,
    QuestionID: selectd_Question?.QuestionID,
    Answers: answer,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("submitQuestion response", response);
    if (response.success) {
     //  if (isLast == 100) {
     //   setShowModal(true);
     //  } else {
     nextQuestion();
     //  }
     //  getQuestions(selectd_Question);
     //  set_Is_Loading(false);
    } else {
     setquestionLoader(false);
     //  set_Is_Loading(false);
     toast.dismiss();
     toast(response.message);
    }
   })
   .catch((error) => {
    console.log("submitQuestion error", error);
    // set_Is_Loading(false);
    setquestionLoader(false);
    set_Is_Network_Error(true);
   });
 };

 const readQuestion = (ques_id) => {
  // set_Is_Loading(true);

  let payload = {
   url: API_URL.attend_assessment,
   data: {
    UserID: token,
    AssessmentID: queryParams?.Assessment_ID,
    QuestionID: ques_id,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("readQuestion response", response);
    if (response.success) {
     //  set_Is_Loading(false);
    } else {
     //  set_Is_Loading(false);
     toast.dismiss();
     toast(response.message);
    }
   })
   .catch((error) => {
    console.log("readQuestion error", error);
    // set_Is_Loading(false);
    set_Is_Network_Error(true);
   });
 };

 if (is_Loading) {
  return <Loader isLoading={is_Loading} callBack={() => set_Is_Loading(false)} />;
 }

 if (is_Network_Error) {
  return <NetworkError callBack={() => set_Is_Network_Error(false)} />;
 }

 //  console.log("question_Data[0]?.Question?.Text", question_Data[0]?.Question[0]?.Text);

 const Question_Section = () => {
  return (
   <div>
    {question_Data[0]?.Question.map((ele, ind) => {
     return (
      <div key={ind}>
       {ele?.Type === 0 ? (
        <MathFieldComponent value={ele?.Text} readOnly={true} width={"780px"} />
       ) : (
        <div className={Styles.option_Image}>
         <img src={ele?.Url} className={Styles.option_Image_Fit} crossOrigin="anonymous" />
        </div>
       )}
      </div>
     );
    })}
   </div>
  );
 };

 const Option_Section = () => {
  // console.log("question_Data[0]?.Options", question_Data);
  return (
   <div>
    {Object.keys(question_Data[0]?.Options).map((prefix, index) => (
     <div key={index}>
      {question_Data[0]?.Options[prefix]?.map((option, optionIndex) => (
       <div key={optionIndex} className={Styles.optionView}>
        <div
         className={Styles.optionKey}
         style={{
          backgroundColor:
           selectedOptions == prefix ? "var(--primary-color)" : "var(--bg-color)",
          color:
           selectedOptions == prefix ? "var(--inside-primary-color)" : "var(--text-color)",
         }}
         onClick={() => setSelected_Option(prefix)}
        >
         {prefix.toUpperCase() + "."}
        </div>
        {option?.Type === 0 ? (
         <MathFieldComponent value={option?.Text} readOnly={true} />
        ) : (
         <div className={Styles.option_Image}>
          <img src={option?.Url} className={Styles.option_Image_Fit} crossOrigin="anonymous" />
         </div>
        )}
       </div>
      ))}
     </div>
    ))}
   </div>
  );
 };

 //  const AnswerInput = () => {
 //   return (
 //    <div>
 //     <input
 //      type="text"
 //      value={fillAnswer}
 //      className={Styles.answerInput}
 //       onChange={handleChange}
 //     />
 //    </div>
 //   );
 //  };

 return (
  <>
   {" "}
   {showModal && (
    <Modal_Component
     closeModel={() => setShowModal(false)}
     onSubmit={() => submit_Assessment()}
    />
   )}
   {showScore && (
    <ScoreCardModal
     score={score}
     closeModel={() => setShowScore(false)}
     onSubmit={() => assessmentCompleted()}
    />
   )}
   <div className={Styles.assessment_Container}>
    <div className={Styles.question_Section}>
     {questionLoader ? (
      // <div style={{ width: "70%" }}>
      <Loader isLoading={questionLoader} callBack={() => setquestionLoader(false)} />
     ) : (
      // </div>
      <>
       <div className={Styles.course_Title_View}>
        <div className={Styles.course_Title}>{names?.courseName}</div>

        {names?.chapterName && (
         <div className={Styles.breadCrumb_Text}>
          <span className={Styles.breadCrumb_Divider}>{""}</span> {names?.chapterName}
         </div>
        )}

        {names?.topicName && (
         <div className={Styles.breadCrumb_Text}>
          <span className={Styles.breadCrumb_Divider}>{""}</span>
          {names?.topicName}
         </div>
        )}
       </div>

       <div className={Styles.page_Title_View}>Answer the following</div>

       {question_Data.length ? (
        <div className={Styles.questionAlignment}>
         {(() => {
          let next_ques_ind = -1;
          for (let i = 0; i < questionIDs.length; i++) {
           if (questionIDs[i].QuestionID === selectd_Question.QuestionID) {
            next_ques_ind = i + 1;
            break;
           }
          }
          return <div className={Styles.questionNumber}>{next_ques_ind}.</div>;
         })()}
         {/* <div className={Styles.questionNumber}>01.</div> */}

         <div>
          <Question_Section />

          {question_Data[0]?.Type == "0" && question_Data[0]?.Options ? (
           <Option_Section />
          ) : question_Data[0]?.Type == "1" ? (
           <div style={{ paddingTop: "30px" }}>
            <input
             type="text"
             value={fillAnswer}
             className={Styles.answerInput}
             onChange={handleChange}
            />
           </div>
          ) : (
           <></>
          )}
         </div>
        </div>
       ) : (
        <></>
       )}

       {(() => {
        let last_ques = questionIDs[questionIDs?.length - 1]?.QuestionID;
        // let notAnswered = [];
        // for (let i = 0; i < questionIDs.length; i++) {
        //  if (questionIDs[i].Answerstatus === false) {
        //   notAnswered.push(i);
        //  }
        // }
        // console.log("notAnswered", notAnswered);
        return (
         <div className={Styles.buttonView}>
          <div
           className={Styles.submitButton}
           onClick={() =>
            selectedOptions != "" || fillAnswer.length ? submitQuestion() : null
           }
           style={{
            backgroundColor:
             question_Data[0]?.Type == 0
              ? selectedOptions != ""
                ? "var(--primary-color)"
                : "var(--secondary-color)"
              : fillAnswer.length
              ? "var(--primary-color)"
              : "var(--secondary-color)",
            color: "var(--inside-primary-color)",
           }}
          >
           Submit
          </div>

          {last_ques == selectd_Question?.QuestionID ? (
           <></>
          ) : (
           <div className={Styles.nextButton} onClick={() => nextQuestion()}>
            Next
           </div>
          )}
         </div>
        );
       })()}
      </>
     )}
    </div>

    <div className={Styles.numberPad_Section}>
     <div className={Styles.numberPad_Title}>Question Status</div>
     <div
      className={Styles.numberPad_Body}
      style={{ height: questionIDs.length > 30 ? "231px" : "" }}
     >
      {questionIDs.map((ele, ind) => {
       // console.log("element", ele);
       let question_no = ind + 1;
       answerCount = questionIDs.filter((obj) => obj.QuestionStatus == 2).length;
       return (
        <div key={ind} className={Styles.questionNumber_Text_View}>
         <div
          className={Styles.questionNumber_Text}
          style={{
           backgroundColor:
            selectd_Question?.QuestionID == ele?.QuestionID
             ? "var(--primary-color)"
             : ele?.QuestionStatus == 2
             ? "#217C58" //Answered
             : ele?.QuestionStatus == 1
             ? "#FBCA7D" //Not Answered
             : ele?.QuestionStatus == 0
             ? "var(--secondary-color)"
             : "var(--secondary-color)", //  Not Visited

           color:
            selectd_Question?.QuestionID == ele?.QuestionID
             ? "var(--inside-primary-color)"
             : ele?.QuestionStatus == 0
             ? "var(--text-color)"
             : "var(--inside-primary-color)",
          }}
          onClick={() => {
           getQuestions(ele);
          }}
         >
          {question_no}
         </div>
        </div>
       );
      })}
     </div>

     <div className={Styles.answerStatus_View}>
      <div className={Styles.answerStatus_Text}>
       <div className={Styles.numberPad_Title} style={{ fontWeight: "500" }}>
        Answer Status{" "}
       </div>
       <div>
        <span className={Styles.total_Mark_View}>
         {" "}
         <span className={Styles.acutal_Mark_Text}>
          {answerCount?.toString().padStart(2, "0")}
         </span>
         /{questionIDs.length?.toString().padStart(2, "0")}
        </span>
       </div>
      </div>

      <div className={Styles.indication_View}>
       {indication.map((ele, ind) => {
        return (
         <div key={ind} className={Styles.indication_Body}>
          <div
           className={Styles.color_Indication}
           style={{ backgroundColor: ele.color }}
          ></div>
          <div className={Styles.question_Indication}>{ele.text}</div>
         </div>
        );
       })}
      </div>

      <div className={Styles.over_All_Submit_Button}>
       <div onClick={() => setShowModal(true)} className={Styles.over_All_Submit_Text}>
        Over All Submit
       </div>
      </div>
     </div>
    </div>
   </div>
  </>
 );
};

export default Assessments;
