import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
 <>
  <Provider store={store}>
   <App />
  </Provider>
 </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// PostMethod({data:{
//   UserName: "dharani",
//   EmailId: "dharanidha@gmail.com",
//   MobileNumber: "9789544514",
//   Password: "Welcome@123"
// },url:"users/user_registeration"})
