import Styles from '../styles/Registration_Page.module.css';
import { AiOutlineExclamationCircle } from "react-icons/ai";


const Textfield = ({
    error,
    touched,
    handleSubmit,
    setFieldValue,
    type,
    placeholder,
    name,
    label,
    config,
    // passwordEyeIconChange,
    // passwordEyeIcon
  }) => {
    return (
      <div>
        <label className={Styles.field}>{label}</label>
        <span className={Styles.requiredFieldSymbol}>*</span>
        <br />
        <input style={{}}
          type={type}
          placeholder={placeholder}
          name={name}
          className={`${Styles.fieldInput} ${
            error && touched ? Styles.fieldInputError : ""
          }`}
          onChange={(val) => {
            setFieldValue(name, val.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSubmit();
            }
          }}
          maxLength={config}
        />
         
  
                  <div className={Styles.formikErrorText}>
                    <div>
                      {error && touched && error && (
                        <AiOutlineExclamationCircle size={14} />
                      )}
                    </div>
                    <div>{error && touched && error}</div>
                  
  
                  </div>
      </div>
    )}
    export default Textfield;
  