import axios from "axios";
import { getLocalStorage } from "../storage/localStorage";

// code here for request interceptor
const axiosRequestInterceptor = async (config) => {
 // console.log(".....", config.headers);
 const token = await getLocalStorage("token");
 // if (API) {
 // console.log("token", token)
 if (token != null && token != undefined) {
  var interupt = { authorization: +token };
  config.headers = Object.assign(config.headers, interupt);
 }
 // } else {
 //   axios.CancelToken();
 // }
 return config;
};
axios.interceptors.request.use(axiosRequestInterceptor);

// code here for respose  interceptor

const axiosResponseInterceptor = (res) => {
 return res;
};
axios.interceptors.response.use(axiosResponseInterceptor);

export const apiRequest = axios;
