import React from "react";

function Facebook_Svg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      fill="none"
      viewBox="0 0 22 23"
    >
      <path
        fill="#fff"
        d="M22 11.677A11 11 0 109.281 22.544v-7.687H6.488v-3.18h2.793V9.254c0-2.757 1.643-4.28 4.155-4.28a17.06 17.06 0 012.463.214v2.708H14.51a1.59 1.59 0 00-1.792 1.719v2.062h3.048l-.488 3.18h-2.567v7.687A11 11 0 0022 11.677z"
      ></path>
    </svg>
  );
}

export default Facebook_Svg;
