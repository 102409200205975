const RunTimeConstant = {
  BASEURL: "",  SERVICEURL:{
    AMS:"", //Account Management Service
    IMS:"", //Identity Management Service
    UMS:"",
    CMS:"" //Cache Management Service 
  }
};

if (process.env.NODE_ENV == "development") {
  //URL
  RunTimeConstant.BASEURL = "http://127.0.0.1:55548/";
  RunTimeConstant.SERVICEURL.AMS = "http://127.0.0.1:40002/";
  RunTimeConstant.SERVICEURL.IMS = "http://127.0.0.1:40000/"
  RunTimeConstant.SERVICEURL.UMS = "http://127.0.0.1:40005/"
  RunTimeConstant.SERVICEURL.CMS = "http://127.0.0.1:40001/"
  
  //change


  // RunTimeConstant.BASEURL = "https://ftp.iinvsys.com:55576/slu/";
} else if (process.env.NODE_ENV == "production") {
  //URL
  RunTimeConstant.BASEURL = "https://sslluu.onrender.com/";
} else {
  //URL 
  RunTimeConstant.BASEURL = "http://192.168.10.34:55548/";
  RunTimeConstant.SERVICEURL.AMS = "http://127.0.0.1:40002/";
  RunTimeConstant.SERVICEURL.IMS = "http://127.0.0.1:40000/";
  RunTimeConstant.SERVICEURL.CMS = "http://127.0.0.1:40001/"
 }
export default RunTimeConstant;
