// CropImage.js
import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import { getCroppedImg } from "../../config/cropImage";

const CropImage = ({ imageSrc, onCropComplete }) => {
 const [crop, setCrop] = useState({ x: 0, y: 0 });
 const [zoom, setZoom] = useState(1);
 const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

 const onCropCompleteCallback = useCallback((croppedArea, croppedAreaPixels) => {
  setCroppedAreaPixels(croppedAreaPixels);
 }, []);

 const handleCrop = async () => {
  try {
   const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
   onCropComplete(croppedImage);
  } catch (e) {
   console.error(e);
  }
 };

 return (
  <div style={{ width: "300px" }}>
   <div style={{ position: "relative", width: "100%", height: 200 }}>
    <Cropper
     image={imageSrc}
     crop={crop}
     zoom={zoom}
     aspect={4 / 3}
     onCropChange={setCrop}
     onZoomChange={setZoom}
     onCropComplete={onCropCompleteCallback}
    />
   </div>
   <div>
    <Slider
     value={zoom}
     min={1}
     max={3}
     step={0.1}
     aria-labelledby="Zoom"
     onChange={(e, zoom) => setZoom(zoom)}
    />
   </div>
   <Button variant="contained" color="primary" onClick={handleCrop}>
    Crop Image
   </Button>
  </div>
 );
};

export default CropImage;
