import React from "react";

function Video_Svg(props) {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.height}
          fill="none"
          viewBox="0 0 16 14"
        >
          <path
            fill={props.color}
            d="M10.803 5.326L7.111 2.963a.633.633 0 00-.87.188.573.573 0 00-.087.304v4.727c0 .107.03.212.087.304.057.091.14.167.238.217a.636.636 0 00.632-.03l3.692-2.363a.6.6 0 00.202-.213.572.572 0 000-.558.6.6 0 00-.202-.213zM7.385 7.078V4.562l1.967 1.256-1.967 1.26zM14.769.5H1.231C.904.5.59.625.36.846.13 1.068 0 1.368 0 1.682v8.273c0 .313.13.614.36.835.231.222.544.346.87.346h13.54c.326 0 .639-.124.87-.346.23-.221.36-.522.36-.835V1.682c0-.314-.13-.614-.36-.836A1.257 1.257 0 0014.77.5zm0 9.455H1.231V1.682h13.538v8.273zM16 12.909a.58.58 0 01-.18.418.629.629 0 01-.435.173H.615a.629.629 0 01-.435-.173.58.58 0 01-.18-.418.58.58 0 01.18-.418.628.628 0 01.435-.173h14.77c.163 0 .32.062.435.173a.58.58 0 01.18.418z"
          ></path>
        </svg>
      );
}

export default Video_Svg;
